import axios from "axios";
import clsx from "clsx";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetSinglePatientRecord } from '../../../api/hooks/provider/useGetSinglePatientRecord';
import { FormResponseItem } from "../../../api/types";
import CustomDrawer from "../../../components/drawer";
import Button from "../../../components/shared/Button";
import { Loading } from "../../../components/shared/Loading";
import { ArrowBackIcon } from "../../../svgs/ArrowBackIcon";
import { ReactComponent as DurationIcon } from "../../../svgs/duration-icon.svg";
import { ReactComponent as CloseIcon } from "../../../svgs/small-close.svg";
import { Appointment } from "../../../types/appointment";
import { calculateAgeFromDOB } from "../../../utils/helpers";

interface PatientAppointmentDrawerProps {
  appointmentDetails: Appointment
  drawerTitle: string;
  onClose: () => void;
  open: boolean;
  clickJoinCall: () => void;
  callNumber?: number;
  patientIntakeFormResponse: FormResponseItem[];
  handleCancelAppointment: (id: string) => void;
  setIsRescheduleViewVisible: (val: boolean) => void;
  setOldAppointmentForReschedule: (val: string) => void;
}

const ProviderAppointmentsDrawer: React.FC<PatientAppointmentDrawerProps> = ({
  appointmentDetails,
  drawerTitle,
  onClose,
  open,
  clickJoinCall,
  patientIntakeFormResponse,
  handleCancelAppointment,
  setIsRescheduleViewVisible,
  setOldAppointmentForReschedule
}) => {
  const [isIntakeFormAnswersView, setIsIntakeFormAnswersView] = useState(false);
  const router = useNavigate();


  const { data, isLoading, isError, error } = useGetSinglePatientRecord(appointmentDetails?.patientId)

  if (isError && axios.isAxiosError(error)) {
    toast.error(error?.response?.data?.error, { toastId: "customId" });
  }

  if (isLoading) {
    <div className="w-full h-full flex items-center justify-center">
      <Loading />
    </div>
  }

  return (
    <div data-testid="drawer-root">
      <CustomDrawer
        open={open}
        onClose={onClose}
        drawerTitle={drawerTitle}
        noWidth
        data-testid="drawer-root"
      >
        {isIntakeFormAnswersView ? (
          <div className="min-w-full max-w-full lg:w-[600px] overflow-x-hidden mx-0 px-4">
            <div className="w-full flex items-center my-4" data-testid='back-button-container'>
              <button
                data-testid='back-button'
                className="mr-3"
                type="button"
                onClick={() => setIsIntakeFormAnswersView(false)}
              >
                <ArrowBackIcon />
              </button>
              <p className="text-[24px] font-[500]">
                {appointmentDetails?.patientName}:{" "}
                <span className="text-[24px] font-[600]">Intake Form</span>
              </p>
            </div>

            {patientIntakeFormResponse?.length ? (
              <section className="w-full h-full">
                {patientIntakeFormResponse?.map((item: any, idx: number) => (
                  <section key={idx} className="text-[18px]">
                    {Object.entries(item).map(([key, value]: any) => (
                      <div className="w-full pb-5" key={key}>
                        <p className="font-semibold">{key}</p>
                        {Array.isArray(value) ? (
                          Object.keys(value[0])?.map((item: any, index) => (
                            <div key={index}>
                              <input
                                className="accent-[#3D874E]"
                                type="checkbox"
                                checked={value[0][item]}
                                readOnly={true}
                              />{" "}
                              <span>{item}</span>
                            </div>
                          ))
                        ) : (
                          <p>{value}</p>
                        )}
                      </div>
                    ))}
                  </section>
                ))}
              </section>
            ) : (
              <div className="w-full h-full">No Response</div>
            )}
          </div>
        ) : (
            <div className="min-w-full max-w-full lg:w-[600px] overflow-x-hidden mx-0 px-4" data-testid="appointment-drawer-section">
              <p className="text-[32px] font-[600] pb-3" data-testid='appointment-with'>
              Appointment with {appointmentDetails?.patientName}
            </p>
              <div className="flex items-center justify-start" data-testid="provider-appointment-info-container">
                <p className="mr-2" data-test="appointment-date">
                {appointmentDetails?.appointmentDate &&
                  format(
                    new Date(appointmentDetails?.appointmentDate),

                    // new Date(appointmentDetails?.appointmentNewDate.substring(0, 10)),
                    "EEEE, dd LLL"
                  )}
              </p>
                <div data-testid="appointment-time">
                <li className="text-[16px] font-[400] text-[#2E3011]">
                    {`${appointmentDetails?.appointmentStartTime} - ${appointmentDetails?.appointmentEndTime}` || 'N/A'}
                </li>
              </div>
            </div>
            <div className="flex items-center w-full mb-4">
              <div className="mr-4 lg:mr-12">
                <span>
                  <button
                    className={clsx({
                      "flex items-center justify-start text-[#2F9F4A] text-[.77rem] font-[400] cursor-pointer":
                        true
                    })}
                    onClick={() => {
                      onClose();
                      setIsRescheduleViewVisible(true);
                      setOldAppointmentForReschedule(
                        appointmentDetails?.appointmentId
                      );
                    }}
                    disabled={appointmentDetails?.didAppointmentHold}
                      data-testid="reschedule-appointment"
                  >
                      <DurationIcon className="mr-2 w-[16px] h-[16px]" data-testid='rechedule-icon' />
                    Reschedule Appointment
                  </button>
                </span>
              </div>
              <span className="mr-auto">
                <button
                  className={clsx({
                    "flex items-center justify-start text-[#E45B3D] text-[.77rem] font-[400] cursor-pointer":
                      true,
                    "text-gray-400 cursor-not-allowed":
                      appointmentDetails?.didAppointmentHold
                  })}
                  onClick={async () =>
                    await handleCancelAppointment(
                      appointmentDetails?.appointmentId
                    )
                  }
                  disabled={appointmentDetails?.didAppointmentHold}
                    data-testid="cancel-appointment"
                >
                    <CloseIcon className="mr-2 w-[16px] h-[16px] " data-testid='close-icon' />
                  Cancel Appointment
                </button>
              </span>
                <div className="hidden lg:flex justify-end mr-0 w-[117px]" data-testid="join-call-button-container">
                <Button
                  height={60}
                  type="submit"
                  variant={
                    appointmentDetails?.didAppointmentHold
                      ? "secondary"
                      : "primary"
                  }
                  label="Join Call"
                  size="medium"
                  onClick={clickJoinCall}
                  disabled={appointmentDetails?.didAppointmentHold}
                />
              </div>
              <div className="mt-4 flex lg:hidden">
                <Button
                  width={108}
                  height={60}
                  type="submit"
                  variant="secondary"
                  label="Join Call"
                  size="medium"
                  disabled
                    onClick={() => { }}
                />
              </div>
            </div>
              <div data-testid='patient-root'>
                <p className="text-[20px] font-[600] pb-3" data-testid='patient-root-title'>About the patient</p>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="h-[50px] w-[50px] mr-4 rounded-full flex relative flex-col items-center justify-center">
                    <img
                      src={
                        appointmentDetails?.avatarUrl ??
                        "/assets/images/avatar.png"
                      }
                      className="rounded-full"
                      alt=""
                        data-testid='patient-avatar'
                    />
                  </div>
                  <div className="flex flex-col items-start gap-1">
                      <p className="text-[20px] font-[600] text-[#2E3011]" data-testid='patient-name'>
                      {appointmentDetails?.patientName}
                    </p>
                      <p className="text-[15px] font-[600] text-[#2E3011] pb-2" data-testid='patient-dob'>
                      {calculateAgeFromDOB(appointmentDetails?.dateOfBirth)}
                    </p>
                      <div />
                  </div>
                </div>
                  <div className="flex justify-end mr-0 w-[117px]" data-testid='view-profile-button-container'>
                  <Button
                    width={108}
                    height={60}
                    type="submit"
                    variant="secondary"
                    label="View full profile"
                    size="medium"
                    onClick={() => {
                      router(
                        `/provider/patients/${data?.patientDetails.patient.userId}/details`
                      );
                    }}
                  />
                </div>
              </div>

                <div className="w-full my-4 flex items-center justify-start" data-testid='patient-address-container'>
                <div className="w-1/2">
                    <p className="text-[14px] font-[500] text-[#2E3011] pb-2" data-testid='patient-pronound-title'>
                    Preferred Pronouns
                  </p>
                    <p className="text-[16px] font-[400] text-[#2E3011] pb-2" data-testid='patient-pronound'>
                      {data?.patientDetails.patient?.pronoun || "N/A"}
                  </p>
                </div>
                  <div className="w-1/2" data-testid='patient-email-address-title'>
                  <p className="text-[14px] font-[500] text-[#2E3011] pb-2">
                    Email Address
                  </p>
                    <p className="text-[16px] font-[400] text-[#2E3011] pb-2" data-testid='patient-email-address'>
                      {data?.patientDetails.patient?.email || "N/A"}
                  </p>
                </div>
              </div>
            </div>

              <div className="lg:mt-8" data-testid='patient-info-container'>
                <p className="text-[20px] font-[600] pb-3" data-testid='patient-info-title'>More Information</p>
                <div className="text-[16px] my-3" data-testid='patient-info-description'>
                The patient has provided more information. Learn more about
                their health conditions to prepare adequately ahead of your
                call.
              </div>
              <div>
                  <div className="flex justify-end mr-0 w-[117px]" data-testid='intake-form-container'>
                  <Button
                    height={60}
                    width={108}
                    type="submit"
                    variant="secondary"
                    label="View intake form"
                    onClick={() => {
                      setIsIntakeFormAnswersView(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </CustomDrawer>
    </div>
  );
};

export default ProviderAppointmentsDrawer;