import { useNavigate } from "react-router-dom";
import BtnButton from "../../../components/shared/BtnButton";
import { ReactComponent as AppointmentCountIcon } from "../../../svgs/appointment-count-card-icon.svg";

interface AppointmentsCountCardProps {
  appointmentCount?: React.ReactNode;
}

const AppointmentsCountCard: React.FC<AppointmentsCountCardProps> = ({
  appointmentCount
}) => {
  const navigate = useNavigate();

  return (
    <div data-testid='appointments-card' className="border-t-2 border-[#FEC22A] rounded-[8px] shadow-md shadow-[-2px 4px 25px #EEEEE9] bg-[#fff] w-full h-[300px] py-8 px-4 box-border flex flex-col gap-y-4">
      <div>
        <AppointmentCountIcon />
      </div>
      <p className="w-full text-left text-[#324705] font-semibood text-[16px] lg:text-[24px] py-4">
        <span className="w-full text-left pr-4 text-[#324705] font-[600]" data-testid='appointments-count'>
          {appointmentCount ?? 0}
        </span>
        Appointments today
      </p>
      <div className="mt-4 w-full flex justify-center" data-testid='appointments-button-container'>
        <BtnButton
          data-testid='appointments-view-all-button'
          type="button"
          variant="secondary"
          label="View all"
          onClick={() => navigate("/provider/appointments")}
        />
      </div>
    </div>
  );
};

export default AppointmentsCountCard;
