import clsx from "clsx";

interface ListTabItemProps {
  isActive: boolean;
  label: string;
  onClick: () => void;
}

const ListTabsItem: React.FC<ListTabItemProps> = ({
  isActive,
  label,
  onClick
}) => {
  return (
    <li className="h-full " data-testid='list-tab-items'>
      <button
        data-testid='list-tab-items-button'
        type="button"
        className={clsx({
          "w-[145px] min-[821px]:w-auto inline rounded-t-lg lg:text-base h-full pr-2 font-[400] min-[1280px]:font-[500] text-xs min-[1180px]:text-base ":
            true,
          "text-[#2E3011] hover:text-[#34623f] hover:border-b-[#34623f] border-b-transparent hover:border-b-[1.5px] font-medium":
            !isActive,
          "text-[#3D874E] font-semibold active border-b-[1px] border-b-solid border-b-[#3D874E]":
            isActive
        })}
        onClick={() => {
          onClick();
        }}
      >
        {label}
      </button>
    </li>
  );
};

export default ListTabsItem;
