import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";


type Payload = {
  documentationId: string;
  values?: any[]
  isLocked?: boolean;
}

export const useEditDocumentation = (selectedButtonType: string) => {
  const queryClient = useQueryClient();
  const { mutate, data, variables, ...rest } = useMutation({
    mutationKey: ['edit document'],
    mutationFn: async ({ ...data }: Payload) => {
      const response = await http.patch("documentation", data)
      return response.data;
    },

    onMutate: () => {
      const toastId = toast.loading('Please wait...')
      return { toastId }
    },

    onSuccess: (_data, _variables, context) => {
      const { toastId } = context
      toast.update(toastId, {
        render: `Documentation successfully ${selectedButtonType === "edit" ? "updated" : "locked"
          }`,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true
      });
      queryClient.invalidateQueries({
        queryKey: ["get initialVisitNote", "get progressNote"]
      });
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number

      if (axios.isAxiosError(error)) {
        toast.update(toastId, {
          render: `Error ${selectedButtonType === "edit" ? "updating" : "locking"
            } documentation`,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        })
      } else {
        toast.update(toastId, {
          render: error?.message || "Error processing your request",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
      }
    }
  });
  return {
    editDocumentation: mutate,
    data,
    variables,
    ...rest,
  };
}