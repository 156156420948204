
function BillingSettings() {
  const cards = [
    { id: "card1", number: "1234567812341234", card_img: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2048px-Visa_Inc._logo.svg.png" },
    { id: "card2", number: "9876543210987654" },
  ];

  // TODO: Implement this function https://www.npmjs.com/package/react-credit-cards-2

  function maskCardNumber(cardNumber: string) {
    return `•••• •••• •••• ${cardNumber.slice(-4)}`;
  }
  return (
    <div className="w-full h-full mx-auto p-6">
      <div className="w-full">
        <div className="">
          <h2 className="text-xl font-medium mb-4">Payment Information</h2>
          <div className="space-y-1 w-1/3">
            {cards.map((card, index) => (
              <div key={card.id} className="flex items-center space-x-3 border rounded-lg p-4 shadow-md">
                <input type="radio" name="preferredPayment" value={card.id} id={card.id} defaultChecked={index === 0} />
                <label htmlFor={card.id} className="flex items-center gap-3 cursor-pointer">
                  <div className="w-12 h-5 relative border rounded">
                    <img
                      src={card.card_img}
                      alt="card img"
                      className="object-contain p-1"
                    />
                  </div>
                  <span className="text-sm">{maskCardNumber(card.number)}</span>
                </label>
              </div>
            ))}
          </div>
          {/* <div className="flex items-center space-x-3 border rounded-lg p-4 shadow-md w-1/2 h-16"> */}
          <button className="mt-4 w-[202px] h-[42px] border rounded-[40px] text-[#34623F] flex items-center justify-center gap-[10px] border-[#34623F] font-generalSans font-normal leading-[25.6px] text-[16px]">
            Add payment method
          </button>
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}

export default BillingSettings

