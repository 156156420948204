import { Dialog, DialogPanel } from '@headlessui/react';
import { isAxiosError } from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import http from '../utils/http';

import { ReactComponent as CloseIcon } from "../svgs/close-icon.svg";

type Props = {
  handleClose: () => void;
  isOpen: boolean;
}

export function VideoModal({ handleClose, isOpen }: Props) {
  const [neverShow, setNeverShow] = useState(false);


  const handleNeverShowAgain = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setNeverShow(e.target.checked)
    http.patch("providers/details", {
      hideIntroModal: e.target.checked
    }).then(response => {
      if (response) {
        handleClose();
      }
    }).catch((error) => {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.error || 'Something went wrong, refresh the page', {
          toastId: 'never-show-again-error'
        })
      }
    })
  }

  return (
    <Dialog
      open={isOpen}
      as="div"
      className="relative z-10"
      onClose={handleClose}
    >
      <div
        className="fixed inset-0 bg-black/40 backdrop-blur-sm"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center" data-testid="video-modal">
        <DialogPanel className="w-4/5 lg:w-full max-w-3xl 2xl:max-w-4xl rounded-2xl overflow-hidden lg:ml-40 p-2">
          <div className="relative">
            <div className="flex justify-end pb-1">
              <button
                className="rounded-full bg-gray-200 p-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-colors close-modal-button"
                onClick={handleClose}
                aria-label="Close modal"
                data-testid="close-modal-button"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="bg-white shadow-2xl rounded-md">
              <div className="relative pb-[56.25%] h-0 overflow-hidden w-full">
                <iframe
                  title="Introduction to TempleHS"
                  src="https://www.youtube.com/embed/YzqPzMhe6A8?autoplay=1"
                  className="absolute top-0 left-0 w-full h-full border-none rounded-md"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  data-testid="video-iframe"
                />
              </div>
              <div className="p-6">
                <div className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    id="neverShow"
                    checked={neverShow}
                    onChange={handleNeverShowAgain}
                    className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                    data-testid="never-show-checkbox"
                  />
                  <label
                    htmlFor="neverShow"
                    className="text-sm font-medium text-gray-700 cursor-pointer"
                  >
                    Never show this again
                  </label>
                </div>
              </div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}