import { Divider } from "@mui/material";
import axios, { isAxiosError } from "axios";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getS3SignedUrl } from "../../../api/hooks/common/fetchers.common";
import { useChangeProviderPassword } from "../../../api/hooks/provider/changePassword";
import { useUpdateProviderDetails } from "../../../api/hooks/provider/updateProviderDetails";
import { useGetProviderProfileDetails } from "../../../api/hooks/provider/useGetProviderProfileDetails";
import { LicenseNumber } from "../../../api/types";
import FormikPhoneInput from "../../../components/form/FormikPhoneInput";
import PasswordField from "../../../components/form/PasswordField";
import TextAreaField from "../../../components/form/TextAreaField";
import TextField from "../../../components/form/TextField";
import TextFieldWithTags from "../../../components/form/TextFieldWithTags";
import Button from "../../../components/shared/Button";
import { Loading } from '../../../components/shared/Loading';
import Spacer from "../../../components/Spacer";
import { DeleteIcon } from "../../../svgs/DeleteIcon";
import { PlusIcon } from "../../../svgs/PlusIcon";
import { statesInTheUS } from "../../../utils/consts";
import { generateUUID } from "../../../utils/helpers";
import { getInitialValues, passwordInitialValues, passwordValidationSchema, validationSchema } from "./schema/data.schema";



const YourAccount = () => {
  const [licenseFormInputs, setLicenseFormInputs] = useState<LicenseNumber[]>([
    { number: "", state: "" },
  ]);
  const [tagInputValue, setTagInputValue] = useState("");
  const [showImageFileUpload, setShowImageFileUpload] = useState(false);

  const { providerData, isLoading, isError, error } = useGetProviderProfileDetails()
  const { user } = providerData ?? {};

  const { updateProviderProfileDetails, isPending } = useUpdateProviderDetails()
  const [imageUploadInProgress, setIsImageUploadInProgress] = useState(false)

  const { changeProviderPassword, isPending: isChangeProviderPasswordPending } = useChangeProviderPassword()

  useEffect(() => {
    if (user?.licenseNumbers) {
      setLicenseFormInputs([...user?.licenseNumbers]);
    } else {
      setLicenseFormInputs([]);
    }
  }, [user?.licenseNumbers]);


  const usaStates = Array.from(new Set(statesInTheUS));

  const phoneInputRef = useRef();

  const specialtyList: { name: string; value: string }[] = [
    { name: "Primary Care Physician", value: "pcp" },
    { name: "Psychiatrist", value: "psychiatrist" },
    { name: "Therapist", value: "therapist" }
  ];

  const formik = useFormik({
    initialValues: getInitialValues(user || {}),
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: () => { }
  });

  const passwordFormik = useFormik({
    initialValues: passwordInitialValues,
    validationSchema: passwordValidationSchema,
    onSubmit: () => { }
  });

  const handleFileUpload = (e: any) => {
    try {
      const file = e.target.files?.[0];
      if (!file) {
        toast.info("No file selected");
        return;
      }

      const maxAllowedSize = 2 * 1024 * 1024;
      if (file.size > maxAllowedSize) {
        toast.error("File size should be less than 2MB");
        return;
      } else {
        const key =
          generateUUID() + file.name?.slice(file.name?.lastIndexOf("."));
        (async () => {
          setIsImageUploadInProgress(true);
          const response = await getS3SignedUrl({
            operation: "putObject",
            key,
            object: "account"
          });
          if (response?.signedUrlParams) {
            const { signedUrl, host } = response.signedUrlParams;
            const avatarUrl = `${host}/${key}`;
            const [res] = await Promise.all([
              axios.put(signedUrl, file),
              updateProviderProfileDetails({ avatarUrl })
            ]);
            if (res.status === 200) {
              formik.setFieldValue('avatarUrl', avatarUrl);
              e.target.value = "";
              setShowImageFileUpload(false);
              setIsImageUploadInProgress(false);
              e.target.value = "";
            }
          }
        })();
      }
    } catch (error) {
      toast.error(
        "An error occurred. Please try your profile picture upload again."
      );
    }
  };

  const handleTagsInputChange = (event: any) => {
    setTagInputValue(event.target.value);
  };

  const handleRemoveTag = (index: number) => {
    const updatedFocusAreas = formik.values?.focusAreas?.filter((_, i) => i !== index);
    formik.setFieldValue('focusAreas', updatedFocusAreas);
  };


  if (isLoading) {
    return <div className="w-1/2 h-1/2 flex items-center justify-center">
      <Loading />
    </div>
  }

  if (isError) {
    if (isAxiosError(error)) {
      const errorMessage = error?.response?.data?.error
      toast.error(errorMessage,
        { toastId: "customId" })
    } else {
      toast.error(error?.error || "Error: Couldn't process your request", { toastId: "customId" })
    }
  }


  return (
    <>
      <div className="h-full w-full mb-10 overflow-y-auto" data-testid='your-account-container'>
        <form onSubmit={formik.handleSubmit}>
          <p className="font-[600]] text-[20px] text-black pl-4" data-testid='header-title'>
            Personal Information
          </p>
          <div className="flex w-full items-center my-6 ml-4">
            <div className="relative mr-8 h-[80px] w-[80px] rounded-full">
              <img
                className="w-full h-full rounded-full"
                src={formik.values.avatarUrl || "/assets/images/avatar.png"}
                width={96}
                height={96}
                alt="Profile img"
                data-testid='img'
              />
              {imageUploadInProgress && (
                <div className="absolute bg-[#000000] opacity-50 h-full w-[80px] rounded-full top-0 flex items-center justify-center">
                  Loading...
                </div>
              )}
            </div>
            <input
              onChange={handleFileUpload}
              type="file"
              accept="image/*"
              className={showImageFileUpload ? "" : "hidden"}
              data-testid='file-upload'
            />
            <p
              onClick={() => setShowImageFileUpload(!showImageFileUpload)}
              className="cursor-pointer text-[14px] font-[500] text-[#3D874E]"
            >
              {showImageFileUpload ? "Cancel" : "Change profile photo"}
            </p>
          </div>
          <div className="w-full lg:w-1/2 my-8 pl-4">
            <div className="w-full flex flex-row items-center justify-between my-4">
              <div className="w-[275px]">
                <TextField
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  value={formik.values?.firstName || ""}
                  onChange={formik.handleChange}
                  label="First Name"
                />
              </div>
              <div className="w-4" />
              <div className="w-[275px]">
                <TextField
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  value={formik.values?.lastName || ""}
                  onChange={formik.handleChange}
                  label="Last Name"
                />
              </div>
            </div>
            <div className="flex flex-row items-center justify-end my-4">
              <div className="w-full">
                <TextField
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  label="Email address"
                  value={formik.values?.email || ""}
                  onChange={formik.handleChange}
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-row items-center justify-end my-4">
              <div className="w-full" data-testid='phonenumber-coontainer'>
                <FormikPhoneInput
                  data-testid='phoneNumber'
                  ref={phoneInputRef}
                  name="phoneNumber"
                  label="Phone Number"
                  formik={formik}
                  placeholder="Enter phone number"
                  value={formik.values?.phoneNumber || ""}
                  onChange={(event: any) => {
                    formik.setFieldValue("phoneNumber", event);
                  }}
                  inputValid={
                    formik.touched.phoneNumber &&
                    !Object.keys(formik.errors).includes("phoneNumber")
                  }
                />
              </div>
            </div>
          </div>
          <div className="w-full pl-4">
            <Divider />
          </div>
          <div className="w-full lg:w-1/2 my-8 pl-4" data-testid='information-container'>
            <p className="font-[600] text-[20px] text-black" data-testid='info-header-title'>
              Professional Information
            </p>
            <div className="w-full my-6">
              <TextField
                name="providerNPI"
                type="text"
                placeholder="09399239717"
                label="Provider NPI"
                value={formik.values?.providerNPI || ""}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-full my-6">
              <TextField
                name="practiceNPI"
                type="text"
                placeholder="12348523"
                label="Practice NPI"
                value={formik.values?.practiceNPI || ""}
                onChange={formik.handleChange}
              />
            </div>
            <div className="flex flex-col justify-start items-start">
              {licenseFormInputs &&
                licenseFormInputs.map((input, index) => (
                  <div
                    className="w-full p-2 flex justify-start items-center"
                    key={index}
                  >
                    <React.Fragment>
                      <div className="w-[45%]">
                        <TextField
                          name="licenseNumbers"
                          type="text"
                          placeholder="121231"
                          value={input?.number}
                          onChange={(e) => {
                            const newArr: LicenseNumber[] = [...licenseFormInputs];
                            newArr[index] = {
                              ...newArr[index],
                              number: e.target.value
                            };
                            setLicenseFormInputs(newArr);
                            formik.setFieldValue("licenseNumbers", newArr);
                          }}
                          label={`License Number ${index + 1}`}
                        />
                      </div>
                      <Spacer width={20} />
                      <div className="w-[45%]" data-testid='license-state-container'>
                        <p className="text-[#000] font-medium mb-2 text-[16px]">
                          {`License State  ${index + 1}`}
                        </p>
                        <select
                          className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded h-[47px]"
                          name="licenseState"
                          value={input?.state}
                          onChange={(e) => {
                            const newArr: LicenseNumber[] = [...licenseFormInputs];
                            newArr[index] = {
                              ...newArr[index],
                              state: e.target.value
                            };
                            setLicenseFormInputs(newArr);
                            formik.setFieldValue("licenseNumbers", newArr);
                          }}
                          data-testid='select-license-state'
                        >
                          <option value="" disabled>
                            Select an option
                          </option>
                          {usaStates.map(state => (
                            <option value={state.toLowerCase()} key={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>
                      <Spacer width={20} />
                      <button
                        type="button"
                        className="mt-5 cursor-pointer"
                        onClick={() => {
                          const newInputs = [...licenseFormInputs];
                          newInputs.splice(index, 1);
                          setLicenseFormInputs(newInputs);
                          formik.setFieldValue("licenseNumbers", newInputs);
                        }}
                        data-testid='delete-license-numbers'
                      >
                        <DeleteIcon color="grey" />
                      </button>
                    </React.Fragment>
                  </div>
                ))}
              <button
                type="button"
                className="text-[#1A9D39] text-[16px] flex justify-center items-center"
                onClick={() => {
                  setLicenseFormInputs([
                    ...licenseFormInputs,
                    { number: "", state: "" }
                  ]);
                }}
                data-testid='add-new-fields'
              >
                <PlusIcon /> Add new fields
              </button>
            </div>
            <div className="relative flex flex-row justify-between w-[100%] mt-5 mb-5" data-testid='select-dea-container'>
              <div className="w-[50%]">
                <TextField
                  name="deaNumber"
                  type="text"
                  placeholder="121231"
                  value={formik.values?.deaNumber || ""}
                  onChange={formik.handleChange}
                  label="DEA Number"
                />
              </div>
              <Spacer width={20} />
              <div className="w-[50%]" >
                <p className="text-[#000] font-medium mb-2 text-[16px]" data-testid='dea-state-title'>
                  DEA State
                </p>
                <select
                  className="w-[100%] py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded h-[47px]"
                  name="deaState"
                  value={formik.values?.deaState || ""}
                  onChange={formik.handleChange}
                  data-testid='select-dea-state'
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {usaStates.map(state => (
                    <option value={state.toLowerCase()} key={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="w-full my-4">
              <div className="w-full h-[40]">
                <span className="flex flex-row items-center text-[#000] font-medium mb-2 text-[16px" data-testid='specialty-title'>
                  Specialty
                </span>
                <select
                  className="w-full py-3 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                  name="specialty"
                  value={formik.values?.specialty || ""}
                  disabled
                  data-testid='disabled-input-specialty'
                >
                  <option value="" disabled>
                    Specialty
                  </option>
                  {specialtyList.map((specialty, index) => {
                    return (
                      <option
                        key={index}
                        value={specialty.value}
                        id={specialty.value}
                      >
                        {specialty.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div data-testid='focus-area-container'>
              <TextFieldWithTags
                label="Focus areas"
                value={tagInputValue}
                tags={formik.values.focusAreas || []}
                handleKeyDown={(event) => {
                  if (event.key === "Enter" && tagInputValue.trim() !== "") {
                    formik.setFieldValue("focusAreas", [
                      ...(formik.values.focusAreas || []),
                      tagInputValue.trim(),
                    ]);
                    setTagInputValue("");
                  }
                }}
                handleInputChange={handleTagsInputChange}
                handleRemoveTag={handleRemoveTag}
              />
            </div>

            <div className="w-full my-4">
              <TextField
                name="title"
                type="text"
                placeholder="e.g MD, DO, NP, PA, LMHC, LCMHC"
                label="Title"
                value={formik.values?.title || ""}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-full my-4">
              <TextField
                name="zipCode"
                type="text"
                placeholder="90210"
                label="Zip Code"
                value={formik.values.zipCode || ""}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-full my-4">
              <TextField
                name="address"
                type="text"
                placeholder="149 Fink Ln, Linden, PA 17744, USA"
                label="Practice Address"
                value={formik.values?.address || ""}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-full my-4" data-testid='state-container'>
              <div data-testid='state'>State</div>
              <select
                className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded h-[47px]"
                name="state"
                value={formik.values?.state || ""}
                onChange={e => {
                  formik.setFieldValue("state", e.target.value);
                }}
                data-testid='select-state-input'
              >
                <option value={formik.values.state || ""} disabled>
                  {formik.values?.state || ""}
                </option>
                {usaStates.map(state => (
                  <option value={state.toLowerCase()} key={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full my-4">
              <div data-testid='city'>City</div>
              <div className="w-full">
                <TextField
                  name="city"
                  type="tel"
                  placeholder="Enter City"
                  value={formik.values?.city || ""}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>
          {/* ends here */}
          <div className="w-full pl-4">
            <Divider />
          </div>

          <div className="w-full lg:w-1/2 my-8 pl-4">
            <p className="font-[600] text-[20px] text-black my-6" data-testid='bio-header'>About You</p>
            <div className="w-full">
              <TextField
                name="pronoun"
                type="text"
                placeholder="He/Him"
                label="Preferred Pronouns"
                value={formik.values.pronoun || ""}
                onChange={formik.handleChange}
              />
            </div>
            <div className="flex flex-row items-center justify-end my-4">
              <div className="w-full">
                <TextField
                  name="languages"
                  type="text"
                  placeholder="Seperate languages with a comma if more than one"
                  label="Languages"
                  value={Array.isArray(formik.values.languages) ? formik.values.languages.join(', ') : formik.values.languages || ''}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="flex flex-row items-center justify-end my-4">
              <div className="w-full">
                <TextAreaField
                  name="bio"
                  rows={4}
                  placeholder="Write something about yourself"
                  label="Short Bio"
                  value={formik.values?.bio || ""}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>
        </form>

        <div className="w-full pl-4">
          <Divider />
        </div>

        <form
          onSubmit={passwordFormik.handleSubmit}
          className="w-full lg:w-1/2 my-8 pl-4"
        >
          <p className="font-[600]] text-[20px] text-black my-4" data-testid='password-label'>Password</p>
          <div className="w-full my-4  mb-12">
            <div className="w-full my-4">
              <PasswordField
                name="currentPassword"
                placeholder="At least 8 characters"
                value={passwordFormik.values?.currentPassword}
                onChange={passwordFormik.handleChange}
                label="Current password"
                showPasswordToggler={true}
              />
            </div>
            <div className="w-full my-4">
              <PasswordField
                name="newPassword"
                placeholder="At least 8 characters"
                value={passwordFormik.values?.newPassword}
                onChange={passwordFormik.handleChange}
                label="New password"
                showPasswordToggler={true}
              />
            </div>
            <div className="w-full my-4  mb-12">
              <PasswordField
                name="confirmNewPassword"
                placeholder="At least 8 characters"
                value={passwordFormik.values?.confirmNewPassword}
                onChange={passwordFormik.handleChange}
                label="Repeat new password"
                showPasswordToggler={true}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="h-[96px] w-full bg-white border-t border-[#ddd] bottom-0 sticky" data-testid='form-button-container'>
        <div className="h-full flex justify-end items-center">
          <p
            onClick={() => {
              formik.resetForm();
              passwordFormik.resetForm();
            }}
            className="text-sm text-[#3B3D24] font-[500] mr-4 cursor-pointer"
            data-testid='discard-all'
          >
            Discard all changes
          </p>
          {passwordFormik.values?.currentPassword !== "" ||
            passwordFormik.values?.newPassword !== "" ||
            passwordFormik.values?.confirmNewPassword !== "" ? (
            <Button
              type="button"
              variant="primary"
              label="Update password"
              size="medium"
              additionalClassname={"lg:mr-5"}
                loading={isChangeProviderPasswordPending}
              onClick={async () => {
                changeProviderPassword({
                  currentPassword: passwordFormik.values?.currentPassword,
                  newPassword: passwordFormik.values?.newPassword
                });

              }}
            />
          ) : (
              <div className="w-[122px] lg:mr-5" data-testid='save-changes-button-container'>
              <Button
                type="button"
                variant="primary"
                label="Save changes"
                size="medium"
                  loading={isPending}
                  onClick={() => {
                    updateProviderProfileDetails({
                      ...(formik.values.firstName && { firstName: formik.values.firstName }),
                      ...(formik.values.lastName && { lastName: formik.values.lastName }),
                      ...(formik.values.phoneNumber && { phoneNumber: formik.values.phoneNumber }),
                      ...(formik.values.providerNPI && { providerNPI: formik.values.providerNPI }),
                      ...(formik.values.practiceNPI && { practiceNPI: formik.values.practiceNPI }),
                    ...(formik.values.licenseNumbers && { licenseNumbers: formik.values.licenseNumbers }),
                    ...(formik.values.deaState && { deaState: formik.values.deaState }),
                    ...(formik.values.deaNumber && { deaNumber: formik.values.deaNumber }),
                    ...(formik.values.address && { address: formik.values.address }),
                    ...(formik.values.title && { title: formik.values.title }),
                    ...(formik.values.pronoun && { pronoun: formik.values.pronoun }),
                    ...(formik.values.zipCode && { zipCode: formik.values.zipCode }),
                    ...(formik.values.bio && { bio: formik.values.bio }),
                    ...(formik.values.languages && {
                      languages: Array.isArray(formik.values.languages)
                        ? formik.values.languages
                        : [formik.values.languages],
                    }),
                    city: formik.values.city || '',
                    state: formik.values.state || '',
                    ...(formik.values.focusAreas && {
                      focusAreas: Array.isArray(formik.values.focusAreas) ? formik.values?.focusAreas :
                        [formik.values?.focusAreas ?? []],
                    }
                    )
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default YourAccount;
