const ToggleSwitch = ({
  label,
  onClick,
  isSelected,
  testId
}: {
  label: string;
  onClick: (e?: any) => void;
  isSelected: boolean;
    testId?: string;
}) => {
  const toggleClass = " transform translate-x-4 transition !bg-[#3d874e]";

  return (
    <div className="flex items-center my-4">
      <div
        className={
          "lg:w-10 lg:h-5 w-10 h-5 flex items-center rounded-full p-1 cursor-pointer border-solid border-[1px] border-[#cecfc5]" +
          (isSelected ? " bg-[#f4fef6] !border-[#3d874e]" : " bg-[#f8f8f7]")
        }
        onClick={(e) => onClick(e)}
      >
        <div
          className={
            "bg-[#cecfc5] lg:w-4 lg:h-4 h-4 w-4 rounded-full shadow-md transform duration-1000 transition" +
            (isSelected ? toggleClass : null)
          }
          data-testid={testId}
        />
      </div>
      <p className="pl-2" data-testid={`toggle-${label}`}
      >{label}</p>
    </div>
  );
};

export default ToggleSwitch;
