import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { Column } from "react-table";
import { ProviderPatient } from '../../../api/types';
import ReusableTable from "../../../components/ReusableTable";
import SearchField from "../../../components/form/SearchField";
import Button from "../../../components/shared/Button";
import ModalPortal from "../../../components/shared/ModalPortal";
import { ArrowDownIcon } from "../../../svgs/ArrowDownIcon";
import { ReactComponent as EmptyMsgIcon } from "../../../svgs/empty-smg.svg";
import MobilePatientCard from "../../components/MobilePatientCard";
import AddPatientFile from "./AddPatientFile";
import AddSinglePatient from "./AddSinglePatient";


type Props = {
  patients: ProviderPatient;
  setSelectedPatient: (id: string) => void;
}
const ProviderPatients = ({ patients, setSelectedPatient }: Props) => {
  const TABLE_COLUMNS = useMemo<Column<any>[]>(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value || "N/A"}</React.Fragment>;
        }
      },
      {
        Header: "Age",
        accessor: "age",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value || "N/A"}</React.Fragment>;
        }
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Last Session",
        accessor: "lastSession",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {value ? format(new Date(value), "EEEE, dd LLL") : "N/A"}
            </React.Fragment>
          );
        }
      }
    ],
    []
  );

  const [searchTerm, setSearchTerm] = useState<any>("");
  const [showSelectAddPatientFormat, setShowSelectAddPatientFormat] = useState<boolean>(false);
  const [showAddSinglePatient, setShowAddSinglePatient] = useState<boolean>(false);
  const [showAddPatientFile, setShowAddPatientFile] = useState<boolean>(false);

  const updateSearch = (event: any) => {
    setSearchTerm(event.target.value.trim().substr(0, 30));
  };

  const filteredData =
    patients &&
    patients?.providersPatients?.filter((item) => {
      return item?.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
    });

  return (
    <>
      {showSelectAddPatientFormat && (
        <ModalPortal>
          <section className="fixed bg-[#eeeee9] rounded-md top-[185px] right-0 w-[320px] z-20 flex flex-col justify-evenly gap-4 p-[30px]" data-testid='add-patient-modal-button-container'>
            <Button
              label="Add One Patient"
              variant="secondary"
              onClick={() => setShowAddSinglePatient(true)}
            />
            <Button
              label="Upload CSV"
              variant="secondary"
              onClick={() => setShowAddPatientFile(true)}
            />

          </section>
        </ModalPortal>
      )}
      {showAddSinglePatient && (
        <AddSinglePatient
          setShowAddSinglePatient={setShowAddSinglePatient}
        />
      )}
      {showAddPatientFile && (
        <AddPatientFile
          setShowAddPatientFile={setShowAddPatientFile}
        />
      )}
      <div className="w-full mt-6">
        <div className="w-full">
          <div className="w-full">
            {patients?.providersPatients?.length ? (
              <>
                <div className="w-full hidden lg:block">
                  <ReusableTable
                    tableColumns={TABLE_COLUMNS}
                    tableData={patients?.providersPatients}
                    searchFieldPlaceholder="Search Patients"
                    onClick={a => {
                      setSelectedPatient(a);
                      const url =
                        window.location.protocol +
                        "//" +
                        window.location.host +
                        `/provider/patients/${a.userId}/details`;
                      window.history.pushState({ path: url }, "", url);
                    }}
                    headerButton={true}
                    headerButtonLabel="Add Patient(s)"
                    headerButtonFunction={() =>
                      setShowSelectAddPatientFormat(!showSelectAddPatientFormat)
                    }
                  />
                </div>

                <div className="w-fullblock lg:hidden pb-2">
                  <SearchField
                    placeholder="Search Patients"
                    value={searchTerm}
                    onChange={updateSearch}
                    name="searchTerm"
                  />
                </div>

                <div className="w-full block lg:hidden lg:mt-8 shadow-[-1.74129px_3.48259px_21.7662px_#EEEEE9]">
                  <p className="w-full h-[53px] bg-[#EEEEE9] text-[#34623F] text-[500] flex items-center my-2 px-4">
                    Patients
                  </p>
                  {filteredData &&
                    filteredData?.map((item: any, ind: number) => (
                      <MobilePatientCard
                        key={ind}
                        patientDetails={item}
                        onClick={() => {
                          setSelectedPatient(item);
                          const url =
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            `/provider/patients/${item.userId}/details`;
                          window.history.pushState({ path: url }, "", url);
                        }}
                      />
                    ))}
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-end mr-5 mt-12">
                  <Button
                    variant="primary"
                    size="large"
                      label="Add Patient(s)"
                    onClick={() =>
                      setShowSelectAddPatientFormat(!showSelectAddPatientFormat)
                    }
                    icon={<ArrowDownIcon color="#fff" />}
                    iconPosition="right"
                  />{" "}
                </div>
                  <div className="h-full w-full m-auto mt-[50px] flex flex-col justify-center items-center" data-testid='empty-state-container'>
                    <EmptyMsgIcon data-testid='empty-msg-icon' />
                    <div className="font-semibold text-[#34623f] mt-4 text-center" data-testid='empty-state-text'>
                    Nothing here
                  </div>
                    <div className="text-[#103C1B] pt-2 lg:w-[322px] text-center" data-testid='empty-state-subtext'>
                    No patient found
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderPatients;
