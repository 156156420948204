import { capitalize } from "@mui/material";
import clsx from "clsx";
import { status } from "../../../../api/types";


export const StatusEl = ({ status }: { status: status }) => (
  <div className="flex items-center">
    <p
      data-testid='status'
      className={clsx({
        "flex justify-center items-center rounded-[32px] w-[105px] h-[32px]":
          true,
        "text-[#E45B3D] bg-[#FEF0ED] border border-[#F9B3A3] ":
          status === "draft",
        "text-[#34623F] bg-[#E7FBEC] border border-[#8BDF9F]":
          status === "approved",
        "text-[#5F91C0] bg-[#F8F8F7] border border-[#CECFC5]":
          status === "processing"
      })}
    >
      {status && capitalize(status)}
    </p>
  </div>
);