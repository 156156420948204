import { useMutation } from "@tanstack/react-query";
import http from "../../../utils/http";


export const useConfirmAppointmentPayment = () => {
  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationKey: ['confirm-payment'],
    mutationFn: async ({ sessionId }: { sessionId: string }) => {
      const response = await http.post('stripe/retrieve-session', {
        session: sessionId,
      });
      return response.data;
    },
  });

  return {
    retrieveSessionData: mutateAsync,
    isPending,
    isError,
    error,
  };
};
