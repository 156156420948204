import axios, { isAxiosError } from "axios";
import { addMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { syncUserCalendar } from "../../../api/common";
import { useCreateOrUpdateProviderSchedule } from "../../../api/hooks/provider/useCreateOrUpdateProviderSchedule";
import { useGetProviderProfileDetails } from "../../../api/hooks/provider/useGetProviderProfileDetails";
import { useGetProviderSchedule } from "../../../api/hooks/provider/useGetProviderSchedule";
import { useTriggerCalendarAuthCode } from "../../../api/hooks/provider/useTriggerSaveCalendarAuthCode";
import { TimeRange } from "../../../api/types";
import Button from "../../../components/shared/Button";
import CustomDropdown from "../../../components/shared/CustomDropdown";
import { Loading } from "../../../components/shared/Loading";
import ToggleSwitch from "../../../components/shared/ToggleSwitch";
import { DeleteIcon } from "../../../svgs/DeleteIcon";
import { PlusWithCircleIcon } from "../../../svgs/PlusWithCircleIcon";
import { DayParams } from "./utils/day_params";


const convertTimeRange = ({
  timeRange,
  offset,
  toUTC
}: {
  timeRange: TimeRange;
  offset: number;
  toUTC: boolean;
}) => {
  const previousDay: Array<string> = [];
  const sameDay: Array<string> = [];
  const nextDay: Array<string> = [];
  const adjustedOffset = toUTC ? -offset : offset;

  timeRange.forEach((time, index) => {
    const [hours, minutes] = time.split(":").map(Number);
    let isPrevDay = false;
    let isNextDay = false;

    let totalMinutes = hours * 60 + minutes;

    totalMinutes -= adjustedOffset;

    if (totalMinutes < 0) {
      totalMinutes = 24 * 60 + totalMinutes;
      isPrevDay = true;
    } else if (totalMinutes >= 1440) {
      isNextDay = true;
    }

    const newHours = Math.floor(totalMinutes / 60) % 24;
    const newMinutes = totalMinutes % 60;

    const formatTime = (unit: number) => unit.toString().padStart(2, "0");
    const newTime = `${formatTime(newHours)}:${formatTime(newMinutes)}`;

    if (isPrevDay) {
      previousDay.push(newTime);
    } else if (
      !isPrevDay &&
      !isNextDay &&
      index === 1 &&
      previousDay.length === 1
    ) {
      previousDay.push("00:00");
      if (newTime !== "00:00") {
        sameDay.push(...["00:00", newTime]);
      }
    } else if (isNextDay && index === 1 && sameDay.length === 1) {
      sameDay.push("00:00");
      if (newTime !== "00:00") {
        nextDay.push(...["00:00", newTime]);
      }
    } else if (isNextDay) {
      nextDay.push(newTime);
    } else {
      sameDay.push(newTime);
    }
  });
  return { previousDay, sameDay, nextDay };
};

const processDaySchedule = ({
  day,
  schedule,
  offset,
  dayMap,
  toUTC
}: {
  day: string;
  schedule: Array<TimeRange>;
  offset: number;
  dayMap: { [key: string]: Array<Array<string>> };
  toUTC: boolean;
}) => {
  schedule?.forEach(timeRange => {
    const { previousDay, sameDay, nextDay } = convertTimeRange({
      timeRange,
      offset,
      toUTC
    });

    if (previousDay.length) {
      const prevDay = getPreviousDay(day);
      dayMap[prevDay].unshift(previousDay);
    }
    if (sameDay.length) {
      dayMap[day].push(sameDay);
    }
    if (nextDay.length) {
      const nextDayName = getNextDay(day);
      dayMap[nextDayName].push(nextDay);
    }
  });
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const getPreviousDay = (day: string): string => {
  const index = days.indexOf(day);
  return days[(index + 6) % 7];
};

const getNextDay = (day: string): string => {
  const index = days.indexOf(day);
  return days[(index + 1) % 7];
};

const Calendar = () => {

  const searchParams = useSearchParams()[0];
  const authorizationCode = searchParams.get("code");
  const [selectedFirstAppt, setSelectedFirstAppt] = useState<number>(60);
  const [selectedFollowUpAppt, setSelectedFollowupAppt] = useState<number>(30);

  const [sundayTimes, setSundayTimes] = useState<string[][]>([
    ["00:00", "00:00"]
  ]);
  const [mondayTimes, setMondayTimes] = useState<string[][]>([
    ["00:00", "00:00"]
  ]);
  const [tuesdayTimes, setTuesdayTimes] = useState<string[][]>([
    ["00:00", "00:00"]
  ]);
  const [wednesdayTimes, setWednesdayTimes] = useState<string[][]>([
    ["00:00", "00:00"]
  ]);
  const [thursdayTimes, setThursdayTimes] = useState<string[][]>([
    ["00:00", "00:00"]
  ]);
  const [fridayTimes, setFridayTimes] = useState<string[][]>([
    ["00:00", "00:00"]
  ]);
  const [saturdayTimes, setSaturdayTimes] = useState<string[][]>([
    ["00:00", "00:00"]
  ]);

  const [isAuthorizingCalendar, setIsAuthorizingCalendar] =
    useState<boolean>(false);

  const { providerData, isLoading: isProviderLoading, isError: isProviderError, error: providerError } = useGetProviderProfileDetails();


  const { createOrUpdateProviderSchedule, isPending } =
    useCreateOrUpdateProviderSchedule();

  const submitSchedule = () => {
    const inputError = Object.values(timeError).find(
      (item: any) => item.length
    );
    if (inputError && typeof inputError === 'string') {
      toast.error(inputError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        toastId: "create-update-schedule-err"
      });
      return;
    }
    const schedule = [
      dayStatus.Monday && { day: "Monday", schedule: mondayTimes },
      dayStatus.Tuesday && { day: "Tuesday", schedule: tuesdayTimes },
      dayStatus.Wednesday && { day: "Wednesday", schedule: wednesdayTimes },
      dayStatus.Thursday && { day: "Thursday", schedule: thursdayTimes },
      dayStatus.Friday && { day: "Friday", schedule: fridayTimes },
      dayStatus.Saturday && { day: "Saturday", schedule: saturdayTimes },
      dayStatus.Sunday && { day: "Sunday", schedule: sundayTimes }
    ];

    const dayMap: { [key: string]: Array<Array<string>> } = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: []
    };

    if (Array.isArray(schedule)) {
      const validSchedules = schedule.filter((item): item is { day: string; schedule: string[][] } => item !== false);

      validSchedules.forEach(({ day, schedule }) => {
        const timeRanges: TimeRange[] = schedule.map((timeRange) => {
          if (timeRange.length === 2) {
            return [timeRange[0], timeRange[1]] as TimeRange;
          }
          throw new Error(`Invalid time range: ${timeRange}`);
        });

        processDaySchedule({ day, schedule: timeRanges, offset, dayMap, toUTC: true });
      });
    }

    createOrUpdateProviderSchedule({
      firstCallDuration: selectedFirstAppt,
      followUpCallDuration: selectedFollowUpAppt,
      ...(dayMap["Monday"] && { Monday: dayMap["Monday"] }),
      ...(dayMap["Tuesday"] && { Tuesday: dayMap["Tuesday"] }),
      ...(dayMap["Wednesday"] && { Wednesday: dayMap["Wednesday"] }),
      ...(dayMap["Thursday"] && { Thursday: dayMap["Thursday"] }),
      ...(dayMap["Friday"] && { Friday: dayMap["Friday"] }),
      ...(dayMap["Saturday"] && { Saturday: dayMap["Saturday"] }),
      ...(dayMap["Sunday"] && { Sunday: dayMap["Sunday"] }),
      offset: new Date().getTimezoneOffset()
    });
  };

  const {
    refetch,
    error: CalError,
    isError: isCalError
  } = useTriggerCalendarAuthCode(String(authorizationCode));

  if (isCalError) {
    const message = axios.isAxiosError(CalError)
      ? CalError?.response?.data?.error
      : "Error processing request";
    toast.error(message, { toastId: "customId" });
  }

  useEffect(() => {
    if (authorizationCode) {
      refetch();
    }
  }, [refetch, authorizationCode]);

  const offset = new Date().getTimezoneOffset();

  const { schedule, isError, error, isLoading } = useGetProviderSchedule();

  useEffect(() => {
    (() => {
      setSelectedFirstAppt(schedule?.firstCallDuration);
      setSelectedFollowupAppt(schedule?.followUpCallDuration);

      (() => {
        const dayMap: { [key: string]: Array<Array<string>> } = {
          Sunday: [],
          Monday: [],
          Tuesday: [],
          Wednesday: [],
          Thursday: [],
          Friday: [],
          Saturday: []
        };

        schedule?.schedule?.forEach(({ day, schedule }: { day: string; schedule: TimeRange[] }) => {

          processDaySchedule({ day, schedule, offset, dayMap, toUTC: false });
        });

        setMondayTimes(dayMap["Monday"].sort());
        setTuesdayTimes(dayMap["Tuesday"].sort());
        setWednesdayTimes(dayMap["Wednesday"].sort());
        setThursdayTimes(dayMap["Thursday"].sort());
        setFridayTimes(dayMap["Friday"].sort());
        setSaturdayTimes(dayMap["Saturday"].sort());
        setSundayTimes(dayMap["Sunday"].sort());
      })();
    })();
  }, [
    schedule?.firstCallDuration,
    schedule?.followUpCallDuration,
    schedule?.schedule,
    offset
  ]);

  if (isError) {
    const message = axios.isAxiosError(error)
      ? error?.response?.data?.error
      : "Error processing request";
    toast.error(message, { toastId: "customId" });
  }

  const [timeError, setTimeError] = useState<any>({
    Monday: "",
    Tuesday: "",
    Wednesday: "",
    Thursday: "",
    Friday: "",
    Saturday: "",
    Sunday: ""
  });

  const [dayStatus, setDayStatus] = useState<{
    Monday: boolean;
    Tuesday: boolean;
    Wednesday: boolean;
    Thursday: boolean;
    Friday: boolean;
    Saturday: boolean;
    Sunday: boolean;
  }>({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true
  });

  const selectTimes = Array.from(Array(97).keys()).map(value => {
    const newTime = addMinutes(new Date(2023, 3, 3, 0, 0), 15 * value)
      .toTimeString()
      .slice(0, 5);
    return { name: newTime, value: newTime };
  });


  if (isLoading || isProviderLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loading />
      </div>
    );
  }

  if (isError) {
    if (isAxiosError(error)) {
      const message = error?.response?.data?.error;
      toast.error(message, { toastId: "customId" });

    } else {
      toast.error(error?.error, { toastId: "customId" });
    }
  }

  if (isProviderError) {
    if (isAxiosError(providerError)) {
      const message = providerError?.response?.data?.error;
      toast.error(message, { toastId: "customId" });

    } else {
      toast.error(providerError?.error, { toastId: "customId" });
    }
  }

  const dayparams = DayParams({
    dayStatus,
    saturdayTimes,
    sundayTimes,
    mondayTimes,
    tuesdayTimes,
    wednesdayTimes,
    thursdayTimes,
    fridayTimes,
    setSaturdayTimes,
    setSundayTimes,
    setMondayTimes,
    setTuesdayTimes,
    setWednesdayTimes,
    setThursdayTimes,
    setFridayTimes
  })

  return (
    <>
      <div className="h-full w-full mb-10 overflow-y-auto">
        <h1 className="font-[600] text-[20px] text-black pl-4 pb-2" data-testid='header-title'>
          Set Availability
        </h1>
        <div className="p-4">
          <p className="pb-2 font-[500]" data-testid='header-sub-title'>Sync Your Calendars</p>
          {providerData?.user?.googleRefreshToken && providerData?.user?.googleRefreshToken ? (
            <p data-testid='sync-title'>Google calendar synced</p>
          ) : (
            <div className="w-[122px]" data-testid='add-to-calendar-button-container'>
              <Button
                variant="secondary"
                label="+ Add Calendar"
                textColor="#3D874E"
                size="medium"
                onClick={async () => {
                  setIsAuthorizingCalendar(true);
                  const data = await syncUserCalendar({
                    type: "google",
                    user: "provider"
                  });
                  setIsAuthorizingCalendar(false);
                  if (data?.url) {
                    window.open(data.url, "myWindow", "width=800,height=600");
                  }
                }}
                loading={isAuthorizingCalendar}
              />
            </div>
          )}
          <p className="pt-6 pb-3 font-[500]" data-testid='duration'>Set Appointment Duration</p>
          <div className="w-full lg:w-[40%] xl:w-[34%] 2xl:w-[28%] flex items-center justify-start">
            <div data-testid='appointment-title'>First Appointment</div>
            <div className="w-[1/2] ml-16">
              <CustomDropdown
                placeholder="60 mins"
                defaultValue="60 mins"
                name="firstAppointment"
                value={selectedFirstAppt}
                onChange={e => setSelectedFirstAppt(e.target.value)}
                optionsList={[
                  { name: "15 mins", value: "15" },
                  { name: "30 mins", value: "30" },
                  { name: "45 mins", value: "45" },
                  { name: "60 mins", value: "60" },
                  { name: "75 mins", value: "75" },
                  { name: "90 mins", value: "90" }
                ]}
                testId="first-appointment-dropdown"
              />
            </div>
          </div>
          <div className="w-full lg:w-[40%] xl:w-[34%] 2xl:w-[28%] flex py-5 items-center justify-start">
            <div data-testid='follow-up-title'>Follow-up Appointment</div>
            <div className="w-[1/2] ml-6">
              <CustomDropdown
                placeholder="30 mins"
                defaultValue="30 mins"
                name="firstAppointment"
                value={selectedFollowUpAppt}
                onChange={e => setSelectedFollowupAppt(e.target.value)}
                optionsList={[
                  { name: "15 mins", value: "15" },
                  { name: "30 mins", value: "30" },
                  { name: "45 mins", value: "45" },
                  { name: "60 mins", value: "60" },
                  { name: "75 mins", value: "75" },
                  { name: "90 mins", value: "90" }
                ]}
                testId="follow-up-appointment-dropdown"
              />
            </div>
          </div>
          <p className="py-2 font-[500]" data-testid="available-days"
          >Available Days & Times</p>
          <div>
            <div>
              {dayparams?.map(
                ({ day, lists, fn, status }: any, index: number) => (
                  <div key={index} data-testid='toggle-container'>
                    <div className="flex flex-wrap lg:flex-nowrap w-full lg:w-[82%] items-center xl:w-[68%] 2xl:w-[65%]" data-testid='day-container'>
                      <div className="self-start pt-2 w-full lg:w-[200px]">
                        <ToggleSwitch
                          label={day}
                          isSelected={status && lists.length}
                          onClick={() => {
                            setDayStatus({ ...dayStatus, [day]: !status });
                            if (!lists.length) {
                              fn([["00:00", "00:00"]]);
                            }
                          }}
                          testId={day}
                        />
                      </div>
                      <div className="flex justify-start items-center  w-full">
                        <div className=" py-3 w-full lg:w-[320px]">
                          {lists.length && status ? (
                            lists.map((list: any, index: number) => (
                              <div
                                className="flex items-center py-2"
                                key={index}
                              >
                                <div className="w-[30] pr-4" data-testid='start-time-container'>
                                  <CustomDropdown
                                    testId={`${day}-start-time`}
                                    placeholder="00:00"
                                    defaultValue="00:00"
                                    name="availability"
                                    value={list[0]}
                                    onChange={e => {
                                      const newList = [...list];
                                      newList[0] = e.target.value;
                                      fn(
                                        lists.map((value: any, ind: any) =>
                                          ind === index ? newList : value
                                        )
                                      );
                                      if (
                                        list[1]?.replace(":", "") <
                                        e.target.value.replace(":", "") &&
                                        list[1] !== "00:00"
                                      ) {
                                        setTimeError({
                                          ...timeError,
                                          [day]:
                                            "Choose an end time later than the start time"
                                        });
                                      } else {
                                        setTimeError({
                                          ...timeError,
                                          [day]: ""
                                        });
                                      }
                                      if (index > 0) {
                                        if (
                                          lists[index - 1][1] > e.target.value
                                        ) {
                                          setTimeError({
                                            ...timeError,
                                            [day]:
                                              "Time overlaps with another set of times"
                                          });
                                        }
                                      }
                                    }}
                                    optionsList={selectTimes}
                                  />
                                </div>
                                <div>&rarr;</div>
                                <div className="w-[30] px-4" data-testid='end-time-container'>
                                  <CustomDropdown
                                    testId={`${day}-end-time`}
                                    placeholder="00:00"
                                    defaultValue="00:00"
                                    name="availability"
                                    value={list[1]}
                                    onChange={e => {
                                      const newList = [...list];
                                      newList[1] = e.target.value;
                                      fn(
                                        lists.map((value: any, ind: any) =>
                                          ind === index ? newList : value
                                        )
                                      );
                                      if (
                                        e.target.value.replace(":", "") <
                                        list[0].replace(":", "")
                                      ) {
                                        setTimeError({
                                          ...timeError,
                                          [day]:
                                            "Choose an end time later than the start time"
                                        });
                                      } else {
                                        setTimeError({
                                          ...timeError,
                                          [day]: ""
                                        });
                                      }
                                    }}
                                    optionsList={selectTimes}
                                  />
                                </div>
                                <div
                                  data-testid="delete-button"
                                  className="px-4"
                                  onClick={() => {
                                    fn(
                                      lists.filter(
                                        (item: any, ind: number) =>
                                          ind !== index
                                      )
                                    );
                                    if (lists.length === 1) {
                                      setDayStatus({
                                        ...dayStatus,
                                        [day]: false
                                      });
                                    }
                                  }}
                                >
                                  <DeleteIcon className="cursor-pointer" />
                                </div>
                                <div className="lg:justify-self-start">
                                  <div
                                    onClick={() => {
                                      if (!status) {
                                        setDayStatus({
                                          ...dayStatus,
                                          [day]: !status
                                        });
                                      } else {
                                        fn([
                                          ...lists,
                                          [
                                            lists.length
                                              ? lists[lists.length - 1][1]
                                              : "00:00",
                                            "00:00"
                                          ]
                                        ]);
                                      }
                                    }}
                                  >
                                    <PlusWithCircleIcon className="cursor-pointer" />
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p className="pt-4" data-testid="unavailable-text">Unavailable</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {timeError[day] && (
                      <p className="text-red-500">{timeError[day]}</p>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="h-[96px] w-full bg-white border-t border-[#ddd] bottom-0 sticky" data-testid="button-container">
        <div className="h-full flex justify-end items-center">
          <div className="w-[122px] lg:mr-5">
            <Button
              type="button"
              variant="primary"
              label="Save changes"
              size="medium"
              loading={isPending}
              onClick={submitSchedule}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;