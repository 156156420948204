import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as EmailBoxIcon } from "../../svgs/email-box.svg";

import { resendEmail } from "../../api/auth";
import Button from "../../components/shared/Button";
import Spacer from "../../components/Spacer";

const SPVerifyEmail = () => {
  const { email, userType, userId } = useParams();
  const [isSendingVerifyEmail, setIsSendingVerifyEmail] = useState(false);

  const handleResendVerificationEmail = async () => {
    try {
      setIsSendingVerifyEmail(true);
      const res = await resendEmail(userType ?? "billers", userId ?? "");
      setIsSendingVerifyEmail(false);
      toast(res.message ?? "Verification email sent");
    } catch (e: any) {
      setIsSendingVerifyEmail(false);
      toast(e?.message ?? "Failed to send verification email");
    }
  };

  return (
    <div className="w-full box-border lg:w-[614px] bg-white flex flex-col justify-center h-full">
      <div className="text-center">
        <div className="w-full mx-auto flex justify-center">
          <EmailBoxIcon />
        </div>
        <Spacer height={30} />
        <p className="text-[34px] font-[800] text-[#103C1B]">
          Verify your email
        </p>
        <Spacer height={10} />
        <p className="font-[400] text-[20px] text-[#103C1B] w-full lg:w-[555px] mx-auto">
          We sent a link to your email{" "}
          <b>{email || "(omolaraatoyebi@gmail.com)"}</b>. Please click on the
          link to verify that the email belongs to you and you have access to
          it.
        </p>
      </div>
      <div className="lg:h-[40px] h-[20px]" />
      <div className="flex justify-center items-center w-full mx-auto">
        <Link to="#">
          <Button
            additionalClassname={"text-[18px] font-[400]"}
            width={178}
            height={60}
            onClick={handleResendVerificationEmail}
            type="submit"
            variant="primary"
            label="Resend email"
            loading={isSendingVerifyEmail}
          />
        </Link>
      </div>
    </div>
  );
};

export default SPVerifyEmail;
