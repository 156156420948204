import { Form, Formik } from "formik";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { usePracticeRegisteration } from '../../../api/hooks/business_office/practice_registration';
import { useInitialOneTimePayment } from "../../../api/hooks/common/initiateOneTimePayment";
import RadioField from "../../../components/form/RadioField";
import TextField from "../../../components/form/TextField";
import Button from "../../../components/shared/Button";
import CheckWithLabelToggle from "../../../components/shared/CheckWithLabelToggle";
import { statesInTheUS } from "../../../utils/consts";
import { removeEmptyObjectValues } from "../../../utils/helpers";
import { biz_reg_initialValues } from './interface/biz_reg_types';
import { BizRegSchema } from './schemas/biz_reg_schema';




const PracticeRegistration = () => {
  const urlLocation = useLocation(); 

  const [isSameAsBusinessAddress, setIsSameAsBusinessAddress] = useState(false);
  const [isSameAsMailingAddress, setIsSameAsMailingAddress] = useState(false);
  const [wantsToRegisterBusiness, setWantsToRegisterBusiness] = useState(false);

  const { initiateOneTimePayment, isPending } = useInitialOneTimePayment()
  const { practiceRegisteration, isPending: isPracticeRegisterationPending } = usePracticeRegisteration()

  // const onSubmit = async (values: FormValues) => {


  // };

  return (
    <>
      <div className="mb-5 px-5 md:px-10 lg:px-20">
        <h2 className="text-[18px]" data-testid='practice_reg_title'>Do you want to register your practice?</h2>
        <RadioField
          name="seeRegistrationForm"
          placeholder="Register"
          display="block"
          options={[
            { item: "Yes", value: true },
            { item: "No", value: false }
          ]}
          value={wantsToRegisterBusiness}
          onChange={e => {
            if (e.target.value === "true") {
              setWantsToRegisterBusiness(true);
            } else {
              setWantsToRegisterBusiness(false);
            }
          }}
        />
      </div>
      {wantsToRegisterBusiness && (
        <div className="h-full w-full overflow-y-auto mb-10 sm:ml-10">
          <h1 className="text-center font-[600] text-[28px] text-neutral-900" data-testid='practice_reg_form_title'>
            Business Registration Form
          </h1>
          <div className="w-full text-center flex flex-row items-center justify-center mt-4">
            <p className="text-center text-[14px] font-[500] text-neutral-900 w-[50%]" data-testid='practice_reg_form_description'>
              To register your practice, complete the business registration form
              below and pay a one-time processing fee.
            </p>
          </div>
          <div className="my-7 space-y-2">
            <div className="font-bold" data-testid='price'>Price</div>
            <div className="font-normal" data-testid='price_value'>$500.00</div>
            <div className="font-normal" data-testid='price_processing_fees'>Processing Fees: $15.24</div>
            <div className="font-bold" data-testid='price_total'>Amount Due: $515.24</div>
            <div className="italic text-[14px] w-full sm:w-2/4" data-testid='price_description'>
              This fee will cover the filling fee with Secretary of State's
              office, Registration Agent fees and EIN fees
            </div>
          </div>
          <div className="mt-6 w-full flex items-center justify-center flex-wrap">
            <div className="mt-6 w-full md:w-[80%]">
              <div className="text-primary-main text-[24px] font-[500] my-4" data-testid='practice_personal_info'>
                Personal Information
              </div>
              <Formik
                initialValues={biz_reg_initialValues}
                validationSchema={BizRegSchema}
                onSubmit={(values) => {
                  const newValues = removeEmptyObjectValues({
                    businessAddress1: isSameAsBusinessAddress
                      ? values.address1
                      : values.businessAddress1,
                    businessAddress2: isSameAsBusinessAddress
                      ? values.address2
                      : values.businessAddress2,
                    businessCity: isSameAsBusinessAddress ? values.city : values.businessCity,
                    businessState: isSameAsBusinessAddress
                      ? values.state
                      : values.businessState,
                    businessZipCode: isSameAsBusinessAddress
                      ? values.zipCode
                      : values.businessZipCode,
                    mailingAddress1: isSameAsMailingAddress
                      ? values.address1
                      : values.mailingAddress1,
                    mailingAddress2: isSameAsMailingAddress
                      ? values.address2
                      : values.mailingAddress2,
                    mailingCity: isSameAsMailingAddress ? values.city : values.mailingCity,
                    mailingState: isSameAsMailingAddress ? values.state : values.mailingState,
                    mailingZipCode: isSameAsMailingAddress
                      ? values.zipCode
                      : values.mailingZipCode
                  });

                  practiceRegisteration({ ...newValues }, {
                    onSuccess: () => {

                      const { REACT_APP_PRACTICE_REG_PRODUCT_ID } = process.env;

                      if (REACT_APP_PRACTICE_REG_PRODUCT_ID) {
                        initiateOneTimePayment({
                          cancelUrl: urlLocation.pathname,
                          productId: REACT_APP_PRACTICE_REG_PRODUCT_ID,
                          successUrl:
                            "provider/checkout/business-office/success?session_id={CHECKOUT_SESSION_ID}&page=practiceReg"
                        }, {
                          onSuccess: (data) => {
                            if (data.url) {
                              localStorage.setItem("practiceReg", JSON.stringify(newValues));
                              window.location.href = data.url;
                            }
                          }
                        });

                      }
                    }
                  });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  values,
                  touched,
                  isValid,
                  isSubmitting,
                  handleSubmit
                }) => (
                  <Form>
                    <div className="flex flex-row items-center gap-1">
                      <span className="font-bold" data-testid='name'>Name</span>
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                      <div className="mt-4 w-full">
                        <TextField
                          name="title"
                          type="text"
                          placeholder="Title"
                          value={values?.title ? values.title : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.title && errors.title ? errors.title : ""
                          }
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <TextField
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.firstName && errors.firstName
                              ? errors.firstName
                              : ""
                          }
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <TextField
                          name="middleName"
                          type="text"
                          placeholder="Middle Name"
                          value={values?.middleName ? values.middleName : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.middleName && errors.middleName
                              ? errors.middleName
                              : ""
                          }
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <TextField
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.lastName && errors.lastName
                              ? errors.lastName
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-1 mt-5">
                      <span className="font-bold" data-testid='address'>Address</span>
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="">
                      <div className="mt-4 w-full">
                        <TextField
                          name="address1"
                          type="text"
                          placeholder="Address Line 1"
                          value={values.address1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.address1 && errors.address1
                              ? errors.address1
                              : ""
                          }
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <TextField
                          name="address2"
                          type="text"
                          placeholder="Address Line 2"
                          value={values?.address2 ? values.address2 : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.address2 && errors.address2
                              ? errors.address2
                              : ""
                          }
                        />
                      </div>
                      <div className="mt-5">
                        <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                          <TextField
                            name="city"
                            type="text"
                            placeholder="City"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.city && errors.city ? errors.city : ""
                            }
                          />
                          <div className="flex-1">
                            <select
                              className="w-fit py-3 px-4 flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
                              // placeholder="Select type"
                              name="state"
                              value={values.state}
                              onChange={handleChange}
                              data-testid='disabled-select-state'
                            >
                              <option value="" disabled>
                                Select state
                              </option>
                              {statesInTheUS.map((state, index) => {
                                return (
                                  <option key={index} value={state} id={state}>
                                    {state}
                                  </option>
                                );
                              })}
                            </select>
                            {touched.state && errors.state && (
                              <div className="text-[red] text-xs">
                                {errors.state}
                              </div>
                            )}
                          </div>
                          <TextField
                            name="zipCode"
                            type="text"
                            placeholder="Zip Code"
                            value={values.zipCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.zipCode && errors.zipCode
                                ? errors.zipCode
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-1 mt-5">
                      <span className="font-bold" data-testid='business-name'>Business Name</span>
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="mt-4 w-full">
                      <TextField
                        name="businessName"
                        type="text"
                        placeholder="Business Name"
                        value={values?.businessName ? values.businessName : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.businessName && errors.businessName
                            ? errors.businessName
                            : ""
                        }
                      />
                      <div className="italic text-[13px]" data-testid='business-name-helper'>
                        This is the name of the business you want to register
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-1 mt-5">
                      <span className="font-bold" data-testid='email'>Email</span>
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="mt-4 w-full">
                      <TextField
                        name="email"
                        type="text"
                        placeholder="Email"
                        value={values?.email ? values.email : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.email && errors.email ? errors.email : ""
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center gap-1 mt-5">
                      <span className="font-bold" data-testid='phone'>Phone</span>
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="mt-4 w-full">
                      <TextField
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        value={values?.phoneNumber ? values.phoneNumber : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.phoneNumber && errors.phoneNumber
                            ? errors.phoneNumber
                            : ""
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center gap-1 mt-5">
                      <span className="font-[500] text-[#000]" data-testid='business-address'>
                        Business address
                      </span>
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="flex flex-row items-center gap-1 mt-5 font-bold" data-testid='same-as-home-address-container'>
                      <CheckWithLabelToggle
                        checkboxId="same-as-home-address"
                        label="Same as home address"
                        isSelected={isSameAsBusinessAddress}
                        toggleSelection={() =>
                          setIsSameAsBusinessAddress(prev => !prev)
                        }
                      />
                    </div>

                    {!isSameAsBusinessAddress && (
                      <div className="">
                        <div className="mt-4 w-full">
                          <TextField
                            name="businessAddress1"
                            type="text"
                            placeholder="Address Line 1"
                            value={
                              values?.businessAddress1
                                ? values.businessAddress1
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.businessAddress1 &&
                              errors.businessAddress1
                                ? errors.businessAddress1
                                : ""
                            }
                          />
                        </div>
                        <div className="mt-4 w-full">
                          <TextField
                            name="businessAddress2"
                            type="text"
                            placeholder="Address Line 2"
                            value={
                              values?.businessAddress2
                                ? values.businessAddress2
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.businessAddress2 &&
                              errors.businessAddress2
                                ? errors.businessAddress2
                                : ""
                            }
                          />
                        </div>
                        <div className="mt-5">
                          <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                            <TextField
                              name="businessCity"
                              type="text"
                              placeholder="City"
                              value={
                                values?.businessCity ? values.businessCity : ""
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.businessCity && errors.businessCity
                                  ? errors.businessCity
                                  : ""
                              }
                            />
                            <div className="flex-1">
                              <select
                                className="w-fit py-3 px-4 flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
                                // placeholder="Select type"
                                name="businessState"
                                value={values.businessState}
                                onChange={handleChange}
                                data-testid='disabled-select-business-state'
                              >
                                <option value="" disabled>
                                  Select state
                                </option>
                                {statesInTheUS.map((state, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={state}
                                      id={state}
                                    >
                                      {state}
                                    </option>
                                  );
                                })}
                              </select>
                              {touched.businessState &&
                                errors.businessState && (
                                  <div className="text-[red] text-xs">
                                    {errors.businessState}
                                  </div>
                                )}
                            </div>
                            <TextField
                              name="businessZipCode"
                              type="text"
                              placeholder="Zip Code"
                              value={
                                values?.businessZipCode
                                  ? values.businessZipCode
                                  : ""
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.businessZipCode &&
                                errors.businessZipCode
                                  ? errors.businessZipCode
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="italic text-[13px] mt-5" data-testid='description'>
                      If your business is a telehealth, and you intend to see
                      patient from and still want to use keep your address
                      private, we recommend a virtual mailbox address
                    </div>
                    <div className="flex flex-row items-center gap-1 mt-5">
                      <span className="font-[500] text-[#000]" data-testid='mailing-address'>
                        Mailing address
                      </span>
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="flex flex-row items-center gap-1 mt-5 font-bold" data-testid='same-as-business-address-container'>
                      <CheckWithLabelToggle
                        checkboxId="same-as-business-address"
                        label="Same as business address"
                        isSelected={isSameAsMailingAddress}
                        toggleSelection={() =>
                          setIsSameAsMailingAddress(prev => !prev)
                        }
                      />
                    </div>
                    {!isSameAsMailingAddress && (
                      <div className="">
                        <div className="mt-4 w-full">
                          <TextField
                            name="mailingAddress1"
                            type="text"
                            placeholder="Address Line 1"
                            value={
                              values?.mailingAddress1
                                ? values.mailingAddress1
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.mailingAddress1 && errors.mailingAddress1
                                ? errors.mailingAddress1
                                : ""
                            }
                          />
                        </div>
                        <div className="mt-4 w-full">
                          <TextField
                            name="mailingAddress2"
                            type="text"
                            placeholder="Address Line 2"
                            value={
                              values?.mailingAddress2
                                ? values.mailingAddress2
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.mailingAddress2 && errors.mailingAddress2
                                ? errors.mailingAddress2
                                : ""
                            }
                          />
                        </div>
                        <div className="mt-5">
                          <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                            <TextField
                              name="mailingCity"
                              type="text"
                              placeholder="City"
                              value={
                                values?.mailingCity ? values.mailingCity : ""
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.mailingCity && errors.mailingCity
                                  ? errors.mailingCity
                                  : ""
                              }
                            />
                            <div className="flex-1">
                              <select
                                className="w-fit py-3 px-4 flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
                                // placeholder="Select type"
                                name="mailingState"
                                value={values.mailingState}
                                onChange={handleChange}
                                data-testid='disabled-select-mailing-state'
                              >
                                <option value="" disabled>
                                  Select state
                                </option>
                                {statesInTheUS.map((state, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={state}
                                      id={state}
                                    >
                                      {state}
                                    </option>
                                  );
                                })}
                              </select>
                              {touched.mailingState && errors.mailingState && (
                                <div className="text-[red] text-xs">
                                  {errors.mailingState}
                                </div>
                              )}
                            </div>
                            <TextField
                              name="mailingZipCode"
                              type="text"
                              placeholder="Zip Code"
                              value={
                                values?.mailingZipCode
                                  ? values.mailingZipCode
                                  : ""
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.mailingZipCode && errors.mailingZipCode
                                  ? errors.mailingZipCode
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flex flex-row items-center gap-1 mt-5">
                      <span className="font-bold" data-testid='business-description'>Description of Business</span>
                    </div>
                    <div className="mt-4 w-full flex items-center justify-between border border-[#949589] text-[#103C1B] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black">
                      <textarea
                        className="w-full appearance-none placeholder:text-[#6E8877] text-black bg-transparent border border-none focus:outline-none"
                        rows={5}
                        name="businessDescription"
                        placeholder="In a few words explain what your business is about"
                        value={
                          values?.businessDescription
                            ? values.businessDescription
                            : ""
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        data-testid='business-description-textarea-input'
                      ></textarea>
                    </div>
                    <div className="mt-5 w-full flex flex-row items-center justify-center" data-testid='submit-button-container'>
                      <Button
                        disabled={isSubmitting}
                        loading={isPracticeRegisterationPending}
                        label="Submit"
                        type="submit"
                        variant="primary"
                        size="large"
                        onClick={handleSubmit}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PracticeRegistration;
