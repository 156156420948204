import clsx from "clsx";
import React, { useState } from "react";
import "react-phone-number-input/style.css";
import { ReactComponent as EyeOpenGreyIcon } from "../../svgs/eye-open-grey.svg";
import { GreenCheckIcon } from "../../svgs/GreenCheckIcon";
import { ReactComponent as PasswordHiddenIcon } from "../../svgs/password-hidden-grey.svg";
import Spacer from "../Spacer";

interface FieldProps {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value: string;
  label: string;
  placeholder: string;
  name: string;
  size?: string;
  disabled?: boolean;
  error?: string;
  width?: number;
  isRequired?: boolean;
  showPasswordToggler?: boolean;
  inputValid?: boolean;
}

const PasswordField: React.FC<FieldProps> = ({
  onChange = () => {},
  onBlur = () => {},
  value,
  label,
  placeholder,
  size,
  name,
  disabled = false,
  error,
  width,
  isRequired,
  showPasswordToggler = true,
  inputValid
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const computedRootStyle = () => {
    const style: { [key: string]: number | string } = {};

    if (width) {
      style.width = `${width}px`;
    }

    return style;
  };

  return (
    <div
      className={clsx({
        "w-full flex flex-col": true,
        "lg:w-1/2": size === "half"
      })}
      style={computedRootStyle()}
    >
      <span className="flex">
        <label
          className="text-[#000] font-medium mb-2 text-[16px]"
          htmlFor={`grid-${name}`}
          data-testid='label-title'
        >
          {label}
        </label>
        {isRequired && (
          <>
            <Spacer width={3} />
            <p className="text-red-500 text-xs">*</p>
          </>
        )}
      </span>
      <div>
        <div className="flex items-center justify-between border border-gray-200 rounded py-[11px] px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-white hover:border-black">
          <input
          autoCorrect="off"
            className={clsx({
              "w-full appearance-none text-[#6E8877] border border-none focus:outline-none text-s":
                true,
              "w-[95%]": showPasswordToggler,
              "w-full": !showPasswordToggler
            })}
            type={showPassword ? "text" : "password"}
            placeholder={placeholder}
            value={value}
            name={name}
            autoComplete="current-password"
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
          {inputValid && (
            <GreenCheckIcon className="flex justify-center mr-4" />
          )}

          {showPasswordToggler && (
            <>
              {showPassword ? (
                <EyeOpenGreyIcon
                  stroke="#6E8877"
                  onClick={() => {
                    setShowPassword(false);
                  }}
                />
              ) : (
                <PasswordHiddenIcon
                  stroke="#6E8877"
                  onClick={() => {
                    setShowPassword(true);
                  }}
                />
              )}
            </>
          )}
        </div>
        {error && (
          <p className="mt-2 text-xs text-red-600 capitalize">{error}</p>
        )}
      </div>
    </div>
  );
};

export default PasswordField;
