import styled from "@emotion/styled";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getCurrentUser } from "../../../api/auth";
import { useMalpracticeRegister } from "../../../api/hooks/business_office/malPractticeRegister";
import { getS3SignedUrl } from "../../../api/hooks/common/fetchers.common";
import { GovtIdDoc } from "../../../components/auth/PersonalRegistration";
import RadioField from "../../../components/form/RadioField";
import TextField from "../../../components/form/TextField";
import { getInsurancePayers } from '../../../components/lib/utils/utils';
import Button from "../../../components/shared/Button";
import CheckWithLabelToggle from "../../../components/shared/CheckWithLabelToggle";
import { DeleteIcon } from "../../../svgs/DeleteIcon";
import { PlusIcon } from "../../../svgs/PlusIcon";
import {
  insuranceCoverageType,
  phoneRegExp,
  statesInTheUS
} from "../../../utils/consts";
import { generateUUID, removeEmptyObjectValues } from "../../../utils/helpers";
import FeedbackModal from "../../components/FeedbackModal";

export interface ClaimType {
  claimDate: string;
  amount: string;
  claimDescription: string;
  claimResolution: string;
}

const claimInputInitialState: ClaimType[] = [
  { amount: "", claimDate: "", claimDescription: "", claimResolution: "" },
  { amount: "", claimDate: "", claimDescription: "", claimResolution: "" }
];

interface FormValues {
  insuranceProvider: string;
  fullName: string;
  npi: string;
  policyNumber: string;
  fax: string | undefined;
  specialty: string;
  practiceName: string;
  licenseNumber: string;
  effectiveDate: string;
  expirationDate: string;
  coverageType: string;
  coverageLimit: string;
  deductibleAmount: string;
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zipCode: string | undefined;
  comments: string | undefined;
  phoneNumber: string;
  insuranceResponse: string | undefined;
  currentStatusOfClaim: string | undefined;
  hasHadMalpracticeLawSuit: string;
}

const MalpracticeInsurance = () => {
  const [wantsToRegisterMalpractice, setWantsToRegisterMalpractice] =
    useState(false);
  const [isSameAsBusinessAddress, setIsSameAsBusinessAddress] = useState(false);
  const [isCertified, setIsCertified] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [cvKey, setCvKey] = useState<any>("");
  const [cvId, setCvId] = useState({} as GovtIdDoc);
  const [medicalLicenseId, setMedicalLicenseId] = useState({} as GovtIdDoc);
  const [medicalLicenseKey, setMedicalLicenseKey] = useState<any>("");
  const [bizEntityProofId, setBizEntityProofId] = useState({} as GovtIdDoc);
  const [bizEntityProofKey, setBizEntityProofKey] = useState<any>("");
  const [showModal, setShowModal] = useState(false);
  const [claimFormInputs, setClaimFormInputs] = useState<ClaimType[]>(
    claimInputInitialState
  );
  const savedPayers = localStorage.getItem("insurancePayers");
  const insuranceCompaniesFromLocalStorage = getInsurancePayers()
  const [insuranceName, setInsuranceName] = useState<{
    payer_id: string;
    payer_name: string;
  }>({ payer_id: "", payer_name: "" });
  const [insuranceData, setInsuranceData] = useState<Array<any>>([]);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

  const handleSearchInsuranceName = useCallback((val: string) => {
    const filteredData = insuranceCompaniesFromLocalStorage?.filter(
      (e: any, i: number) =>
        e.payer_name.toLowerCase().includes(val.toLowerCase())
    );
    if (filteredData.length) {
      setInsuranceData(filteredData);
    }
  }, [insuranceCompaniesFromLocalStorage]);

  const { malpracticeRegister, isPending, } = useMalpracticeRegister()


  const initialValues: FormValues = {
    hasHadMalpracticeLawSuit: "no",
    currentStatusOfClaim: "",
    fax: "",
    fullName: getCurrentUser()?.firstName
      ? `${getCurrentUser()?.lastName} ${getCurrentUser()?.firstName}`
      : "",
    practiceName: getCurrentUser()?.practiceName || "",
    effectiveDate: "",
    expirationDate: "",
    insuranceProvider: getCurrentUser()?.insuranceProviders
      ? getCurrentUser()?.insuranceProviders[0]
      : "",
    policyNumber: "",
    address1: getCurrentUser()?.address || "",
    address2: "",
    city: getCurrentUser()?.city || "",
    state: getCurrentUser()?.state || "",
    zipCode: getCurrentUser()?.zipCode || "",
    phoneNumber: getCurrentUser()?.phoneNumber || "",
    comments: "",
    npi: getCurrentUser()?.practiceNPI || "",
    licenseNumber: getCurrentUser()?.licenseNumbers
      ? getCurrentUser()?.licenseNumbers[0]?.number
      : "",
    coverageLimit: "",
    coverageType: "",
    deductibleAmount: "",
    insuranceResponse: "",
    specialty: getCurrentUser()?.specialty || ""
  };

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
    fax: Yup.string(),
    currentStatusOfClaim: Yup.string(),
    hasHadMalpracticeLawSuit: Yup.string().required(
      "Malpractice law suit is required"
    ),
    fullName: Yup.string().required("Full name is required"),
    practiceName: Yup.string().required("Practice name is required"),
    npi: Yup.string().required("NPI is required"),
    specialty: Yup.string().required("Provider type is required"),
    licenseNumber: Yup.string().required("License number is required"),
    comments: Yup.string(),
    effectiveDate: Yup.string().required("Effective date is required"),
    expirationDate: Yup.string().required("Policy number is required"),
    insuranceProvider: Yup.string().required("Insurance provider is required"),
    policyNumber: Yup.string().required("Policy number is required"),
    coverageLimit: Yup.string().required("Coverage limit is required"),
    coverageType: Yup.string().required("Coverage type is required"),
    deductibleAmount: Yup.string().required("Deductible amount is required"),
    address1: Yup.string().required("Address line 1 is required"),
    address2: Yup.string(),
    insuranceResponse: Yup.string(),
    claims: Yup.array(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Zip code is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .test("is-valid-us-phone-number", "Invalid US phone number", value => {
        return phoneRegExp.test(value as string);
      })
  });

  useEffect(() => {
    if (insuranceName.payer_name.length > 2) {
      handleSearchInsuranceName(insuranceName.payer_name);
    }
  }, [insuranceName.payer_name, handleSearchInsuranceName]);

  const onSubmit = async (values: FormValues, { resetForm }: any) => {
    const newValues = removeEmptyObjectValues({
      cvKey,
      bizEntityProofKey,
      medicalLicenseKey,
      claims: claimFormInputs,
      hasHadMalpracticeLawSuit:
        values.hasHadMalpracticeLawSuit === "yes" ? true : false,
      currentStatusOfClaim: values.currentStatusOfClaim,
      fax: values.fax,
      effectiveDate: values.effectiveDate,
      expirationDate: values.expirationDate,
      insuranceProvider: values.insuranceProvider,
      policyNumber: values.policyNumber,
      comments: values.comments,
      coverageLimit: values.coverageType,
      coverageType: values.coverageType,
      deductibleAmount: values.deductibleAmount,
      insuranceResponse: values.insuranceResponse
    });

    if (!cvKey || !bizEntityProofKey || !medicalLicenseKey) {
      toast.error("please upload all the necessary documents");
    } else {
      malpracticeRegister({
        cvKey,
        bizEntityProofKey,
        medicalLicenseKey,
        claims: claimFormInputs,
        hasHadMalpracticeLawSuit: newValues.hasHadMalpracticeLawSuit === "yes" ? true : false,
        currentStatusOfClaim: newValues?.currentStatusOfClaim,
        fax: newValues?.fax,
        effectiveDate: newValues.effectiveDate,
        expirationDate: newValues.expirationDate,
        insuranceProvider: newValues.insuranceProvider,
        policyNumber: newValues.policyNumber,
        comments: newValues.comments,
        coverageLimit: newValues.coverageLimit,
        coverageType: newValues.coverageType,
        deductibleAmount: newValues.deductibleAmount,
        insuranceResponse: newValues.insuranceResponse
      })
      resetForm();
      setShowModal(true);
      setIsSuccessful(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    touched,
    isValid,
    setFieldValue,
    isSubmitting
  } = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit
  });

  const handleFileUpload = async (e: any, setId: any, setKey: any) => {
    const file = e.target.files?.[0];

    if (!file) {
      toast.info("No file selected");
      return;
    }

    const maxAllowedSize = 2 * 1024 * 1024;
    if (file.size > maxAllowedSize) {
      toast.error("File size should be less than 2MB");
      return;
    } else {
      setId({ file });
      const key = generateUUID() + file.name.slice(file.name.lastIndexOf("."));
      (async () => {
        const response = await getS3SignedUrl({
          operation: "putObject",
          key,
          object: "account"
        });
        setKey(`${response.signedUrlParams.host}/${key}`);
      })();
    }
  };

  const handleDeleteFile = (setId: any, setKey: any) => {
    setId({});
    setKey("");
  };

  return (
    <>
      <div className="mb-5 px-5 md:px-10 lg:px-20">
        <h2 className="text-[18px]" data-testid='insurance-title'>Do you want to register for a malpractice insurance?</h2>
        <RadioField
          name="seeRegistrationForm"
          placeholder="Register"
          display="block"
          options={[
            { item: "Yes", value: true },
            { item: "No", value: false }
          ]}
          value={wantsToRegisterMalpractice}
          onChange={e => {
            if (e.target.value === "true") {
              setWantsToRegisterMalpractice(true);
            } else {
              setWantsToRegisterMalpractice(false);
            }
          }}
        />
      </div>
      {wantsToRegisterMalpractice && (
        <div className="h-full w-full mb-10 overflow-y-auto sm:ml-10 mt-10">
          <h1 className="text-center font-[600] text-[28px] text-neutral-900" data-testid='insurance-form'>
            Malpractice Insurance Form
          </h1>
          <div className="w-full text-center flex flex-row items-center justify-center mt-4">
            <p className="text-center text-[14px] font-[500] text-neutral-900 w-[50%]" data-testid='insurance-form-description'>
              Secure your practice with comprehensive malpractice insurance
              coverage. Complete the Malpractice Insurance form to provide
              details about your insurance carrier and other necessary
              information.
            </p>
          </div>
          <form
            className="mt-6 w-full flex items-center justify-center flex-wrap"
            onSubmit={e => handleSubmit(e)}
          >
            <div className="mt-6 w-full md:w-[80%]">
              <div className="text-primary-main text-[24px] font-[500] my-4" data-testid='insurance-form-section'>
                Provider Information
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                <div className="mt-4 flex-1">
                  <TextField
                    name="fullName"
                    type="text"
                    label="Full Name"
                    placeholder="Enter Full Name"
                    value={values?.fullName ? values.fullName : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.fullName && errors.fullName ? errors.fullName : ""
                    }
                  />
                </div>
                <div className="mt-4 flex-1">
                  <TextField
                    name="npi"
                    type="text"
                    label="NPI (National Provider Identifier)"
                    placeholder="Enter NPI"
                    value={values.npi}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.npi && errors.npi ? errors.npi : ""}
                  />
                </div>
                <div className="mt-4 flex-1">
                  <TextField
                    name="licenseNumber"
                    type="text"
                    label="License Number"
                    placeholder="Enter License Number"
                    value={values.licenseNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.licenseNumber && errors.licenseNumber
                        ? errors.licenseNumber
                        : ""
                    }
                  />
                </div>
                <div className="flex-1 mt-4">
                  <label
                    className="text-[#000] font-medium mb-8 text-[16px]"
                    htmlFor={`providerType`}
                    data-testid='specialty'
                  >
                    Specialty
                  </label>
                  <select
                    className="w-[100%] py-3 px-4 flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
                    name="specialty"
                    id="specialty"
                    value={values.specialty}
                    onChange={handleChange}
                    data-testid='select-specialty-disabled'
                  >
                    <option value="" disabled>
                      Select Specialty
                    </option>
                    {[
                      "Primary Care Physician",
                      "Therapist",
                      "Psychiatrist"
                    ].map((state, index) => {
                      return (
                        <option key={index} value={state} id={state}>
                          {state}
                        </option>
                      );
                    })}
                  </select>
                  {touched.specialty && errors.specialty && (
                    <div className="text-[red] text-xs">{errors.specialty}</div>
                  )}
                </div>
              </div>

              <div className="flex flex-row items-center gap-1 mt-5">
                <div className="text-primary-main text-[24px] font-[500] my-4" data-testid='practice-info'>
                  Practice Information
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                <div className="flex-1">
                  <TextField
                    name="practiceName"
                    type="text"
                    label="Practice Name"
                    placeholder="Practice Name"
                    value={values?.practiceName ? values.practiceName : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.practiceName && errors.practiceName
                        ? errors.practiceName
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <span className="font-[500] text-[#000]" data-testid='practice-address'>Practice address</span>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5 font-bold" data-testid='same-as-home-container'>
                <CheckWithLabelToggle
                  label="Same as home address"
                  isSelected={isSameAsBusinessAddress}
                  toggleSelection={() =>
                    setIsSameAsBusinessAddress(prev => !prev)
                  }
                />
              </div>
              {!isSameAsBusinessAddress && (
                <>
                  {" "}
                  <div className="">
                    <div className="mt-4 w-full">
                      <TextField
                        name="address1"
                        type="text"
                        label="Address Line 1"
                        placeholder="Address Line 1"
                        value={values?.address1 ? values.address1 : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.address1 && errors.address1
                            ? errors.address1
                            : ""
                        }
                      />
                    </div>
                    <div className="mt-4 w-full">
                      <TextField
                        name="address2"
                        type="text"
                        label="Address Line 2"
                        placeholder="Address Line 2"
                        value={values?.address2 ? values.address2 : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.address2 && errors.address2
                            ? errors.address2
                            : ""
                        }
                      />
                    </div>
                    <div className="mt-5">
                      <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                        <TextField
                          name="city"
                          type="text"
                          label="City"
                          placeholder="City"
                          value={values?.city ? values.city : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.city && errors.city ? errors.city : ""}
                        />

                        <div className="flex-1">
                          <label
                            className="text-[#000] font-medium my-2 text-[16px]"
                            htmlFor={`stateOfLicense`}
                          >
                            State
                          </label>
                          <select
                            className="w-fit py-3 px-4 flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
                            name="state"
                            id="state"
                            value={values.state ? values.state : ""}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select state
                            </option>
                            {statesInTheUS.map((state, index) => {
                              return (
                                <option key={index} value={state} id={state}>
                                  {state}
                                </option>
                              );
                            })}
                          </select>
                          {touched.state && errors.state && (
                            <div className="text-[red] text-xs">
                              {errors.state}
                            </div>
                          )}
                        </div>
                        <TextField
                          name="zipCode"
                          type="text"
                          label="Zip Code"
                          placeholder="Zip Code"
                          value={values?.zipCode ? values.zipCode : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.zipCode && errors.zipCode
                              ? errors.zipCode
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="mt-3">
                <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                  <div className="flex-1">
                    <TextField
                      name="phoneNumber"
                      type="text"
                      label="Phone Number"
                      placeholder="Phone Number"
                      value={values?.phoneNumber ? values.phoneNumber : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.phoneNumber && errors.phoneNumber
                          ? errors.phoneNumber
                          : ""
                      }
                    />
                  </div>
                  <div className="flex-1">
                    <TextField
                      name="fax"
                      type="text"
                      label="Fax"
                      placeholder="Fax"
                      value={values?.fax ? values.fax : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.fax && errors.fax ? errors.fax : ""}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <div className="text-primary-main text-[24px] font-[500] my-4" data-testid='insurance-details'>
                  Insurance Details
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                <div className="mt-4 flex-1">
                  <div>
                    <div className="">
                      <TextField
                        name="insuranceProvider"
                        type="text"
                        label="Insurance Provider"
                        placeholder="Enter the insurance name here "
                        value={values.insuranceProvider || ""}
                        onChange={
                          handleChange
                          //   e => {
                          //   setIsSelected(false);
                          //   setInsuranceData([]);
                          //   setInsuranceName({
                          //     ...insuranceName,
                          //     payer_name: e.target.value
                          //   });
                          // }
                        }
                        error={
                          touched.insuranceProvider
                            ? errors.insuranceProvider
                            : ""
                        }
                      />
                    </div>
                    {/* {touched.insuranceName && errors.insuranceName && (
                          <div className="text-[red] text-xs">
                            {errors.insuranceName}
                          </div>
                        )} */}
                    <div className="flex flex-col gap-2">
                      {!isSelected &&
                        insuranceData.map((item, index) => (
                          <div
                            key={index}
                            className="cursor-pointer"
                            onClick={() => {
                              setFieldValue(
                                "insuranceProvider",
                                item.payer_name
                              );
                              setInsuranceName({
                                payer_name: item.payer_name,
                                payer_id: item.payerid
                              });
                              setIsSelected(true);
                            }}
                          >
                            {item.payer_name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex-1">
                  <TextField
                    name="policyNumber"
                    type="text"
                    label="Insurance Policy Number"
                    placeholder="Enter Insurance Policy Number"
                    value={values.policyNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.policyNumber && errors.policyNumber
                        ? errors.policyNumber
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                  <div className="flex-1">
                    <TextField
                      name="effectiveDate"
                      type="date"
                      label="Effective Date"
                      placeholder="MM/DD/YYYY"
                      value={values.effectiveDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.effectiveDate && errors.effectiveDate
                          ? errors.effectiveDate
                          : ""
                      }
                    />
                  </div>
                  <div className="flex-1">
                    <TextField
                      name="expirationDate"
                      type="date"
                      label="Expiration Date"
                      placeholder="MM/DD/YYYY"
                      value={values.expirationDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.expirationDate && errors.expirationDate
                          ? errors.expirationDate
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <div className="text-primary-main text-[24px] font-[500] my-4" data-testid='coverage-details'>
                  Coverage Details
                </div>
              </div>
              <div className="my-4 flex flex-col sm:flex-row items-start justify-between gap-2">
                <div className="flex-1">
                  <label
                    className="text-[#000] font-medium mb-8 text-[16px]"
                    htmlFor={`coverageType`}
                    data-testid='coverage-type'
                  >
                    Coverage Type
                  </label>
                  <select
                    className="w-[100%] py-3 px-4 flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
                    name="coverageType"
                    id="coverageType"
                    value={values.coverageType}
                    onChange={handleChange}
                    data-testid='coverage-type-select-disabled'
                  >
                    <option value="" disabled>
                      Select Coverage Type
                    </option>
                    {insuranceCoverageType.map((state, index) => {
                      return (
                        <option key={index} value={state} id={state}>
                          {state}
                        </option>
                      );
                    })}
                  </select>
                  {touched.coverageType && errors.coverageType && (
                    <div className="text-[red] text-xs">
                      {errors.coverageType}
                    </div>
                  )}
                </div>
                <div className="flex-1">
                  <TextField
                    name="coverageLimit"
                    type="text"
                    label="Coverage Limit"
                    placeholder="Enter Coverage Limit"
                    value={values?.coverageLimit ? values.coverageLimit : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.coverageLimit && errors.coverageLimit
                        ? errors.coverageLimit
                        : ""
                    }
                  />
                </div>
                <div className="flex-1">
                  <TextField
                    name="deductibleAmount"
                    type="text"
                    label="Deductible Amount"
                    placeholder="Enter Deductible Amount"
                    value={
                      values?.deductibleAmount ? values.deductibleAmount : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.deductibleAmount && errors.deductibleAmount
                        ? errors.deductibleAmount
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <div className="text-primary-main text-[24px] font-[500] my-4" data-testid='claims-history'>
                  Claims History
                </div>
              </div>
              <div className="flex- mt-4">
                <div className="my-2">
                  <div className="text-[18px] font-[500] text-left" data-testid='has-had-malpractice-law-suit'>
                    Have you ever been part of a malpractice claim or lawsuit
                  </div>
                  <RadioField
                    name="hasHadMalpracticeLawSuit"
                    placeholder="Malpractice claim or lawsuit"
                    display="inline"
                    options={[
                      { item: "Yes", value: "yes" },
                      { item: "No", value: "no" }
                    ]}
                    isRequired={true}
                    value={values.hasHadMalpracticeLawSuit}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {values.hasHadMalpracticeLawSuit === "yes" && claimFormInputs && (
                <>
                  {claimFormInputs?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="flex flex-col gap-3 my-5">
                          <div className="text-primary-main text-center py-3 bg-[#D8F7E0] text-[16px] font-[600] w-full" data-testid={`claims ${index + 1}`}>
                            Claim {index + 1}
                          </div>

                          <div className="flex flex-col sm:flex-row my-3 items-start justify-between gap-2">
                            <div className="flex-1">
                              <TextField
                                name="claim"
                                type="date"
                                label="Claim Date"
                                placeholder="MM/DD/YYYY"
                                value={item?.claimDate}
                                onChange={async e => {
                                  const newArr: ClaimType[] = [
                                    ...claimFormInputs
                                  ];
                                  newArr[index].claimDate = e.target.value;

                                  setClaimFormInputs(newArr);
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <TextField
                                name="claim"
                                type="text"
                                label="Amount Paid/Settled"
                                placeholder="Enter amount"
                                value={item?.amount}
                                onChange={async e => {
                                  const newArr: ClaimType[] = [
                                    ...claimFormInputs
                                  ];
                                  newArr[index].amount = e.target.value;

                                  setClaimFormInputs(newArr);
                                }}
                              />
                            </div>
                          </div>
                          <div className="">
                            <label
                              htmlFor="claim_desc_1"
                              className="text-primary-main text-[16px] font-[500] my-2"
                              data-testid='description-of-claim'
                            >
                              Description of Claim
                            </label>
                            <div className="mt-4 w-full flex items-center justify-between border border-[#949589] text-[#103C1B] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black">
                              <textarea
                                className="w-full appearance-none placeholder:text-[#6E8877] text-black bg-transparent border border-none focus:outline-none"
                                rows={5}
                                id="claim_desc_1"
                                name="claim"
                                placeholder="On [Claim Date], a patient under my care experienced unexpected complications following a surgical procedure. The complications resulted in [specific consequences]. The patient's family filed a malpractice claim alleging [specific allegations], leading to a legal dispute."
                                value={item?.claimDescription}
                                onChange={async e => {
                                  const newArr: ClaimType[] = [
                                    ...claimFormInputs
                                  ];
                                  newArr[index].claimDescription =
                                    e.target.value;

                                  setClaimFormInputs(newArr);
                                }}
                                data-testid='description-of-claim-textarea1'
                              ></textarea>
                            </div>
                          </div>
                          <div className="my-3">
                            <label
                              htmlFor="claim_res_1"
                              className="text-primary-main text-[16px] font-[500] my-2"
                              data-testid='claim-resolution'
                            >
                              Resolution Outcome
                            </label>
                            <div className="mt-4 w-full flex items-center justify-between border border-[#949589] text-[#103C1B] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black">
                              <textarea
                                className="w-full appearance-none placeholder:text-[#6E8877] text-black bg-transparent border border-none focus:outline-none"
                                rows={5}
                                id="claim_res_1"
                                name="claim"
                                placeholder="The legal dispute was resolved through [arbitration/mediation/court proceedings]. After a thorough examination of the case, it was determined that [provide details on the resolution, whether it was settled, dismissed, or went to trial]. The final resolution included [details on any monetary settlement, if applicable]."
                                value={item?.claimResolution}
                                onChange={async e => {
                                  const newArr: ClaimType[] = [
                                    ...claimFormInputs
                                  ];
                                  newArr[index].claimResolution =
                                    e.target.value;

                                  setClaimFormInputs(newArr);
                                }}
                                data-testid='claims-outcome-textarea'
                              ></textarea>
                            </div>
                          </div>
                          <div className="">
                            <button
                              type="button"
                              className="cursor-pointer flex items-center gap-2"
                              onClick={() => {
                                const newInputs = [...claimFormInputs];
                                newInputs.splice(index, 1);
                                setClaimFormInputs(newInputs);
                              }}
                              data-testid='delete-claim-button'
                            >
                              <DeleteIcon color="grey" />
                              <span className="text-primary-main text-[16px] font-[500]">
                                Delete Entry
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="my-3">
                    <button
                      type="button"
                      className="text-[#1A9D39] text-[16px] cursor-pointer flex items-center gap-2"
                      onClick={() => {
                        setClaimFormInputs([
                          ...claimFormInputs,
                          {
                            amount: "",
                            claimDate: "",
                            claimDescription: "",
                            claimResolution: ""
                          }
                        ]);
                      }}
                      data-testid='add-claim-button'
                    >
                      <PlusIcon color="#1A9D39" />{" "}
                      <span className="font-[500]">Add another claim</span>
                    </button>
                  </div>
                  <div className="flex flex-row items-center gap-1 my-3">
                    <div className="text-primary-main text-[16px] font-[500] my-4" data-testid='claims-survey'>
                      How did the insurance company respond to all claims
                    </div>
                  </div>
                  <div className="mt-4 w-full flex items-center justify-between border border-[#949589] text-[#103C1B] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black">
                    <textarea
                      className="w-full appearance-none placeholder:text-[#6E8877] text-black bg-transparent border border-none focus:outline-none"
                      rows={5}
                      name="insuranceResponse"
                      placeholder="Please provide a detailed summary of how the insurance company responded to all claims. Include any relevant information about resolutions, settlements, or other outcomes. Be specific and thorough in your response.
                "
                      value={
                        values?.insuranceResponse
                          ? values.insuranceResponse
                          : ""
                      }
                      onChange={handleChange}
                      data-testid='insurance-response-input'
                    ></textarea>
                  </div>
                  <div className="flex flex-row items-center gap-1 my-3">
                    <div className="text-primary-main text-[16px] font-[500] my-4" data-testid='current-status'>
                      Current status of Claims
                    </div>
                  </div>
                  <div className="mt-4 w-full flex items-center justify-between border border-[#949589] text-[#103C1B] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black">
                    <textarea
                      className="w-full appearance-none placeholder:text-[#6E8877] text-black bg-transparent border border-none focus:outline-none"
                      rows={5}
                      name="currentStatusOfClaim"
                      placeholder="Please indicate the current status of your malpractice claims. Provide details on whether the claims are ongoing, resolved, or in any other relevant status. If applicable, include any recent developments or updates.
                "
                      value={
                        values?.currentStatusOfClaim
                          ? values.currentStatusOfClaim
                          : ""
                      }
                      onChange={handleChange}
                      data-testid='current-status-input-textarea'
                    ></textarea>
                  </div>
                </>
              )}
              <div className="my-2 w-full">
                <hr />
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <div className="text-primary-main text-[24px] font-[500] my-4" data-testid='documents-required'>
                  Document Upload
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-3">
                <div className="flex-1">
                  <div className="flex flex-row items-start justify-between">
                    <div className="text-[#000] font-medium mb-2 text-[16px]" data-testid='upload-cv'>
                      Upload Curriculum Vitae (CV)
                    </div>
                  </div>
                  <UploadContainer className="govt_id_file_cont">
                    <input
                      id="cv_id_file_upload"
                      name="cvKey"
                      onBlur={handleBlur}
                      className="invisible_file_upload"
                      onChange={e => handleFileUpload(e, setCvId, setCvKey)}
                      type="file"
                      accept=".pdf, image/*"
                    />
                    <div
                      className="file_upload_cont"
                      onClick={() =>
                        document.getElementById("cv_id_file_upload")?.click()
                      }
                    >
                      <div className="trigger-text-cont">
                        <span className="text-[#103C1B] text-[16px]" data-testid='upload-cv-text'>
                          Click to upload file (max-file-size: 2MB.)
                        </span>
                      </div>
                    </div>
                    {cvId?.file && (
                      <div className="id-name-cont space-x-2">
                        <span>{cvId?.file?.name}</span>
                        <span
                          className="remove-edoc"
                          onClick={() =>
                            handleDeleteFile(setCvId, setCvKey)
                          }
                          data-testid='delete-cv'
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </UploadContainer>
                </div>
                <div className="flex-1">
                  <div className="flex flex-row items-start justify-between">
                    <div className="text-[#000] font-medium mb-2 text-[16px]" data-testid='upload-medical-license'>
                      Upload State Medical License
                    </div>
                  </div>
                  <UploadContainer className="govt_id_file_cont">
                    <input
                      id="state_id_file_upload"
                      className="invisible_file_upload"
                      name="medicalLicenseKey"
                      onChange={e =>
                        handleFileUpload(
                          e,
                          setMedicalLicenseId,
                          setMedicalLicenseKey
                        )
                      }
                      type="file"
                      accept=".pdf, image/*"
                      data-testid='medical-license-input'
                    />
                    <div
                      className="file_upload_cont"
                      onClick={() =>
                        document.getElementById("state_id_file_upload")?.click()
                      }
                    >
                      <div className="trigger-text-cont">
                        <span className="text-[#103C1B] text-[16px]" data-testid='medical-license-text-info'>
                          Click to upload file (max-file-size: 2MB.)
                        </span>
                      </div>
                    </div>
                    {medicalLicenseId?.file && (
                      <div className="id-name-cont space-x-2">
                        <span>{medicalLicenseId?.file?.name}</span>
                        <span
                          className="remove-edoc"
                          onClick={() =>
                            handleDeleteFile(
                              setMedicalLicenseId,
                              setMedicalLicenseKey
                            )
                          }
                          data-testid='delete-docs'
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </UploadContainer>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-3 my-5">
                <div className="flex-1">
                  <div className="flex flex-row items-start justify-between">
                    <div className="text-[#000] font-medium mb-2 text-[16px]" data-testid='upload-proof-of-business-entity'>
                      Proof of Business Entity
                    </div>
                  </div>
                  <UploadContainer className="govt_id_file_cont">
                    <input
                      id="proof_id_file_upload"
                      name="bizEntityProof"
                      onBlur={handleBlur}
                      className="invisible_file_upload"
                      onChange={e =>
                        handleFileUpload(
                          e,
                          setBizEntityProofId,
                          setBizEntityProofKey
                        )
                      }
                      type="file"
                      accept=".pdf, image/*"
                      data-testid='proof-of-business-entity-input'
                    />
                    <div
                      className="file_upload_cont"
                      onClick={() =>
                        document.getElementById("proof_id_file_upload")?.click()
                      }
                    >
                      <div className="trigger-text-cont">
                        <span className="text-[#103C1B] text-[16px]" data-testid='proof-of-business-entity-text-info'>
                          Click to upload file (max-file-size: 2MB.)
                        </span>
                      </div>
                    </div>
                    {bizEntityProofId?.file && (
                      <div className="id-name-cont space-x-2">
                        <span>{bizEntityProofId?.file?.name}</span>
                        <span
                          className="remove-edoc"
                          onClick={() =>
                            handleDeleteFile(
                              setBizEntityProofId,
                              setBizEntityProofKey
                            )
                          }
                          data-testid='delete-proof-of-business-entity'
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </UploadContainer>
                </div>
                <div className="flex-1"></div>
              </div>
              <div className="flex flex-row items-center gap-1 my-4 font-bold" data-testid='i-certify'>
                <CheckWithLabelToggle
                  label="I certify that the information provided is accurate and complete"
                  isSelected={isCertified}
                  toggleSelection={() => {
                    setIsCertified(prev => !prev);
                  }}
                />
              </div>
              <div className="flex flex-row items-center gap-1 my-5">
                <div className="text-primary-main text-[24px] font-[500] my-4" data-testid='additional-comments'>
                  Additional Comments
                </div>
              </div>
              <div className="mt-4 w-full flex items-center justify-between border border-[#949589] text-[#103C1B] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black">
                <textarea
                  className="w-full appearance-none placeholder:text-[#6E8877] text-black bg-transparent border border-none focus:outline-none"
                  rows={5}
                  name="comments"
                  placeholder="Additional comment"
                  value={values?.comments ? values.comments : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  data-testid='additional-comments-input'
                />
              </div>
              <div className="mt-5 w-full flex flex-row items-center justify-center" data-testid='submit-application-button-container'>
                <Button
                  // disabled={!isValid || isSubmitting || !isCertified || isPending}
                  disabled={isSubmitting || isPending || !isValid}
                  label="Submit Application"
                  loading={isPending}
                  type="submit"
                  variant="primary"
                  size="large"
                />
              </div>
            </div>
          </form>
        </div>
      )}
      <FeedbackModal
        closeModal={handleCloseModal}
        isForSuccess={isSuccessful}
        isVisible={showModal}
        message={
          isSuccessful
            ? "Great news! Your Malpractice Insurance form has been received. We're now processing your application for comprehensive coverage."
            : "An error occurred, please try again"
        }
        title={
          isSuccessful
            ? "Malpractice Insurance Form Submitted!"
            : "Could not submit your request"
        }
      />
    </>
  );
};

const UploadContainer = styled.div`
  marginTop: ".9rem",
  "& .invisible_file_upload": {
    opacity: 0,
    position: "absolute",
    left: "-100%"
  },
  "& .file_upload_cont": {
    border: " 1px dashed #34623F",
    borderColor: "#103C1B",
    cursor: "pointer",
    background: "rgba(52, 98, 63, 0.07)",
    borderRadius: "4px",
    padding: "2rem",
    maxWidth: "300px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ".4rem",

    "& .trigger-text-cont": {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  "& .id-name-cont": {
    marginTop: ".4rem",
    fontSize: ".76rem",
    position: "relative",
    width: "70%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    "& .remove-edoc": {
      cursor: "pointer",
      color: "#F83535"
    }
  }

`;

export default MalpracticeInsurance;
