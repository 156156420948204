import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";
import { Gender, LicenseNumber, Specialty } from "../../types";


type Payload = {
  avatarUrl?: string | null;
  pronoun?: string | null;
  title?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  address?: string;
  licenseNumbers?: LicenseNumber[];
  deaState?: string;
  deaNumber?: string;
  focusAreas?: string[];
  specialty?: Specialty;
  gender?: Gender
  bio?: string;
  languages?: string[] | string;
  religion?: string;
  sexualOrientation?: string;
  city?: string;
  state?: string;
  providerNPI?: string;
  practiceNPI?: string | null;
  zipCode?: string;
  acceptsAdults?: boolean;
  acceptsVirtualAppointments?: boolean;
  acceptsPhysicalAppointments?: boolean;
  acceptsGeriatrics?: boolean;
  acceptsMinors?: boolean;
  insuranceProviders?: any | null;
  isActivated?: boolean;
  paymentType?: string;
  accountHoldersName?: string;
  initialAppointmentFee?: number | null;
  followUpAppointmentFee?: number | null;
  accountNumber?: string;
  routingNumber?: string;
  emailAddress?: string;
  acceptsCash?: boolean | null;
  acceptsInsurance?: boolean | null;
  canReceiveTextNotifications?: boolean;
  canReceiveDesktopNotifications?: boolean;
  canReceiveEmailNotifications?: boolean;
  notificationDelay?: number;
  hasFilledPersonalDetails?: boolean;
  hideIntroModal?: boolean;
  subscriptionPlan?: 'basic' | 'standard' | 'pro' | 'elite';
};

export const useUpdateProviderDetails = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['update provider details'],
    mutationFn: async ({ ...data }: Payload) => {

      const response = await http.patch("providers/details", data)
      return response.data;

    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },
    onSuccess: (_data, _variables, context) => {
      const { toastId } = context;
      toast.update(toastId, {
        render: "Provider details updated successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      })
      queryClient.invalidateQueries({ queryKey: ['provider profile details'] });
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number

      if (axios.isAxiosError(error)) {
        toast.update(toastId, {
          render: error?.response?.data?.error || "Error updating provider details",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        })

      } else {
        toast.update(toastId, {
          render: error?.message || "Error updating provider details",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

      }
    }
  });

  return {
    updateProviderProfileDetails: mutate,
    ...rest,
  };
}
