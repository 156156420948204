import { isAxiosError } from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useUpdateProviderDetails } from "../../../api/hooks/provider/updateProviderDetails";
import { useGetProviderProfileDetails } from "../../../api/hooks/provider/useGetProviderProfileDetails";
import Button from "../../../components/shared/Button";
import CheckWithLabelToggle from "../../../components/shared/CheckWithLabelToggle";
import { Loading } from "../../../components/shared/Loading";
import ToggleSwitch from "../../../components/shared/ToggleSwitch";

const Patient = () => {

  const { updateProviderProfileDetails, isPending } = useUpdateProviderDetails();
  const { providerData, isLoading, isError, error } = useGetProviderProfileDetails();
  const { user } = providerData ?? {}

  const patientFormik = useFormik({
    initialValues: {
      acceptsAdults: user?.acceptsAdults,
      acceptsVirtualAppointments: user?.acceptsVirtualAppointments || false,
      acceptsPhysicalAppointments: user?.acceptsPhysicalAppointments || false,
      acceptsGeriatrics: user?.acceptsGeriatrics || false,
      acceptsMinors: user?.acceptsMinors || false,
      insuranceProviders: user?.insuranceProviders,
      isActivated: user?.isActivated || false
    },
    enableReinitialize: true,
    validationSchema: Yup.object({}),
    onSubmit: () => { }
  });

  if (isLoading) {
    return <div className="h-1/2 w-1/2 flex justify-center items-center">
      <Loading />
    </div>
  }


  if (isError) {
    if (isAxiosError(error)) {
      const message = error?.response?.data?.error;
      toast.error(message, { toastId: "customId" });

    } else {
      toast.error(error?.error, { toastId: "customId" });
    }
  }

  return (
    <>
      <div className="h-full w-full mb-4 overflow-y-auto">
        <p className="font-[600] text-[20px] text-black pl-4" data-testid='patient-preferences'>
          Patient Preferences
        </p>
        <div className="my-6 pl-2 lg:ml-4 w-full lg:w-[500px]" data-testid='all-el-container'>
          <div className="flex items-center justify-between" data-testid='choice-container'>
            <p data-testid='initial-question'>Accepting new patients?</p>{" "}
            <div>
              <ToggleSwitch
                testId="choice-toggle"
                label=""
                isSelected={
                  patientFormik.values.acceptsAdults ||
                  patientFormik.values.acceptsGeriatrics ||
                  patientFormik.values.acceptsMinors ||
                  patientFormik.values.acceptsVirtualAppointments ||
                  patientFormik.values.acceptsPhysicalAppointments
                }
                onClick={() => {
                  patientFormik.setFieldValue(
                    "acceptsMinors",
                    !(
                      patientFormik.values.acceptsAdults ||
                      patientFormik.values.acceptsGeriatrics ||
                      patientFormik.values.acceptsMinors ||
                      patientFormik.values.acceptsVirtualAppointments ||
                      patientFormik.values.acceptsPhysicalAppointments
                    )
                  );
                  patientFormik.setFieldValue(
                    "acceptsGeriatrics",
                    !(
                      patientFormik.values.acceptsAdults ||
                      patientFormik.values.acceptsGeriatrics ||
                      patientFormik.values.acceptsMinors ||
                      patientFormik.values.acceptsVirtualAppointments ||
                      patientFormik.values.acceptsPhysicalAppointments
                    )
                  );
                  patientFormik.setFieldValue(
                    "acceptsAdults",
                    !(
                      patientFormik.values.acceptsAdults ||
                      patientFormik.values.acceptsGeriatrics ||
                      patientFormik.values.acceptsMinors ||
                      patientFormik.values.acceptsVirtualAppointments ||
                      patientFormik.values.acceptsPhysicalAppointments
                    )
                  );
                  patientFormik.setFieldValue(
                    "acceptsVirtualAppointments",
                    !(
                      patientFormik.values.acceptsAdults ||
                      patientFormik.values.acceptsGeriatrics ||
                      patientFormik.values.acceptsMinors ||
                      patientFormik.values.acceptsVirtualAppointments ||
                      patientFormik.values.acceptsPhysicalAppointments
                    )
                  );
                  patientFormik.setFieldValue(
                    "acceptsPhysicalAppointments",
                    !(
                      patientFormik.values.acceptsAdults ||
                      patientFormik.values.acceptsGeriatrics ||
                      patientFormik.values.acceptsMinors ||
                      patientFormik.values.acceptsVirtualAppointments ||
                      patientFormik.values.acceptsPhysicalAppointments
                    )
                  );
                }}
              />
            </div>
          </div>
          <span className="text-[#1A9D39] text-[16px]" data-testid='status'>
            You’re currently{" "}
            {!patientFormik.values.acceptsAdults &&
              !patientFormik.values.acceptsGeriatrics &&
              !patientFormik.values.acceptsMinors &&
              !patientFormik.values.acceptsVirtualAppointments &&
              !patientFormik.values.acceptsPhysicalAppointments &&
              "not"}{" "}
            accepting new patients
          </span>
          <div className="h-6" />
          <p data-testid='type-of-patient'>What kind of patient would you like?</p>
          <div className="h-4" />
          <div data-testid='chekbox-container'>
            <CheckWithLabelToggle
              labelTestId='minors-label'
              checkboxId="checkbox-minors"
              label="Minors"
              isSelected={patientFormik.values?.acceptsMinors}
              toggleSelection={() => {
                patientFormik.setFieldValue(
                  "acceptsMinors",
                  !patientFormik.values?.acceptsMinors
                );
              }}
            />
            <CheckWithLabelToggle
              labelTestId="adults-label"
              checkboxId='checkbox-adults'
              label="Adults"
              isSelected={patientFormik.values?.acceptsAdults || false}
              toggleSelection={() => {
                patientFormik.setFieldValue(
                  "acceptsAdults",
                  !patientFormik.values?.acceptsAdults
                );
              }}
            />
            <CheckWithLabelToggle
              labelTestId="geriatrics-label"
              checkboxId='checkbox-geriatrics'
              label="Older adults (geriatrics)"
              isSelected={patientFormik.values?.acceptsGeriatrics}
              toggleSelection={() => {
                patientFormik.setFieldValue(
                  "acceptsGeriatrics",
                  !patientFormik.values?.acceptsGeriatrics
                );
              }}
            />
            <CheckWithLabelToggle
              labelTestId="virtual-label"
              checkboxId='checkbox-virtual'
              label="Accepts Virtual Appointments"
              isSelected={patientFormik.values?.acceptsVirtualAppointments}
              toggleSelection={() => {
                patientFormik.setFieldValue(
                  "acceptsVirtualAppointments",
                  !patientFormik.values?.acceptsVirtualAppointments
                );
              }}
            />
            <CheckWithLabelToggle
              labelTestId="physical-label"
              checkboxId='checkbox-physical'
              label="Accepts Physical Appointments"
              isSelected={patientFormik.values?.acceptsPhysicalAppointments}
              toggleSelection={() => {
                patientFormik.setFieldValue(
                  "acceptsPhysicalAppointments",
                  !patientFormik.values?.acceptsPhysicalAppointments
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="h-[96px] w-full bg-white border-t border-[#ddd] bottom-0 sticky">
        <div className="h-full w-full flex justify-end items-center">
          <p
            onClick={() => {
              patientFormik.resetForm();
            }}
            className="text-sm text-[#3B3D24] font-[500] mr-4 cursor-pointer"
            data-testid='discard-changes'
          >
            Discard all changes
          </p>
          <div className="w-[122px] lg:mr-5" data-testid='save-changes-container'>
            <Button
              type="button"
              variant="primary"
              label="Save changes"
              size="medium"
              loading={isPending}
              onClick={() => {
                updateProviderProfileDetails({
                  acceptsAdults: patientFormik.values?.acceptsAdults,
                  acceptsVirtualAppointments:
                    patientFormik.values?.acceptsVirtualAppointments,
                  acceptsPhysicalAppointments:
                    patientFormik.values?.acceptsPhysicalAppointments,
                  acceptsGeriatrics: patientFormik.values?.acceptsGeriatrics,
                  acceptsMinors: patientFormik.values?.acceptsMinors
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Patient;
