export function DayParams({
  sundayTimes,
  mondayTimes,
  tuesdayTimes,
  wednesdayTimes,
  thursdayTimes,
  fridayTimes,
  saturdayTimes,
  setSundayTimes,
  setMondayTimes,
  setTuesdayTimes,
  setWednesdayTimes,
  setThursdayTimes,
  setFridayTimes,
  setSaturdayTimes,
  dayStatus
}: {
  sundayTimes: string[][];
  mondayTimes: string[][];
  tuesdayTimes: string[][];
  wednesdayTimes: string[][];
  thursdayTimes: string[][];
  fridayTimes: string[][];
  saturdayTimes: string[][];
  setSundayTimes: React.Dispatch<React.SetStateAction<string[][]>>;
  setMondayTimes: React.Dispatch<React.SetStateAction<string[][]>>;
  setTuesdayTimes: React.Dispatch<React.SetStateAction<string[][]>>;
  setWednesdayTimes: React.Dispatch<React.SetStateAction<string[][]>>;
  setThursdayTimes: React.Dispatch<React.SetStateAction<string[][]>>;
  setFridayTimes: React.Dispatch<React.SetStateAction<string[][]>>;
  setSaturdayTimes: React.Dispatch<React.SetStateAction<string[][]>>;
  dayStatus: {
    Monday: boolean;
    Tuesday: boolean;
    Wednesday: boolean;
    Thursday: boolean;
    Friday: boolean;
    Saturday: boolean;
    Sunday: boolean;
  };
}) {
  const dayParams: any = [
    {
      day: "Sunday",
      lists: sundayTimes,
      fn: setSundayTimes,
      status: dayStatus.Sunday
    },
    {
      day: "Monday",
      lists: mondayTimes,
      fn: setMondayTimes,
      status: dayStatus.Monday
    },
    {
      day: "Tuesday",
      lists: tuesdayTimes,
      fn: setTuesdayTimes,
      status: dayStatus.Tuesday
    },
    {
      day: "Wednesday",
      lists: wednesdayTimes,
      fn: setWednesdayTimes,
      status: dayStatus.Wednesday
    },
    {
      day: "Thursday",
      lists: thursdayTimes,
      fn: setThursdayTimes,
      status: dayStatus.Thursday
    },
    {
      day: "Friday",
      lists: fridayTimes,
      fn: setFridayTimes,
      status: dayStatus.Friday
    },
    {
      day: "Saturday",
      lists: saturdayTimes,
      fn: setSaturdayTimes,
      status: dayStatus.Saturday
    }
  ];
  return dayParams;
}
