import ModalPortal from "../../components/shared/ModalPortal";
import { CancelIcon } from "../../svgs/CancelIcon";
import errorIcon from "../../svgs/error_icon.svg";
import successIcon from "../../svgs/success-icon.svg";

interface IProps {
  closeModal: () => void;
  isVisible: boolean;
  isForSuccess?: boolean;
  title: string;
  message: string;
}
const FeedbackModal: React.FC<IProps> = ({
  closeModal,
  isVisible = false,
  isForSuccess,
  message,
  title
}) => {
  return isVisible ? (
    <ModalPortal>
      <div
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center"
        }}
        className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
        data-testid="feedback-modal"
      >
        <div className="flex justify-end  w-[90%] lg:w-[865px] mx-auto py-2">
          <div
            onClick={() => {
              closeModal();
            }}
            className="p-2 bg-white rounded-full cursor-pointer"
            data-testid='close-btn'
          >
            <CancelIcon className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white h-[297px] w-[90%] lg:w-[865px] px-4 pt-5 pb-4 lg:p-6 lg:pb-4 shadow-lg rounded-[16px] border-box flex flex-col items-center gap-4">
          <p className="text-[#2E3011] text-[24px] font-[600]">
            <img
              src={isForSuccess ? successIcon : errorIcon}
              alt="success"
              height={80}
              width={80}
              data-testid="success-img-icon"
            />
          </p>
          <div className="my-4">
            <div className="w-full flex flex-col items-center">
              <div className="text-[#103C1B] text-[30px] font-[400] h-full flex items-center justify-center w-full">
                {title}
              </div>
              <div className="my-4 text-[#103C1B] text-[18px] font-[400] h-full flex items-center justify-center w-full text-center">
                <p className="w-3/4" data-testid='message'> {message}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  ) : null;
};

export default FeedbackModal;
