import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import AdminDataInfoCard from "../../../admin/components/AdminDataInfoCard";
import { useUpdateChargeSheet } from "../../../api/hooks/provider/updateChargeSheet";
import { useGetChargeSheetProvider } from '../../../api/hooks/provider/useGetChargeSheetProvider';
import { useSaveChargeSheetDraft } from '../../../api/hooks/provider/useSaveChargeSheet';
import { useSendChargeSheetToBilling } from "../../../api/hooks/provider/useSendChargeSheetToBilling";
import { ProviderCharge, status } from '../../../api/types';
import Table from "../../../components/ReusableTable";
import SearchField from "../../../components/form/SearchField";
import BtnButton from "../../../components/shared/BtnButton";
import { Loading } from '../../../components/shared/Loading';
import { ArrowBackIcon } from "../../../svgs/ArrowBackIcon";
import ChargeSheet from "../../components/ChargeSheet";
import MobilePaymentAndBillingListCard from "../../components/MobilePaymentAndBillingListCard";
import ProviderDashboardLayout from "../../components/dashboard/ProviderDashboardLayout";
import PaymentDetailsForPaidStatus from "./PaymentDetailsForPaidStatus";
import { StatusEl } from './components/statusEl';
import { usePaymentAndBillingTableCols } from './table/useTableCols';


const PaymentAndBilling = () => {
  const [isDetailsView, setIsDetailsView] = useState(false);
  const [isDetailsForStatusPaid, setIsDetailsForStatusPaid] = useState<boolean>(false);
  const [isNewBillingFormVisible, setIsNewBillingFormVisible] = useState<boolean>(false);
  const [singleDetailsData, setSingleDetailsData] = useState<ProviderCharge | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFromNewBilling, setIsFromNewBilling] = useState<boolean>(false);
  const [IsFromNewBillingTable, setIsFromNewBillingTable] = useState<boolean>(false);

  const handleListItemClick = (data: ProviderCharge) => {
    setSingleDetailsData(data);
    if (data?.status === "approved") {
      setIsDetailsForStatusPaid(true);
      setIsDetailsView(false);
      setIsNewBillingFormVisible(false);
    } else {
      setIsNewBillingFormVisible(false);
      setIsDetailsForStatusPaid(false);
      setIsDetailsView(true);
    }
    setIsFromNewBilling(false);
  };

  const updateSearch = (event: any) => {
    setSearchTerm(event.target.value.trim().substr(0, 30));
  };

  const filteredData: any[] = [];

  const { columns } = usePaymentAndBillingTableCols()

  const { postChargeSheetToBilling, isPending } = useSendChargeSheetToBilling()
  const { data, isLoading, isError: ischError, error: chError } = useGetChargeSheetProvider()

  if (ischError) {
    const message = axios.isAxiosError(chError) ? chError?.response?.data?.error : "Error processing request";
    toast.error(message, { toastId: "customId" })
  }

  const { saveChargeSheet, isPending: isSavingDraft, } = useSaveChargeSheetDraft()
  const { updateChargeSheet, isPending: isUpdatingChargeSheet } = useUpdateChargeSheet();

  return (
    <ProviderDashboardLayout headerTitle="Billing history">
      {isNewBillingFormVisible && (
        <div className="w-full h-full mt-8" data-testid='new-billing'>
          <div
            onClick={() => {
              setIsDetailsView(false);
              setIsNewBillingFormVisible(false);
              setIsDetailsForStatusPaid(false);
              setIsFromNewBilling(false)
            }}
            className="w-full cursor-pointer pl-4 flex justify-start items-center"
            data-testid='go-back'
          >
            <ArrowBackIcon />{" "}
            <div className="ml-3" data-testid='go-back-text'>Go back to billing & payment</div>
          </div>
          <ChargeSheet
            isUpdatingChargeSheet={isUpdatingChargeSheet}
            setIsFromNewBillingTable={setIsFromNewBillingTable}
            IsFromNewBillingTable={IsFromNewBillingTable}
            setIsDetailsView={setIsDetailsView}
            setIsNewBillingFormVisible={setIsNewBillingFormVisible}
            setIsDetailsForStatusPaid={setIsDetailsForStatusPaid}
            isSendingToBilling={isPending}
            isSavingDraft={isSavingDraft}
            isFromNewBilling={isFromNewBilling}
            chargeSheetData={isFromNewBilling ? singleDetailsData : null}
            useDropdownPatientNameField={true}
            saveAction={({ patientId, appointmentId, procedureCodes, diagnosisCodes, modeOfEncounter, locationOfEncounter, dateOfEncounter }) => {
              saveChargeSheet({
                patientId,
                appointmentId,
                procedureCodes,
                diagnosisCodes,
                status: "draft",
                modeOfEncounter,
                locationOfEncounter,
                dateOfEncounter
              })
            }}
            sendToBillingAction={({ patientId, appointmentId, procedureCodes, diagnosisCodes, modeOfEncounter, locationOfEncounter, dateOfEncounter }) => {
              postChargeSheetToBilling({
                patientId,
                appointmentId,
                procedureCodes,
                diagnosisCodes,
                status: "processing",
                modeOfEncounter,
                locationOfEncounter,
                dateOfEncounter
              });
            }}
            updateChargesheet={({ chargeId, procedureCodes, diagnosisCodes, modeOfEncounter, locationOfEncounter, dateOfEncounter }) => {
              updateChargeSheet({
                chargeId,
                procedureCodes,
                diagnosisCodes,
                status: "processing",
                modeOfEncounter,
                locationOfEncounter,
                dateOfEncounter
              })
            }}
          />
        </div>
      )}

      {isDetailsView && (
        <div className="w-full h-full mt-8">
          <div className="w-full flex items-center justify-between px-4">
            <div
              onClick={() => {
                setIsDetailsView(false);
                setIsNewBillingFormVisible(false);
                setIsDetailsForStatusPaid(false);
                setIsFromNewBilling(false)
                setSingleDetailsData(null)
              }}
              className="w-full cursor-pointer flex justify-start items-center"
              data-testid='go-back'
            >
              <ArrowBackIcon />{" "}
              <div className="ml-3" data-testid='go-back-title'>Go back to billing & payment</div>
            </div>
            <div className="flex justify-end" data-testid='statusEl'>
              <StatusEl status={singleDetailsData?.status as status} />
            </div>
          </div>

          <ChargeSheet
            isUpdatingChargeSheet={isUpdatingChargeSheet}
            setIsFromNewBillingTable={setIsFromNewBillingTable}
            IsFromNewBillingTable={IsFromNewBillingTable}
            setIsDetailsView={setIsDetailsView}
            setIsNewBillingFormVisible={setIsNewBillingFormVisible}
            setIsDetailsForStatusPaid={setIsDetailsForStatusPaid}
            isSendingToBilling={isPending}
            isSavingDraft={isSavingDraft}
            isFromNewBilling={isFromNewBilling}
            saveAction={({ patientId, appointmentId, procedureCodes, diagnosisCodes, modeOfEncounter, locationOfEncounter, dateOfEncounter }) => {
              saveChargeSheet({
                patientId,
                appointmentId,
                procedureCodes,
                diagnosisCodes,
                status: "draft",
                modeOfEncounter,
                locationOfEncounter,
                dateOfEncounter
              })
            }}
            sendToBillingAction={({ patientId, appointmentId, procedureCodes, diagnosisCodes, modeOfEncounter, locationOfEncounter, dateOfEncounter }) => {
              postChargeSheetToBilling({
                patientId,
                appointmentId,
                procedureCodes,
                diagnosisCodes,
                status: "processing",
                modeOfEncounter,
                locationOfEncounter,
                dateOfEncounter
              });
            }}
            chargeSheetData={singleDetailsData}

            useDropdownPatientNameField={false}
            updateChargesheet={({ chargeId, procedureCodes, diagnosisCodes, modeOfEncounter, locationOfEncounter, dateOfEncounter }) => {
              updateChargeSheet({
                chargeId,
                procedureCodes,
                diagnosisCodes,
                status: "processing",
                modeOfEncounter,
                locationOfEncounter,
                dateOfEncounter
              })
            }}
          />
        </div>
      )}

      {isDetailsForStatusPaid && (
        <div className="w-full h-full mt-10">
          <div className="w-full flex items-center justify-between px-4">
            <div
              onClick={() => {
                setIsDetailsView(false);
                setIsNewBillingFormVisible(false);
                setIsDetailsForStatusPaid(false);
                setIsFromNewBilling(false)
                setSingleDetailsData(null)
              }}
              className="w-full cursor-pointer flex justify-start items-center"
              data-testid='go-back'
            >
              <ArrowBackIcon />{" "}
              <div className="ml-3" data-testid='go-back-title'>Go back to billing & payment</div>
            </div>
            <div className="flex justify-end">
              <StatusEl status={singleDetailsData?.status as status} />
            </div>
          </div>
          <div className="h-16" />
          <PaymentDetailsForPaidStatus details={singleDetailsData} />
        </div>
      )}

      {/* {isDetailsForStatusPaid && (
        <div className="h-auto lg:h-[96px] w-full bg-white border-t border-[#ddd] bottom-0 sticky py-4">
          <div className="h-full w-full flex flex-wrap lg:flex-nowrap justify-center lg:justify-end gap-3 items-center"> */}
      {/* <div className="w-full hidden lg:block lg:w-[97px]">
              <Button
                type="button"
                variant="secondary"
                label="Check codes"
                size="medium"
              // loading={}
              // onClick={()=> {}}
              />
            </div> */}
      {/* <div className="w-full flex justify-center lg:justify-end lg:hidden lg:w-[97px]">
              <Button
                type="button"
                variant="secondary"
                label="Check codes"
                size="large"
              // loading={}
              // onClick={()=> {}}
              />
            </div> */}
      {/* <div className="w-full hidden lg:block lg:w-[91px] mx-8">
              <Button
                type="submit"
                variant="secondary"
                label="Save draft"
                size="medium"
                loading={isSaving}
                onClick={saveDraft}
              />
            </div> */}
      {/* <div className="w-full flex justify-center lg:justify-end lg:hidden lg:w-[91px]">
              <Button
                type="button"
                variant="secondary"
                label="Save draft"
                size="large"
              // loading={}
              // onClick={()=> {}}
              />
            </div> */}
      {/* <div className="w-full hidden lg:block lg:w-[161px]">
              <Button
                type="button"
                variant="primary"
                label="Send to billing"
                size="medium"
              // loading={}
              // onClick={()=> {}}
              />
            </div> */}
      {/* <div className="w-full flex justify-center lg:justify-end lg:hidden lg:w-[161px]">
              <Button
                type="submit"
                variant="primary"
                label="Send to billing"
                size="large"
                loading={isPending}
                onClick={sendToBilling}
              />
            </div> */}
      {/* </div>
        </div>
      )} */}

      {!isDetailsForStatusPaid && !isDetailsView && !isNewBillingFormVisible && (
        <div className="w-full h-full px-4 mt-10">
          <div className="flex flex-wrap lg:flex-nowrap items-baseline justify-between w-full">
            <div className="w-full lg:w-auto flex flex-wrap min-[1100px]:flex-nowrap items-center">
              <div className="w-full lg:w-[374px] lg:mb-0 mb-4">
                <AdminDataInfoCard
                  title="Payment Received"
                  dataCount="$0"
                  green
                />
              </div>
              <div className="w-4" />
              <div className="w-full lg:w-[374px]">
                <AdminDataInfoCard
                  title="Payment Outstanding"
                  dataCount="$0"
                  green
                />
              </div>
            </div>
            <div className="w-4" />
            <div className="w-full lg:w-[114px] my-4 lg:my-0 flex justify-end" data-testid='btn-new-billing-container'>
              <BtnButton
                variant="primary"
                label="New Billing"
                size="medium"
                onClick={() => {
                  setIsDetailsView(false);
                  setIsDetailsForStatusPaid(false);
                  setIsNewBillingFormVisible(true);
                  setIsFromNewBilling(true)
                  setSingleDetailsData(null)
                }}
                textSizeProp="text-[12px]"
              />
            </div>
          </div>
          <div className="w-full flex items-center mt-8 mb-4 px-4">
            <div className="text-[24px] font-[600] text-[#324705]" data-testid='title-provider-payment-history'>
              Providers Payment History
            </div>
          </div>
          <div className="w-full mt-8 hidden lg:block">
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loading />
              </div>) : (
              <Table
                tableColumns={columns}
                tableData={data?.providerCharges ?? []}
                onClick={rowData => {
                  handleListItemClick(rowData);
                  setIsFromNewBillingTable(true)
                }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Payment"
              />
            )
            }
          </div>
          <div className="w-fullblock lg:hidden  pb-2">
            <SearchField
              placeholder="Search payment"
              value={searchTerm}
              onChange={updateSearch}
              name="searchTerm"
            />
          </div>

          <div className="w-full block lg:hidden lg:mt-8 shadow-[-1.74129px_3.48259px_21.7662px_#EEEEE9]">
            <p className="w-full h-[53px] bg-[#EEEEE9] text-[#34623F] text-[500] flex items-center my-2 px-4" data-testid='title-patients'>
              Patients
            </p>
            {filteredData &&
              filteredData?.map((item: any) => (
                <MobilePaymentAndBillingListCard
                  paymentAndBillingDetails={item}
                  onClick={() => {
                    handleListItemClick(item);
                  }}
                />
              ))}
          </div>
        </div>
      )}
    </ProviderDashboardLayout>
  );
};

export default PaymentAndBilling;
