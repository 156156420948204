import { isAxiosError } from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useUpdateProviderDetails } from "../../../api/hooks/provider/updateProviderDetails";
import { useGetProviderProfileDetails } from "../../../api/hooks/provider/useGetProviderProfileDetails";
import Button from "../../../components/shared/Button";
import CheckWithLabelToggle from "../../../components/shared/CheckWithLabelToggle";
import CustomDropdown from "../../../components/shared/CustomDropdown";
import { Loading } from "../../../components/shared/Loading";

const Notifications = () => {
  const notificationTimeList: { name: string; value: number }[] = [
    { name: "5 minutes", value: 5 },
    { name: "10 minutes", value: 10 },
    { name: "15 minutes", value: 15 },
    { name: "20 minutes", value: 20 },
    { name: "25 minutes", value: 25 },
    { name: "30 minutes", value: 30 },
    { name: "35 minutes", value: 35 },
    { name: "40 minutes", value: 40 },
    { name: "45 minutes", value: 45 },
    { name: "50 minutes", value: 50 },
    { name: "1 Hour", value: 60 }
  ]; 


  const { providerData, isLoading, isError, error } = useGetProviderProfileDetails();
  const { updateProviderProfileDetails, isPending } = useUpdateProviderDetails()



  const notificationFormik = useFormik({
    initialValues: {
      emailNotification: providerData?.user?.canReceiveEmailNotifications,
      textNotification: providerData?.user?.canReceiveTextNotifications,
      howLongBeforeCallNotification: providerData?.user?.notificationDelay,
      allowDesktopNotification: providerData?.user?.canReceiveDesktopNotifications
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      emailNotification: Yup.boolean(),
      textNotification: Yup.boolean(),
      howLongBeforeCallNotification: Yup.number().required("Required"),
      allowDesktopNotification: Yup.boolean()
    }),
    onSubmit: () => { }
  });

  // useEffect(() => {
  //   if (!("Notification" in window)) {
  //     // logs "This browser does not support desktop notification";
  //   } else if (
  //     "Notification" in window &&
  //     notificationFormik.values?.allowDesktopNotification
  //   ) {
  //     Notification.requestPermission();
  //   }
  // }, [notificationFormik.values?.allowDesktopNotification]);

  // const showNotification = useCallback(async () => {
  //   setTimeout(() => {
  //     if (notificationFormik?.values?.howLongBeforeCallNotification < 60) {
  //       new Notification(
  //         `Your next meeting starts in ${
  //           notificationFormik?.values?.howLongBeforeCallNotification || 5
  //         } minutes`
  //       );
  //     } else if (
  //       notificationFormik?.values?.howLongBeforeCallNotification === 60
  //     ) {
  //       new Notification(`Your next meeting starts in 1 hour`);
  //     }
  //   }, notificationFormik?.values?.howLongBeforeCallNotification * 60000);
  // }, [notificationFormik?.values?.howLongBeforeCallNotification]);

  // useEffect(() => {
  //   showNotification();
  // }, [showNotification]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loading />
      </div>
    );
  }

  if (isError) {
    if (isAxiosError(error)) {
      const message = error?.response?.data?.error;
      toast.error(message, { toastId: "customId" });

    } else {
      toast.error(error?.error, { toastId: "customId" });
    }
  }

  return (
    <>
      <div className="h-full w-full mb-10 overflow-y-auto">
        <p className="font-[600] text-[20px] text-black pl-4" data-testid='notifications'>
          Notifications Settings
        </p>
        <div className="my-6 ml-4">
          <p data-testid='notification-question'>Where would you like to receive notifications?</p>
          <div className="h-6" />
          <div data-testid='notification-options-container'>
            <CheckWithLabelToggle
              label="Via email address"
              isSelected={notificationFormik.values?.emailNotification as boolean}
              toggleSelection={() => {
                notificationFormik.setFieldValue("emailNotification", !notificationFormik.values?.emailNotification);
              }
              }
            />
            <CheckWithLabelToggle
              label="Via a text message"
              isSelected={notificationFormik.values?.textNotification as boolean}
              toggleSelection={() => {
                notificationFormik.setFieldValue("textNotification", !notificationFormik.values?.textNotification);

              }}
            />
          </div>
          <div className="h-6" />
          <div className="w-[335px]">
            <p className="pb-2" data-testid='notification-time'>
              How long before your call do you want to receive a notification?
            </p>
            <CustomDropdown
              value={Number(notificationFormik.values?.howLongBeforeCallNotification)}
              name="howLongBeforeCallNotification"
              optionsList={notificationTimeList}
              placeholder=""
              onChange={event => {
                notificationFormik.setFieldValue(
                  "howLongBeforeCallNotification",
                  event.target.value
                );
                //  showNotification();
              }}
              testId="notification-time-select"
            />
          </div>
          options
          <div className="h-6" />
          <div className="w-[335px]">
            <p className="pb-2" data-testid='notification-type'>
              Do you want to turn on desktop notifications?
            </p>
            <div className="w-full">
              <select
                className="w-full py-3 px-4 text-[#6E8877] border border-grey focus:outline-none rounded"
                value={notificationFormik?.values?.allowDesktopNotification ? "Yes" : "No"}
                name="allowDesktopNotification"
                onChange={event => {
                  const selectedValue = event.target.value;
                  notificationFormik.setFieldValue(
                    "allowDesktopNotification",
                    selectedValue === "Yes"
                  );
                }}
                data-testid='notification-type-select'
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>


          </div>
        </div>
      </div>
      <div className="h-[96px] w-full bg-white border-t border-[#ddd] bottom-0 sticky">
        <div className="h-full flex justify-end items-center">
          <div className="w-[122px] lg:mr-5" data-testid='save-button-container'>
            <Button
              type="button"
              variant="primary"
              label="Save changes"
              size="medium"
              loading={isPending}
              onClick={() => {
                updateProviderProfileDetails({
                  canReceiveEmailNotifications: notificationFormik.values?.emailNotification,
                  canReceiveTextNotifications: notificationFormik.values?.textNotification,
                  notificationDelay: notificationFormik.values?.howLongBeforeCallNotification,
                  canReceiveDesktopNotifications: notificationFormik.values?.allowDesktopNotification
                })
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
