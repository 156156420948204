import { API_URL } from "../constants/base_url";
import http from "../utils/http";

export const callStripeTotakePatientCard = async (data: any) => {
  try {
    const { providerId, dependentId, ...rest } = data;
    const response = await http.post(
      `appointments/patient?providerId=${providerId}&dependentId=${dependentId}`,
      {
        ...rest
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const setUpPatientPayment = async (
  data: any,
  stripeId: string,
  cancel_url: string
) => {
  localStorage.setItem("book_appointment_data", JSON.stringify(data));

  try {
    const response = await http.post(`stripe/setup-payment`, {
      stripeId,
      cancel_url
    });
    if (response.status === 200) {
      window.location.href = response.data.url;
    }
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const bookAppointmentsForPatients = async (data: any) => {
  try {
    const { providerId, dependentId, ...rest } = data;
    const response = await http.post(
      `appointments/patient?providerId=${providerId}&dependentId=${dependentId}`,
      {
        ...rest
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    return err?.response?.data;
  }
};

// export const cancelAppointment = async (appointmentId: string) => {
//   try {
//     const response = await http.delete(`appointments/patient/${appointmentId}`);
//     if (response.status === 200) {
//       return response.data;
//     }
//     throw response.error;
//   } catch (e) {
//     return e;
//   }
// };

// export const getProviderPreviousAppointments = async () => {
//   try {
//     const response = await http.get("appointments/providers/previous");
//     if (response.status === 200) {
//       return response.data;
//     }
//   } catch (err: any) {
//     return err?.response.data;
//   }
// };

export const getAllPatientPreviousAppointments = async () => {
  try {
    const response = await http.get("appointments/patient/previous/all");
    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getPatientPreviousAppointments = async (query: string) => {
  try {
    const response = await http.get(
      `appointments/patient/previous-providers?specialty=${query}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const checkInsuranceEligibility = async ({
  insured,
  providerNPI,
  patRel = "18"
}: {
  insured: { firstName: string; lastName: string; payerId: string };
  providerNPI: string;
  patRel?: string;
}) => {
  const today = new Date(Date.now());
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const date = year + "" + month + "" + day;
  try {
    const response = await http.post(`${API_URL}/billers/eligibility`, {
      firstName: insured.firstName,
      lastName: insured?.lastName,
      payerId: insured.payerId,
      patRel: patRel,
      fdos: `${date}`,
      providerNPI: providerNPI
    });
    return response;
  } catch (error: any) {
    return error;
  }
};
