import clsx from "clsx";

interface AdminDataInfoCardProps {
  title: string;
  dataCount: number | string;
  blue?: boolean;
  green?: boolean;
  yellow?: boolean;
  red?: boolean;
}

const AdminDataInfoCard = ({
  dataCount,
  title,
  blue,
  green,
  yellow,
  red
}: AdminDataInfoCardProps) => {
  return (
    <div
      className={clsx({
        "border-l-4 border-b-4 rounded-[24px] shadow-md shadow-[-2px 4px 25px #EEEEE9] bg-[#fff] w-full h-[143px] py-4 px-4 box-border":
          true,
        "border-[#5D5FEF]": blue,
        "border-[#3D874E]": green,
        "border-[#F1A512]": yellow,
        "border-[#97171E]": red
      })}
      data-testid='billing-info-card'
    >
      <p className="text-left text-[#324705] font-[500] text-[20px]" data-testid='billing-card-title'>{title}</p>
      <div className="w-full text-left py-4 text-[40px] text-[#324705] font-[600]" data-testid='billing-card-count'>
        {dataCount}
      </div>
    </div>
  );
};
export default AdminDataInfoCard;