import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ListTabsWidget from '../../components/shared/ListTabsWidget';
import ProviderDashboardLayout from "../components/dashboard/ProviderDashboardLayout";
import BillingSettings from "./billing_settings";
import Subscriptions from "./subscription_pricing";

export default function Subscription() {

  const search = useSearchParams()[0];
  const navigate = useNavigate();
  const activeTab = search.get("activeTab");
  const [activeTabLabel, setActiveTabLabel] = useState(
    activeTab || "Subscription"
  );

  const handleTabClick = (tabName: string) => {
    navigate(`/provider/subscription?activeTab=${tabName}`);
    setActiveTabLabel(tabName);
  };

  return (
    <ProviderDashboardLayout headerTitle="Subscription">
      <div className="lg:mt-6 flex flex-col justify-end w-full h-full">
        <div className="overflow-y-hidden mb-6 px-4 w-full overflow-x-scroll lg:overflow-x-hidden py-4">
          <ListTabsWidget
            tabs={[
              { label: "Subscription" },
              // { label: "Billing Settings" },

            ]}
            handleTabClick={label => {
              handleTabClick(label);
            }}
            activeTabLabel={activeTabLabel}
          />
        </div>

        {(activeTab === "Subscription" || activeTab === null) && (
          <div className="-mb-52">
            <Subscriptions />
          </div>
        )}
        {activeTab === "Billing Settings" && (
          <BillingSettings />
        )}
      </div>
    </ProviderDashboardLayout>
  )
}
