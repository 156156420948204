import axios from "axios";
import { add, format } from "date-fns";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useGetProvidersAppointments } from "../../../api/hooks/provider/useGetProviderAppointments";
import { useRescheduleAppointment } from "../../../api/hooks/provider/useRescheduleAppoint";
import BtnButton from "../../../components/shared/BtnButton";
import { Loading } from "../../../components/shared/Loading";
import ModalPortal from "../../../components/shared/ModalPortal";
import { CancelIcon } from "../../../svgs/CancelIcon";

interface IProps {
  oldId: string;
  closeModal: () => void;
  isVisible: boolean;
}

const RescheduleAppointmentModal: React.FC<IProps> = ({
  oldId,
  closeModal,
  isVisible = false
}) => {
  const [availableDatesAndTimes, setAvailableDatesAndTimes] = useState<{
    [key: string]: Array<string>;
  }>({});
  const [selectedDateTimes, setSelectedDateTimes] = useState<any[]>([]);

  const { rescheduleAppointment, isPending } = useRescheduleAppointment();

  const formik = useFormik({
    initialValues: {
      appointmentDate: "",
      appointmentStartTime: ""
    },
    validationSchema: Yup.object({
      appointmentDate: Yup.string().required("Start date is required"),
      appointmentStartTime: Yup.string().required("Start time is required")
    }),
    onSubmit: () => { }
  });

  const {
    availableAppointments: dateList,
    isError,
    error,
    isLoading
  } = useGetProvidersAppointments();

  useEffect(() => {
    if (dateList && dateList.providerAvailability) {
      const datesAndTimesAvailable: { [key: string]: Array<string> } = {};
      dateList?.providerAvailability[0]?.availability?.forEach(
        ({ appointmentDate, appointmentTimes }) => {
          appointmentTimes
            .map(time => {
              const composeDate = add(new Date(appointmentDate), {
                hours: Number(time.slice(0, 2)),
                minutes: Number(time.slice(3))
              });
              const formatTime = format(composeDate, "MM-dd-yy HH:mm");
              return formatTime;
            })
            .forEach(dateAndTime => {
              const [date, time] = dateAndTime.split(" ");
              if (datesAndTimesAvailable.hasOwnProperty(date)) {
                datesAndTimesAvailable[date].push(time);
              } else {
                datesAndTimesAvailable[date] = [time];
              }
            });
        }
      );
      setAvailableDatesAndTimes(datesAndTimesAvailable);
    }
  }, [dateList]);

  if (isLoading) {
    return (
      <div className=" h-1/2 flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (isError && axios.isAxiosError(error)) {
    toast.error(error?.response?.data?.error, { toastId: "customId" });
  }

  return isVisible ? (
    <ModalPortal>
      <div
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center"
        }}
        className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
        data-testid='reschedule-appointment-modal'
      >
        <div className="flex justify-end  w-[90%] min-[821px]:w-[775px] mx-auto py-2">
          <div
            data-testid='reschedule-appointment-close-modal'
            onClick={() => {
              closeModal();
            }}
            className="p-2 bg-white rounded-full cursor-pointer"
          >
            <CancelIcon className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white w-[90%] min-[821px]:w-[775px] px-6 pt-6 pb-4 lg:p-6 shadow-lg rounded-[16px] border-box">
          <p className="text-[#2E3011] text-[24px] font-[600]" data-testid='reschedule-appointment-title'>
            Reschedule Appointment
          </p>
          <div className="flex flex-col  items-center justify-center h-full">
            <form className="w-full h-full p-2">
              <div className="w-full my-8 flex items-center gap-x-2">
                <div className="w-1/2">
                  <div className="pb-2 text-[#6E8877]" data-testid='reschedule-appointment-start-date-title'>Start Date</div>
                  <div className="flex flex-row items-center justify-end">
                    <select
                      data-testid='reschedule-appointment-start-date'
                      className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                      name="patientId"
                      value={formik.values?.appointmentDate}
                      onChange={e => {
                        formik.setFieldValue("appointmentDate", e.target.value);
                        const times = Object.keys(availableDatesAndTimes).find(
                          appointmentDate => appointmentDate === e.target.value
                        );
                        if (times) {
                          setSelectedDateTimes(availableDatesAndTimes[times]);
                        }
                      }}
                    >
                      <option value="" disabled>
                        Select date
                      </option>
                      {Object.keys(availableDatesAndTimes).map(
                        (appointmentDate, index) => (
                          <option
                            key={index}
                            value={appointmentDate}
                            id={index.toString()}
                          >
                            {appointmentDate}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className="w-1/2">
                  <p className="mb-2 text-[#6E8877]">Start Time</p>
                  <select
                    data-testid='reschedule-appointment-start-time'
                    className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                    name="appointmentStartTime"
                    value={formik.values?.appointmentStartTime}
                    onChange={e => {
                      formik.setFieldValue(
                        "appointmentStartTime",
                        e.target.value
                      );
                    }}
                  >
                    <option value="" disabled>
                      Select time
                    </option>
                    {selectedDateTimes &&
                      selectedDateTimes.length &&
                      selectedDateTimes?.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item}
                            id={index.toString()}
                          >
                            {item}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="flex flex-row items-center justify-center mt-8 w-full">
                <div className="flex flex-row items-center justify-center w-[383px] h-[60px]">
                  {isPending ? (
                    <div className="flex justify-end w-full pr-20">
                      <Loading />
                    </div>
                  ) : (
                      <div data-testid='reschedule-appointment-button-container'>
                        <BtnButton
                          loading={isLoading}
                          type="button"
                          variant="primary"
                          label="Reschedule Appointment"
                          size="extra-large"
                          onClick={() => {
                            const { appointmentDate, appointmentStartTime } =
                              formik.values;
                            const formattedDate = format(
                              new Date(`${appointmentDate}`),
                              "yyyy-MM-dd"
                            );
                            const dateToUtc = new Date(
                              `${formattedDate}:${appointmentStartTime}`
                            ).toISOString();
                            rescheduleAppointment({
                              oldId: oldId,
                              date: dateToUtc.slice(0, 10),
                              time: dateToUtc.slice(11, 16)
                            });
                            closeModal();
                          }}
                        />
                      </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalPortal>
  ) : null;
};

export default RescheduleAppointmentModal;
