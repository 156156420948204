import { format } from "date-fns";
import { toZonedTime } from 'date-fns-tz';
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { ProviderCharge, status } from "../../../../api/types";
import { StatusEl } from '../components/statusEl';


export const usePaymentAndBillingTableCols = () => {
  const columns = useMemo<Column<ProviderCharge>[]>(
    () => [
      {
        Header: "Charge ID",
        accessor: "chargeId"
      },
      {
        Header: "Date",
        accessor: "dateOfEncounter",
        Cell: ({ cell: { value } }) => {
          const USA_TIMEZONE = 'America/New_York';
          const dateValue = new Date(value);
          const zonedDate = toZonedTime(dateValue, USA_TIMEZONE);

          return <Fragment>{format(zonedDate, 'MM/dd/yyyy')}</Fragment>;
        }
      },
      {
        Header: "Patient Name",
        accessor: "patientName",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value}</Fragment>;
        }
      },
      {
        Header: "Mode Of Appointment",
        accessor: "modeOfEncounter",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value === "virtual" ? "Virtual" : "Physical"}</Fragment>;
        }
      },
      {
        Header: "Insurance",
        accessor: "insurance",
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              {value ? value : "N/A"}
            </Fragment>
          );
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              <StatusEl status={value as status} />
            </Fragment>
          );
        }
      }
    ],
    []
  );

  return { columns };
}
