import React from "react";
import { useProviderAuthState } from '../../../api/hooks/provider/auth/useProviderAuthState';
import { getGreeting } from "../../../utils/helpers";

interface ProviderHeaderProps {
  loggedIn?: boolean | null;
  currentUserInfo?: any;
  headerTitle?: string;
}

const ProviderHeader: React.FC<ProviderHeaderProps> = ({
  loggedIn = null,
  headerTitle
}) => {
  const { user } = useProviderAuthState()

  if (!user) {
    return null;
  }

  return (
    <div className="hidden lg:flex z-100 top-0 items-center justify-between w-full h-[110px] m-auto mt-0 relative border-b border-[#EEEEE9]">
      {loggedIn ? (
        <div className="flex justify-between w-full mx-5">
          <div className="text-[24px] font-[600] text-[#324705] pt-5">
            {headerTitle ? (
              headerTitle
            ) : (
                <div data-testid="username">
                  {getGreeting()} {user && user?.firstName}
                </div>
            )}

          </div>
          <div className="flex bg-[#F8F8F7] px-5 py-3 rounded-full border-2 border-[#CECFC5]">
            <img
              src={user?.avatarUrl || "/assets/images/avatar.png"}
              alt="provider-avatar"
              className="w-[40px] h-[40px] rounded-full mr-2"
            />
            <div className="overflow-hidden">
              <p className="font-bold text-[15px]">
                {user &&
                  `${user?.firstName} ${user?.lastName}`}
              </p>
              <p className="text-[13px]">
                {user && user?.email}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <p className="font-gentiumBasic font-[700] text-[34px]"></p>
      )}
    </div>
  );
};

export default ProviderHeader;
