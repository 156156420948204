
type Props = {
  width?: string | number,
  height?: string | number
  viewBox?: string
  fill?: string
  xmlns?: string
  className?: string
}
export function CheckOne({
  width = 20,
  height = 21,
  viewBox = '0 0 20 21',
  fill = 'none',
  xmlns = 'http://www.w3.org/2000/svg',
  className
}: Props) {


  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns={xmlns} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.66485 3.82305C5.28533 2.2026 7.52576 1.19922 9.99935 1.19922C12.4729 1.19922 14.7134 2.2026 16.3338 3.82305L15.8919 4.26499L16.3338 3.82305C17.9543 5.44353 18.9577 7.68397 18.9577 10.1576C18.9577 12.6311 17.9543 14.8716 16.3338 16.492C14.7134 18.1125 12.4729 19.1159 9.99935 19.1159C7.52576 19.1159 5.28532 18.1125 3.66485 16.492L4.10679 16.0501L3.66485 16.492C2.0444 14.8716 1.04102 12.6311 1.04102 10.1576C1.04102 7.68397 2.0444 5.44353 3.66485 3.82306L3.66485 3.82305ZM9.99935 2.44922C7.8706 2.44922 5.94437 3.31132 4.54873 4.70694C3.15311 6.10258 2.29102 8.0288 2.29102 10.1576C2.29102 12.2863 3.15312 14.2125 4.54873 15.6082C5.94437 17.0038 7.8706 17.8659 9.99935 17.8659C12.1281 17.8659 14.0543 17.0038 15.4499 15.6082C16.8456 14.2125 17.7077 12.2863 17.7077 10.1576C17.7077 8.02881 16.8456 6.10258 15.45 4.70694C14.0543 3.31132 12.1281 2.44922 9.99935 2.44922Z" fill="#2E3011" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.608 7.21431C14.852 7.45839 14.852 7.85411 14.608 8.09819L9.60796 13.0982C9.36388 13.3423 8.96815 13.3423 8.72407 13.0982L6.22407 10.5982C5.98 10.3541 5.98 9.95839 6.22407 9.71431C6.46815 9.47023 6.86388 9.47023 7.10796 9.71431L9.16602 11.7724L13.7241 7.21431C13.9682 6.97023 14.3639 6.97023 14.608 7.21431Z" fill="#2E3011" />
    </svg>
  )
}