import * as Yup from "yup";
import { phoneRegExp } from "../../../../utils/consts";

export const BizRegSchema = Yup.object({
  title: Yup.string().nullable(),
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string().optional(),
  lastName: Yup.string().required("Last name is required"),
  address1: Yup.string().required("Address line 1 is required"),
  address2: Yup.string(),
  businessDescription: Yup.string(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zipCode: Yup.string().required("Zip code is required"),
  businessAddress1: Yup.string(),
  businessAddress2: Yup.string(),
  businessCity: Yup.string(),
  businessState: Yup.string(),
  businessZipCode: Yup.string(),
  mailingAddress1: Yup.string(),
  mailingAddress2: Yup.string(),
  mailingCity: Yup.string(),
  mailingState: Yup.string(),
  mailingZipCode: Yup.string(),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  businessName: Yup.string().required("Business name is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .test("is-valid-us-phone-number", "Invalid US phone number", value => {
      return phoneRegExp.test(value as string);
    })
});
