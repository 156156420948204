import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { GreenCheckIcon } from "../../svgs/GreenCheckIcon";
import Spacer from "../Spacer";

interface FieldProps {
  name?: string;
  onChange: any;
  formik: {
    errors: any;
    touched: any;
  };
  label?: string;
  value: string;
  size?: string;
  submissionTriggered?: boolean;
  placeholder: string;
  inputValid?: boolean;
}

const FormikPhoneInput = React.forwardRef<any, FieldProps>(
  (
    {
      name,
      onChange,
      formik,
      label = "",
      size,
      value,
      submissionTriggered,
      inputValid
    },
    ref
  ) => {
    const computedInputStyle = () => {
      if (name && formik.touched[name] && formik.errors[name]) {
        return { borderColor: "rgb(220 38 38)" };
      }
      return {};
    };
    return (
      <div className="w-full flex flex-col">
        <span className="flex">
          <label className="text-[#141921] font-medium text-xs" data-testid='label-title'>
            Mobile Number
          </label>
          <>
            <Spacer width={3} />
            <p className="text-yellow-500 text-xs">*</p>
          </>
        </span>
        <Spacer height={8} />
        <div className="flex justify-between relative hover:border-blue-500 border border-solid border-[#EBEBEB] rounded">
          <PhoneInput
            ref={ref}
            name={name}
            value={value}
            onChange={onChange}
            defaultCountry="US"
            style={computedInputStyle()}
            international
          />
          {inputValid && (
            <GreenCheckIcon className="absolute ml-[480px] mt-[10px]" />
          )}
        </div>
        {name &&
          (formik.touched[name] || submissionTriggered) &&
          formik.errors[name] && (
            <div className="text-input__error mt-2 text-xs text-red-600 capitalize">
              {formik.errors[name]}
            </div>
          )}
      </div>
    );
  }
);

export default FormikPhoneInput;
