import { getCurrentUser } from "../../../../api/auth";

export interface FormValues {
  title: string | null;
  firstName: string;
  middleName?: string | undefined;
  lastName: string;
  address1: string;
  address2?: string | undefined;
  city: string;
  state: string;
  zipCode: string;
  businessAddress1?: string | undefined;
  businessAddress2?: string | undefined;
  businessCity?: string | undefined;
  businessState?: string | undefined;
  businessZipCode?: string | undefined;
  mailingAddress1?: string | undefined;
  mailingAddress2?: string | undefined;
  mailingCity?: string | undefined;
  mailingState?: string | undefined;
  mailingZipCode?: string | undefined;
  businessDescription?: string | undefined;
  businessName: string;
  email: string;
  phoneNumber: string;
}

export const biz_reg_initialValues: FormValues = {
  title: getCurrentUser()?.title || "",
  firstName: getCurrentUser()?.firstName || "",
  middleName: getCurrentUser()?.middleName || "",
  lastName: getCurrentUser()?.lastName || "",
  address1: getCurrentUser()?.address || "",
  address2: "",
  city: getCurrentUser()?.city || "",
  state: getCurrentUser()?.state || "",
  zipCode: getCurrentUser()?.zipCode || "",
  businessName: getCurrentUser()?.practiceName || "",
  email: getCurrentUser()?.email || "",
  phoneNumber: getCurrentUser()?.phoneNumber || "",
  businessAddress1: "",
  businessAddress2: "",
  businessCity: "",
  businessState: "",
  businessZipCode: "",
  mailingAddress1: "",
  mailingAddress2: "",
  mailingCity: "",
  mailingState: "",
  mailingZipCode: "",
  businessDescription: ""
};
