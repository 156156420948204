import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { bookAppointmentsForPatients } from "../api/appointments";
import { useConfirmAppointmentPayment } from "../api/hooks/patient/appointmentConfirmation";
import Button from "../components/shared/Button";
import { Loading } from '../components/shared/Loading';
import errorIcon from "../svgs/error_icon.svg";
import successIcon from "../svgs/success-icon.svg";
import { isObjectNotEmpty } from "../utils/isObjectEmpty";

const Checkout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id") as string;
  const navigate = useNavigate();
  const [msg, setMsg] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isAlreadyBooked, setIsAlreadyBooked] = useState<boolean>(false);

  const removeRecords = () => {
    localStorage.removeItem("bookingDetails");
    localStorage.removeItem("medRecords");
    localStorage.removeItem("book_appointment_data");
  };

  const { retrieveSessionData, isPending } = useConfirmAppointmentPayment()

  useEffect(() => {
    const confirmPayment = async () => {
      try {
        // Retrieve session data
        const response = await retrieveSessionData({ sessionId });

        if (response.message === 'Payment successful') {
          const appointmentData = JSON.parse(
            localStorage.getItem('book_appointment_data') ?? '{}'
          );

          if (isObjectNotEmpty(appointmentData)) {
            if (appointmentData?.providerId) {
              try {
                // Book appointment
                const res = await bookAppointmentsForPatients(appointmentData);

                if (res?.appointmentDetails) {
                  setIsSuccess(true); // Appointment booked successfully
                  removeRecords(); // Remove booking details from local storage
                } else {
                  if (res.error === 'Appointment already booked') {
                    setIsSuccess(true); // Treat as success if already booked
                    setMsg(res.error);
                    setIsAlreadyBooked(true);
                    removeRecords(); // Remove booking details from local storage
                  } else {
                    setMsg(res.error || 'Unable to book appointment');
                    setIsSuccess(false);
                  }
                }
              } catch (error: any) {
                setMsg(error.error || 'Failed to book appointment. Please try again.');
                setIsSuccess(false);
                removeRecords();
              }
            }
          }
        } else {
          setMsg('Payment was not successful');
          setIsSuccess(false);
        }
      } catch (error: any) {
        setMsg(error.message || 'Failed to confirm payment. Please try again.');
        setIsSuccess(false);
      }
    };

    confirmPayment();
  }, [sessionId, retrieveSessionData]);


  return (
    <div className="min-w-screen min-h-screen flex flex-col items-center justify-center space-y-3">
      {isPending ? (
        <Loading />
      ) : isSuccess ? (
        <div className="text-center flex flex-col items-center justify-center space-y-3">
          {" "}
          <img src={successIcon} alt="success" />
          <p className="text-[#103C1B] text-[30px] font-[400]">Success!</p>
          <p className="text-[#103C1B] text-[18px] font-[400]">
              {isAlreadyBooked ? msg : "Appointment booked successfully"}
          </p>
          <Button
            variant="primary"
            label="Appointments"
            size="medium"
            onClick={() => navigate("/appointments")}
          />
        </div>
      ) : (
        <div className="text-center flex flex-col items-center justify-center space-y-3">
          {" "}
          <img src={errorIcon} alt="error" className="h-[100px] w-[100px]" />
          <p className="text-[#103C1B] text-[30px] font-[400]">Error!</p>
          <p className="text-[#103C1B] text-[18px] font-[400]">{msg}</p>
          <Button
            variant="primary"
            label="Try Again"
            size="medium"
            onClick={() => navigate("/dashboard/dependent/visit/schedule")}
          />
        </div>
      )}
    </div>
  );
};

export default Checkout;
