import { ReactComponent as CheckboxChecked } from "../../svgs/checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "../../svgs/checkbox-unchecked.svg";

interface CheckBoxProps {
  label: string;
  isSelected: boolean;
  toggleSelection: () => void; // Make icon selected or not
  labelTestId?: string;
  checkboxId?: string;
  uncheckedTestId?: string;
}

const CheckWithLabelToggle = ({
  label,
  isSelected,
  toggleSelection,
  labelTestId,
  checkboxId,
  uncheckedTestId
}: CheckBoxProps) => {
  return (
    <div className="flex justify-between items-center w-full rounded mb-4">
      <div className="flex items-center">
        {isSelected ? (
          <CheckboxChecked
            data-testid={checkboxId}
            role="img"
            aria-label="Checked"
            className="cursor-pointer"
            onClick={() => {
              toggleSelection();
            }}
          />
        ) : (
          <CheckboxUnchecked
              data-testid={uncheckedTestId}
              role="img"
              aria-label="Unchecked"
            className="cursor-pointer"
            onClick={() => {
              toggleSelection();
            }}
          />
        )}
        {/* <div className="w-2" /> */}
        <p className="font-normal text-[#434343] ml-2 text-[16px]" data-testid={labelTestId}>{label}</p>
      </div>
    </div>
  );
};

export default CheckWithLabelToggle;
