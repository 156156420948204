import { Divider } from "@mui/material";
import axios from "axios";
import {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState
} from "react";
import { toast } from "react-toastify";
// import { useGetCompletedAppointmentData } from "../../api/hooks/provider/useGetCompletedAppointmentData";
import { useFormik } from "formik";
import { useGetCompletedAppointmentData } from "../../api/hooks/provider/useGetCompletedAppointmentData";
import { useGeProviderPatient } from "../../api/hooks/provider/useGetProviderPatients";
import { useGetSinglePatientRecord } from "../../api/hooks/provider/useGetSinglePatientRecord";
import { useVerifyDiagnosisCode } from "../../api/hooks/provider/useVerifyDiagnosisCodes";
import { useVerifyProcedureCode } from "../../api/hooks/provider/useVerifyProcedureCodes ";
import { DataSourceType } from "../../api/types";
import RadioField from "../../components/form/RadioField";
import TextField from "../../components/form/TextField";
import Button from "../../components/shared/Button";
import { Loading } from "../../components/shared/Loading";
import { useDebounce } from "../../hooks/useDebounce";
import { DeleteIcon } from "../../svgs/DeleteIcon";
import { PlusIcon } from "../../svgs/PlusIcon";
import { useSyncFormValues } from "./hooks/use_sync_form_values";
import {
  ChargeSheetProps,
  FormValues,
  initialValues
} from './types/chargeSheet';
import { chargeSheetschema } from "./utils/chargeSheetSchema";

const ChargeSheet = ({
  updateChargesheet,
  sendToBillingAction,
  saveAction,
  chargeSheetData,
  useDropdownPatientNameField = false,
  isSendingToBilling,
  isSavingDraft,
  isFromNewBilling,
  setIsDetailsView,
  setIsNewBillingFormVisible,
  setIsDetailsForStatusPaid,
  IsFromNewBillingTable,
  setIsFromNewBillingTable,
  isUpdatingChargeSheet
}: ChargeSheetProps) => {
  const sheetTop = useRef<null | HTMLDivElement>(null);
  const [patientId, setPatientId] = useState<string>("");

  const scrollToTop = () => {
    sheetTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const { values, setFieldValue, setValues } = useFormik<FormValues>({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: chargeSheetschema,
    onSubmit: (values) => { },
  });

  const {
    patientName,
    dateOfBirth,
    gender,
    providerName,
    patientInsurance,
    modeOfEncounter,
    locationOfEncounter,
    diagnosisCodes,
    procedureCodes,
    dateOfEncounter,
    appointmentId,
    status,
    patientId: patientIdFromProps,
  } = values ?? {};

  const procedurecode = Array.isArray(procedureCodes) ? (procedureCodes).join(",") : "";
  const diagnosiscode = Array.isArray(diagnosisCodes) ? (diagnosisCodes).join(", ") : "";

  const debouncedProcedureValue = useDebounce(procedurecode, 500);
  const debouncedDiagnosisValue = useDebounce(diagnosiscode, 500);

  const validateCodes = () => {
    if (!procedureCodes || (Array.isArray(procedureCodes) && procedureCodes.length === 0)) {
      toast.error("Procedure code is required");
      return false;
    }
    if (!diagnosisCodes || (Array.isArray(diagnosisCodes) && diagnosisCodes.length === 0)) {
      toast.error("Diagnosis code is required");
      return false;
    }
    return true;
  };

  const {
    isLoading: isFetchingProCode,
    procedureData,
    refetch: refetchProcedureCode
  } = useVerifyProcedureCode(debouncedProcedureValue);

  const {
    isLoading: isFetchingDiagCode,
    refetch: refetchDiagnosisCode,
    diagnosisData
  } = useVerifyDiagnosisCode(debouncedDiagnosisValue);

  const {
    data: patientData,
    isLoading: isLoadingPatient,
    isError: isPatientError,
    error: patientError
  } = useGetSinglePatientRecord(patientId ? patientId : patientIdFromProps);

  if (isPatientError) {
    const message = axios.isAxiosError(patientError)
      ? patientError?.response?.data?.error
      : "Error getting patient data";
    toast.error(message, { toastId: "customId" });
  }

  useSyncFormValues({
    initialValues,
    chargeSheetData: chargeSheetData ?? null,
    patientData,
    setValues,
    values,
    isFromNewBilling: !!isFromNewBilling,
    IsFromNewBillingTable: !!IsFromNewBillingTable
  });

  const {
    completedAppointments,
    isError: isLastError,
    error: lastError,
    isLoading,
  } = useGetCompletedAppointmentData(
    (patientId ? chargeSheetData?.patientId : patientIdFromProps) ?? ""
  );

  if (isLastError) {
    const message = axios.isAxiosError(lastError)
      ? lastError?.response?.data?.error
      : "Error fetching initialVisitNote";
    toast.error(message, { toastId: "customId" });
  }

  const {
    providerPatientsData,
    isError,
    error,
    isLoading: isLoadingProPatient
  } = useGeProviderPatient();

  if (isError && axios.isAxiosError(error)) {
    toast.error(error?.response?.data?.error, { toastId: "customId" });
  }

  if (isLoadingProPatient || isLoading) {
    return (
      <div className=" h-1/2 flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  const handlePatientIdChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setPatientId(value);
  };

  // const handleLastAppointmentChange = (e: ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedAppointment(e.target.value);
  //   const parsedValue = JSON.parse(e.target.value || "{}");
  //   setFieldValue("modeOfEncounter", parsedValue?.appointmentType);
  //   handleChange(e);
  // };

  const handleAddDiagnosisCode = () => {
    if (status === "processing") return
    setFieldValue('diagnosisCodes', Array.isArray(values.diagnosisCodes) ? [...values.diagnosisCodes, ''] : [values.diagnosisCodes, '']);
  };

  const handleAddProcedureCode = () => {
    if (status === "processing") return
    setFieldValue('procedureCodes', Array.isArray(values.procedureCodes) ? [...values.procedureCodes, ''] : [values.procedureCodes, '']);
  };

  return (
    <div className="bg-white w-full flex items-center justify-between" data-testid='chargesheet'>
      <div ref={sheetTop} />
      <div className="w-full h-[90%] my-8 mx-4">
        <div className="w-full my-4">
          <p className="text-[#2E3011] text-[28px] font-[600] pb-8" data-testid='chargesheet-title'>
            Charge sheet
          </p>
          <p className="text-[#324705] text-[24px] font-[500]" data-testid='chargesheet-subtitle'>
            Patient Information
          </p>
          <div className="h-6" />
          <div className="flex flex-wrap lg:flex-nowrap items-center">
            {useDropdownPatientNameField ? (
              <div className="w-full lg:w-[358px]">
                <p className="text-[#000] font-medium mb-2 text-[16px]" data-testid='patient-name-title'>
                  Patient Name
                </p>
                <select
                  className="w-[100%] h-[60px] py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                  name="patientName"
                  value={patientId}
                  onChange={handlePatientIdChange}
                  data-testid='input-patient-name'
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {providerPatientsData?.providersPatients?.map(
                    ({ userId, name }) => (
                      <option value={userId} key={userId}>
                        {name}
                      </option>
                    )
                  )}
                </select>
              </div>
            ) : (
                <div className="w-full lg:w-[358px]" data-testid='patient-name-container'>
                  <span className="text-black text-[18px] font-[500]" data-testid='patient-name-title'>
                  Patient Name
                </span>
                <input
                  placeholder="Enter patient name"
                  type="text"
                  name="patientName"
                  value={patientName ?? ""}
                  className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={(e) => {
                      setFieldValue("patientName", e.target.value);
                    }}
                  disabled={true}
                    data-testid='input-patient-name-disabled'
                />
              </div>
            )}

            <div className="w-3" />
            <div className="w-full lg:w-[358px]" data-testid='patient-dob-container'>
              <span className="text-black text-[18px] font-[500]" data-testid='patient-dob-title'>
                Date of Birth
              </span>
              <input
                placeholder="Enter date of birth"
                type="text"
                name="date"
                value={isLoadingPatient ? "loading" : dateOfBirth ?? ""}
                className=" w-full p-2 h-[60px] border border-solid rounded outline-none"
                onChange={(e) => {
                  setFieldValue("dateOfBirth", e.target.value);
                }}
                disabled={true}
                data-testid='input-patient-dob-disabled'
              />
            </div>
            <div className="w-3" />
            <div className="w-full lg:w-[358px]">
              <span className="text-black text-[18px] font-[500]" data-testid='gender'>
                Gender
              </span>
              <input
                placeholder="Enter gender"
                type="text"
                name="gender"
                value={isLoadingPatient ? "loading" : gender || ""}
                className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                onChange={(e) => {
                  setFieldValue("gender", e.target.value);
                }}
                disabled={true}
                data-testid='input-gender-disabled'
              />
            </div>
          </div>
          <div className="h-8" />
          <div className="flex flex-wrap lg:flex-nowrap items-center">
            <div className="w-full lg:w-[358px]">
              <span className="text-black text-[18px] font-[500]" data-testid='primary-insurance'>
                Primary Insurance
              </span>
              <input
                placeholder="Enter primary insurance"
                type="text"
                name="primaryInsurance"
                value={isLoadingPatient ? "loading" : patientInsurance ?? ""}
                className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                onChange={(e) => {
                  setFieldValue("patientInsurance", e.target.value);
                }}
                disabled={true}
                data-testid='input-primary-insurance-disabled'
              />
            </div>
            <div className="w-3" />
          </div>
        </div>
        <Divider />
        <div className="w-full my-4">
          <p className="text-[#324705] text-[24px] font-[500]" data-testid='visit'>
            Visit/Provider Information
          </p>
          <div className="h-6" />
          <div className="flex flex-wrap lg:flex-nowrap items-center">
            <div className="w-full lg:w-[358px]">
              <span className="text-black text-[18px] font-[500]" data-testid='visited-provider-name'>
                Provider Name
              </span>
              <input
                placeholder="Name of Provider"
                type="text"
                name="providerName"
                value={providerName ?? ""}
                className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                onChange={(e) => {
                  setFieldValue("providerName", e.target.value);
                }}
                disabled={true}
                data-testid='input-provider-name-disabled'
              />
            </div>
            <div className="w-3" />
            <div className="w-full lg:w-[358px]">
              <label
                htmlFor="paymentAndBillingDate"
                className="text-black text-[18px] font-[500]"
                data-testid='date-of-appointment'
              >
                Date of Appointment
              </label>
              {
                // isFromDetailView ? (
                <input
                  type="date"
                  className="w-[100%] h-[60px] py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                  name="paymentAndBillingDate"
                  value={dateOfEncounter}
                  onChange={(e) => {
                    setFieldValue("dateOfEncounter", e.target.value);
                  }}
                  disabled={status === "processing"}
                  data-testid='input-date-of-appointment-disabled'
                />
                //   ) : (
                //     <select
                //       className="w-[100%] h-[60px] py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                //       name="paymentAndBillingDate"
                //       value={selectedAppointment}
                //       onChange={handleLastAppointmentChange}
                //     >
                //       <option value="">Select</option>
                //       {completedAppointments?.completedAppointments?.map(
                //         ({ id, appointmentDate, appointmentId, appointmentType }) => {
                //           const data = {
                //             appointmentDate,
                //             appointmentId,
                //             appointmentType
                //           };
                //           return (
                //             <option value={JSON.stringify(data)} key={id}>
                //               {isLoading ? "...loading" : formatChargeSheetDate(appointmentDate)}
                //             </option>
                //           );
                //         }
                //       )}
                //     </select>
                //   )
              }

            </div>
            <div className="w-3" />
            <div className="w-full lg:w-[358px]">
              <span className="text-black text-[18px] font-[500]" data-testid='location-of-appointment'>
                Location of Appointment
              </span>
              {/* {initialValues?.locationOfEncounter === null ? (
                "virtual"
              ) : ( */}
                <input
                  placeholder="Enter meeting location"
                  type="text"
                    name="locationOfEncounter"
                  value={locationOfEncounter}
                  className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={(e) => {
                      setFieldValue("locationOfEncounter", e.target.value);
                    }}
                data-testid='input-location-of-appointment'
                />
              {/* )} */}
            </div>
          </div>
          <div className="h-2" />
          <div className="form-item-text" data-testid='mode-of-appointment'> Mode of Appointment</div>
          <div className="flex flex-row items-center justify-start">
            <div className="form-item-data" data-testid='input-mode-of-appointment'>
              <RadioField
                name="modeOfEncounter"
                placeholder="Mode of Encounter"
                display="block"
                options={[
                  { item: "Physical", value: "physical" },
                  { item: "Virtual", value: "virtual" }
                ]}
                isRequired={true}
                value={modeOfEncounter ?? ""}
                onChange={(e) => {
                  setFieldValue("modeOfEncounter", e.target.value);
                }}
                disabled={status === "processing"}

              />
            </div>
          </div>
        </div>
        <Divider />
        <div className="w-full my-2" data-testid='procedure-code-container'>
          <p className="text-[#324705] text-[24px] font-[500]" data-testid='procedure-code-title'>
            Procedure Code(s)
          </p>
          <div className="w-full">
            <p className="text-[18px] text-[#324705] font-[500] my-2 w-full block lg:hidden" data-testid='procedure-code-subtitle'>
              Procedure code(s)
            </p>
            <div className="w-full flex flex-col my-3 lg:my-0 lg:flex-nowrap items-center justify-start" data-testid='procedure-code-container'>
              {Array.isArray(procedureCodes) ? (
                procedureCodes.map((text, index) => (
                  <Fragment key={index}>
                    <div className="w-full p-2 flex justify-start items-center">
                      <div className="w-full lg:w-[358px]" data-testid='procedure-code-input-container' >
                        <TextField
                          name="procedureCodes"
                          type="text"
                          placeholder="Enter procedure code"
                          value={text ?? ""}
                          onChange={(e) => {
                            const newCodes = [...procedureCodes];
                            newCodes[index] = e.target.value;
                            setFieldValue("procedureCodes", newCodes);
                          }}
                          label={`Procedure code ${index + 1}`}
                          disabled={status === "processing"}
                        />
                      </div>
                      <div
                        className="flex justify-start px-4 mt-4"
                        onClick={() => {
                          if (Array.isArray(procedureCodes) && procedureCodes.length > 1) {
                            const newCodes = [...procedureCodes];
                            newCodes.splice(index, 1);
                            setFieldValue('procedureCodes', newCodes);
                          }
                        }}
                        data-testid='delete-procedure-code'
                      >
                        {index > 0 && (
                          <DeleteIcon className="cursor-pointer" />
                        )}
                      </div>
                    </div>
                  </Fragment>
                ))
              ) : (
                <div className="w-full p-2 flex justify-start items-center">
                    <div className="w-full lg:w-[358px]" data-testid='input-procedure-code-subcontainer'
                    >
                    <TextField
                      name="procedureCodes"
                      type="text"
                      placeholder="Enter procedure code"
                      value={procedureCodes ?? ""}
                      onChange={(e) => {
                        setFieldValue("procedureCodes", e.target.value);
                      }}
                      label="Procedure code"
                        disabled={status === "processing"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-start items-start">
            <div className="w-[122px] lg:mr-5">
              <Button
                type="button"
                variant="secondary"
                label="Verify code"
                size="small"
                loading={isFetchingProCode}
                onClick={refetchProcedureCode}
                disabled={isFetchingProCode || (Array.isArray(procedureCodes) ? procedureCodes.length === 0 : !procedureCodes) || status === "processing"}
              />
            </div>
            {isFetchingProCode ? (
              "...verifying"
            ) : (
                <div className="text-sm shadow-sm w-72 border flex flex-col justify-evenly 
                items-start mt-4">{(procedureData || [])?.map(
                  ({ code, title }: DataSourceType) => (
                    <div key={code} className="text-sm">
                      <p className="text-sm">Code: {code}</p>
                      <p className="text-sm">Name: {title}</p>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          <div className="flex flex-row pb-4  mt-4">
            <span
              className="cursor-pointer flex items-center"
              onClick={handleAddProcedureCode}
              data-testid='add-procedure-code'

            >
              <PlusIcon />
              <span className="text-[#1A9D39] text-[16px] ml-3">
                Add procedure code
              </span>
            </span>
          </div>
        </div>
        <Divider />
        <div className="w-full my-2">
          <p className="text-[#324705] text-[24px] font-[500]" data-testid='diagnosis-code-title'>
            Diagnosis Code(s)
          </p>
          <div className="w-full">
            <p className="text-[18px] text-[#324705] font-[500] my-2 w-full block lg:hidden" data-testid='diagnosis-code-subtitle'>
              Diagnosis code(s)
            </p>
            <div className="w-full flex flex-col my-3 lg:my-0 lg:flex-nowrap items-center justify-start" data-testid='diagnosis-code-container'>
              {Array.isArray(diagnosisCodes) ? (
                diagnosisCodes.map((text, index) => (
                  <Fragment key={`diagnosisCode-${index}`}>
                    <div className="w-full p-2 flex justify-start items-center">
                      <div className="w-full lg:w-[358px]" data-testid='input-diagnosis-code-container'>
                        <TextField
                          name="diagnosisCodes"
                          type="text"
                          placeholder="Enter Diagnosis Code"
                          value={text || ""}
                          onChange={(e) => {
                            const newCodes = [...diagnosisCodes];
                            newCodes[index] = e.target.value;
                            setFieldValue("diagnosisCodes", newCodes);
                          }}
                          label={`Diagnosis code ${index + 1}`}
                          disabled={status === "processing"}
                        />
                      </div>
                      <div
                        className="flex justify-start px-4 mt-4"
                        onClick={() => {
                          if (Array.isArray(diagnosisCodes) && diagnosisCodes.length > 1) {
                            const newCodes = [...diagnosisCodes];
                            newCodes.splice(index, 1);
                            setFieldValue('diagnosisCodes', newCodes);
                          }
                        }}
                        data-testid='delete-diagnosis-code'
                      >
                        {index > 0 && (
                          <DeleteIcon className="cursor-pointer" />
                        )}
                      </div>
                    </div>
                  </Fragment>
                ))
              ) : (
                  <div className="w-full p-2 flex justify-start items-center" data-testid='procedure-code-container'>
                    <div className="w-full lg:w-[358px]" data-testid='input-diagnosis-code-subcontainer'>
                    <TextField
                      name="diagnosisCodes"
                      type="text"
                      placeholder="Enter Diagnosis Code"
                      value={diagnosisCodes || ""}
                      onChange={(e) => {
                        setFieldValue("diagnosisCodes", e.target.value);
                      }}
                      label="Diagnosis code"
                        disabled={status === "processing"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-start items-start">
            <div className="w-[122px] lg:mr-5">
              <Button
                type="button"
                variant="secondary"
                label="Verify code"
                size="small"
                loading={isFetchingDiagCode}
                onClick={refetchDiagnosisCode}
                disabled={isFetchingDiagCode || (Array.isArray(diagnosisCodes) ? diagnosisCodes.length === 0 : false) || status === "processing"}
              />
            </div>
            {isFetchingDiagCode ? (
              "...verifying"
            ) : (
              <div className="text-sm shadow-sm w-72 border flex flex-col justify-evenly items-start mt-4">
                {(diagnosisData || [])?.map(
                  ({ code, title }: DataSourceType) => (
                    <div key={code} className="text-sm">
                      <p className="text-sm">Code: {code}</p>
                      <p className="text-sm">Name: {title}</p>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          <div className="flex flex-row pb-4  mt-4" >
            <span
              className="cursor-pointer flex items-center"
              onClick={handleAddDiagnosisCode}
              data-testid='add-diagnosis-code'
            >
              <PlusIcon />
              <span className="text-[#1A9D39] text-[16px] ml-3">
                Add diagnosis code
              </span>
            </span>
          </div>
        </div>
        <div className="relative h-[96px] bg-white border-t border-[#ddd] bottom-0 flex justify-end items-center">
          <div className="absolute bottom-0 right-0 flex justify-center items-center gap-5 p-4">
            {isFromNewBilling ? (
              <>
                {
                  status !== "processing" && (
                    <>
                      <div className="mr-3 lg:mr-5" data-testid='from-new-billing-save-draft-container'>
                        <Button
                          type="submit"
                          variant="secondary"
                          label="Save draft"
                          size="medium"
                          loading={isSavingDraft}
                          disabled={status === "draft"}
                          onClick={() => {

                            if (!validateCodes()) return;

                            saveAction({
                              patientId: patientId ? patientId : patientIdFromProps,
                              appointmentId: appointmentId ? appointmentId : completedAppointments?.completedAppointments[0]?.appointmentId,
                              procedureCodes,
                              diagnosisCodes,
                              status: "draft",
                              modeOfEncounter,
                              locationOfEncounter,
                              dateOfEncounter
                            });
                            setFieldValue('diagnosisCodes', "");
                            setFieldValue('procedureCodes', "");
                            if (setIsDetailsView && setIsNewBillingFormVisible && setIsDetailsForStatusPaid && setIsFromNewBillingTable) {
                              setIsDetailsView(false);
                              setIsNewBillingFormVisible(false);
                              setIsDetailsForStatusPaid(false);
                              setIsFromNewBillingTable(false);
                            }
                          }}
                        />
                      </div>
                      <div className="w-full lg:w-[122px] mr-3 lg:mr-5" data-testid='from-new-b-send-billing-container'>
                        <Button
                          additionalClassname="w-full sm:w-1/2 lg:w-1/3"
                          type="submit"
                          variant="primary"
                          label="Send to billing"
                          size="medium"
                          loading={isSendingToBilling}
                          onClick={() => {
                            sendToBillingAction({
                              patientId: patientId ? patientId : patientIdFromProps,
                              appointmentId: appointmentId ? appointmentId : completedAppointments?.completedAppointments[0]?.appointmentId,
                              procedureCodes,
                              diagnosisCodes,
                              status: "processing",
                              modeOfEncounter,
                              locationOfEncounter,
                              dateOfEncounter
                            });
                            setFieldValue('diagnosisCodes', "");
                            setFieldValue('procedureCodes', "");
                            if (setIsDetailsView && setIsNewBillingFormVisible && setIsDetailsForStatusPaid && setIsFromNewBillingTable) {
                              setIsDetailsView(false);
                              setIsNewBillingFormVisible(false);
                              setIsDetailsForStatusPaid(false);
                              setIsFromNewBillingTable(false);
                            }
                          }}
                        />
                      </div>
                    </>
                  )
                }
              </>
            ) : (
              <div className="mr-3 lg:mr-5">
                  <>
                    {status !== "processing" && (
                      <div data-testid='from-new-billing-Update-container'>
                      <Button
                        additionalClassname="w-full sm:w-1/2 lg:w-1/3"
                        type="submit"
                        variant="primary"
                        label="Update for billing"
                        size="medium"
                        loading={isUpdatingChargeSheet}
                        // disabled={status === "processing"}
                        onClick={() => {
                          updateChargesheet({
                            chargeId: chargeSheetData?.chargeId as string,
                            procedureCodes,
                            diagnosisCodes,
                            status: "processing",
                            modeOfEncounter,
                            locationOfEncounter,
                            dateOfEncounter
                          });
                          if (setIsDetailsView && setIsNewBillingFormVisible && setIsDetailsForStatusPaid && setIsFromNewBillingTable) {
                            setIsDetailsView(false);
                            setIsNewBillingFormVisible(false);
                            setIsDetailsForStatusPaid(false);
                            setIsFromNewBillingTable(false);
                          }
                        }}
                      />
                      </div>
                    )}
                  </>
              </div>
            )}
          </div>
        </div>

      </div>
    </div>
  );
};

export default ChargeSheet;