import { Divider } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useProviderAuthState } from '../../api/hooks/provider/auth/useProviderAuthState';
import { useSubscriptionPayment } from "../../api/hooks/provider/SubsPayment";
import { useUpdateProviderDetails } from '../../api/hooks/provider/updateProviderDetails';
import { CheckOne } from "../../svgs/CheckOne";

function SubscriptionPricing() {
  const urlLocation = useLocation();

  const { user } = useProviderAuthState();
  const { initiateSubsPayment } = useSubscriptionPayment()
  const { updateProviderProfileDetails } = useUpdateProviderDetails()

  const handleBuyPlan = ({
    plan,
    customerId,
    cancel_url,
  }: {
    plan: string;
    customerId: string;
    cancel_url: string;
  }) => {
    initiateSubsPayment({ plan, customerId, cancel_url })
  };
  return (
    <div className="container mx-auto px-4 h-screen">
      <div className="space-y-8">
        <div className="space-y-8">
          <div className="grid gap-6 lg:grid-cols-3">
            {/* Basic Plan */}
            <div className="rounded-lg shadow-lg border py-5 px-5">
              <div className="w-full md:w-[260.77px] h-[560.08px]">
                <div >
                  <div className="w-[65.5px] h-[30.08px]  rounded-[5.54px] bg-[#EEEEE9] text-xs font-semibold uppercase flex justify-center items-center leading-[27.7px] text-[#2E3011] tracking-tighter font-generalSans" data-testid="plan-title">
                    Basic
                  </div>
                  <div className="w-[140px] h-[98px]">
                    <h3 className="font-semibold text-[#34623F] leading-[73.12px] text-[50px]" data-testid='plan-price'>Free</h3>
                    <div className="text-sm text-[#2E3011] font-semibold] opacity-50" data-testid='plan-duration'>Forever</div>
                  </div>
                  <div className="py-4">
                    <Divider sx={{
                      mr: 10
                    }} />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div className="grid gap-2 pb-4">
                    {[
                      "Practice Listing",
                      "Scheduling",
                      "Messaging",
                      "Telehealth",
                      "Intake Forms",
                      "Consent Forms",
                      "Reminders",
                      "Collection of payments",
                      "Access to templates",
                      "Notes",
                      "Lab Orders",
                      "Electronic Prescription (EPCS)",
                    ].map((feature, index) => (
                      <div key={feature}
                        className={`flex items-center gap-2 text-sm ${index >= 3 ? " opacity-10" : "text-muted-foreground"
                          }`}>
                        <CheckOne className="h-4 w-4 text-[#2B572B]" />
                        {feature}
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  {
                    user?.subscriptionPlan === 'basic' ? (
                      <button className="text-[#34623F] bg-[#F4FEF6] w-full rounded-[40px] gap-1 h-[39px] hover:opacity-70 cursor-none" data-testid='current-plan' disabled={true}>
                        Current Plan
                      </button>
                    )
                      : (
                        <button
                          onClick={() => updateProviderProfileDetails({
                            subscriptionPlan: 'basic',
                          })}
                          className="text-white gap-1 rounded-[40px] bg-[#34623F] w-full h-[39px] hover:bg-[#1F3F1F] cursor-pointer"
                          data-testid='buy-plan'
                        >
                          Buy Basic
                        </button>
                      )
                  }
                </div>
              </div>
            </div>

            {/* Standard Plan */}
            <div className="rounded-lg shadow-lg border py-5 px-5">
              <div className="w-full md:w-[260.77px] h-[560.08px]">
                <div >
                  <div className="w-[110.5px] h-[30.08px]  rounded-[5.54px] bg-[#EEEEE9] text-xs font-semibold uppercase flex justify-center items-center leading-[27.7px] text-[#2E3011] tracking-tighter font-generalSans" data-testid="standard-plan-title">
                    Standard
                  </div>
                  <div className="w-[140px] h-[98px]">
                    <h3 className="font-semibold text-[#34623F] leading-[73.12px] text-[50px]" data-testid='standard-plan-price'>$99</h3>
                    <div className="text-sm text-[#2E3011] font-semibold] opacity-50" data-testid='standard-plan-per-month'>Per Month</div>
                  </div>
                  <div className="py-4">
                    <Divider sx={{
                      mr: 10

                    }} />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div className="grid gap-2 pb-4">
                    {[
                      "Practice Listing",
                      "Scheduling",
                      "Messaging",
                      "Telehealth",
                      "Intake Forms",
                      "Consent Forms",
                      "Reminders",
                      "Collection of payments",
                      "Access to templates",
                      "Notes",
                      "Lab Orders",
                      "Electronic Prescription (EPCS)",
                    ].map((feature) => (
                      <div key={feature} className="flex items-center gap-2 text-sm text-muted-foreground">
                        <CheckOne className="h-4 w-4 text-[#2B572B]" />
                        {feature}
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  {
                    user?.subscriptionPlan === 'standard' ? (
                      <button className="text-[#34623F] bg-[#F4FEF6] w-full border rounded-[40px] gap-1 h-[39px] hover:opacity-70 cursor-none" data-testid='standard-current-plan'>
                        Current Plan
                      </button>
                    ) : (
                      <button
                        onClick={() => handleBuyPlan({
                          plan: process.env.REACT_APP_STANDARD_PLAN,
                          customerId: user?.stripeCustomerId as string,
                          cancel_url: urlLocation.pathname,
                        })}
                          className="text-white gap-1 rounded-[40px] bg-[#34623F] w-full h-[39px] hover:bg-[#1F3F1F] cursor-pointer"
                        data-testid='standard-buy-plan'
                      >
                        Buy Standard
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
            {/* Pro Plan */}
            <div className="rounded-lg shadow-lg border py-5 px-5">
              <div className="w-full md:w-[260.77px] h-[560.08px]">
                <div >
                  <div className="w-[52.7px] h-[30.08px] rounded-[5.54px] bg-[#EEEEE9] text-xs font-semibold uppercase flex justify-center items-center leading-[27.7px] text-[#2E3011] tracking-tighter font-generalSans" data-testid='pro-plan-title'>
                    Pro
                  </div>
                  <div className="w-[140px] h-[98px]">
                    <h3 className="font-semibold text-[#34623F] leading-[73.12px] text-[50px]" data-testid='pro-plan-price'>$145</h3>
                    <div className="text-sm text-[#2E3011] font-semibold] opacity-50" data-testid='pro-plan-per-month'>Per Month</div>
                  </div>
                  <div className="py-4">
                    <Divider sx={{
                      mr: 10

                    }} />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div className="grid gap-2 pb-4">
                    {[
                      "Practice Listing",
                      "Scheduling",
                      "Messaging",
                      "Telehealth",
                      "Intake Forms",
                      "Consent Forms",
                      "Reminders",
                      "Collection of payments",
                      "Access to templates",
                      "Notes",
                      "Lab Orders",
                      "Electronic Prescription (EPCS)",
                    ].map((feature) => (
                      <div key={feature} className="flex items-center gap-2 text-sm text-muted-foreground">
                        <CheckOne className="h-4 w-4 text-[#2B572B]" />
                        {feature}
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  {
                    user?.subscriptionPlan === 'pro' ? (
                      <button className="text-[#34623F] bg-[#F4FEF6] w-full rounded-[40px] gap-1 h-[39px] hover:opacity-70 cursor-none" data-testid='pro-current-plan'>
                        Current Plan
                      </button>
                    ) : (
                      <button
                        onClick={() => handleBuyPlan({
                          plan: process.env.REACT_APP_PRO_PLAN,
                          customerId: user?.stripeCustomerId as string,
                          cancel_url: urlLocation.pathname,
                        })}
                          className="text-white gap-1 rounded-[40px] bg-[#34623F] w-full h-[39px] hover:bg-[#1F3F1F] cursor-pointer"
                        data-testid='pro-buy-plan'
                      >
                        Buy Pro
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="flex  gap-4">
            {/* Elite Plan */}
            <div className="rounded-lg shadow-lg border py-5 px-5">
              <div className="w-full md:w-[260.77px] h-[560.08px]">
                <div >
                  <div className="w-[59.7px] h-[30.08px] rounded-[5.54px] bg-[#EEEEE9] text-xs font-semibold uppercase flex justify-center items-center leading-[27.7px] text-[#2E3011] tracking-tighter font-generalSans" data-testid="elite-plan-title">
                    Elite
                  </div>
                  <div className="w-[140px] h-[98px]">
                    <h3 className="font-semibold text-[#34623F] leading-[73.12px] text-[50px]" data-testid='elite-plan-price'>$199</h3>
                    <div className="text-sm text-[#2E3011] font-semibold] opacity-50" data-testid='elite-plan-per-month'>Per Month</div>
                  </div>
                  <div className="py-4">
                    <Divider sx={{
                      mr: 10
                    }} />
                  </div>
                </div>
                <div className="grid gap-4">
                  <div className="grid gap-2 pb-4">
                    {[
                      "Practice Listing",
                      "Scheduling",
                      "Messaging",
                      "Telehealth",
                      "Intake Forms",
                      "Consent Forms",
                      "Reminders",
                      "Collection of payments",
                      "Access to templates",
                      "Notes",
                      "Lab Orders",
                      "Electronic Prescription (EPCS)",
                    ].map((feature) => (
                      <div key={feature} className="flex items-center gap-2 text-sm text-muted-foreground">
                        <CheckOne className="h-4 w-4 text-[#2B572B]" />
                        {feature}
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  {
                    user?.subscriptionPlan === 'elite' ? (
                      <button className="text-[#34623F] bg-[#F4FEF6] w-full rounded-[40px] gap-1 h-[39px] hover:opacity-70 cursor-none" data-testid='elite-current-plan'>
                        Current Plan
                      </button>
                    ) : (
                      <button
                        onClick={() => handleBuyPlan({
                          plan: process.env.REACT_APP_ELITE_PLAN,
                          customerId: user?.stripeCustomerId as string,
                          cancel_url: urlLocation.pathname,

                        })}
                          className="text-white gap-1 rounded-[40px] bg-[#34623F] w-full h-[39px] hover:bg-[#1F3F1F] cursor-pointer"
                        data-testid='elite-buy-plan'
                      >
                        Buy Elite
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
            {/* Add-ons Section */}
            <div className="space-y-4 w-[768px] h-full md:h-[270px]  rounded-lg shadow-lg border py-5 px-5">
              <h2 className="text-xl font-semibold px-5">Add-ons</h2>
              <div className="flex sm:flex-col gap-4" >
                <div className="flex flex-col items-start justify-start p-6">
                  <div className="space-y-1">
                    <h3 className="font-medium" data-testid='healthscribe-plan-title'>HealthScribe (Pay as you use)</h3>
                    <p className="text-sm text-muted-foreground" data-testid='healthscribe-plan-description'>
                      Automatically create clinical notes from patient-clinician conversations using generative AI
                    </p>
                  </div>
                  <div className="flex self-end mt-10">
                    <button className="bg-[#34623F] hover:bg-[#1F3F1F] w-[202px] h-[42px] rounded-[40px] text-white flex justify-center items-center" data-testid='healthscribe-plan-button'>Explore AI</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionPricing

