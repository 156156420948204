import { format } from "date-fns";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SendMessageButton from "../../../components/shared/BtnButton";
import { AllDocumentsProps, formTitleMapper } from "./interfaces";
import { PatientDetailsType } from "./interfaces/patientDetails";
import { itemValues } from './interfaces/patient_overview';

interface OverviewProps {
  activePatient: PatientDetailsType | undefined;
  diagnosis: string;
  setActiveTabLabel: any;
  allDocumentation: AllDocumentsProps;
}

const Overview = ({
  activePatient,
  diagnosis,
  setActiveTabLabel,
  allDocumentation,
}: OverviewProps) => {
  const navigate = useNavigate();
  const pathnames = window.location.pathname.split("/");
  const userId = pathnames[pathnames.length - 2];

  const sortedActivePatient = useMemo<PatientDetailsType | undefined>(
    () =>
      activePatient?.patientDetails?.medicalRecord &&
        activePatient?.patientDetails?.medicalRecord
        ? {
          ...activePatient,
          medicalRecord: activePatient?.patientDetails?.medicalRecord?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
        }
        : activePatient,
    [activePatient]
  );

  const recentNotes = allDocumentation
    ?.sort((a: any, b: any) => a?.created_at - b?.created_at)
    .slice(0, 3);

  return (
    <div className="w-[100%] h-auto" data-testid='overview'>
      <div className="flex flex-row flex-wrap lg:flex-nowrap">
        <div className="lg:w-[40%] w-full lg:mr-[2%]">
          <div className="relative w-[100%] shadow-overview rounded-[4px]">
            <div className="w-[100%] flex flex-row items-center  border-b-2 border-[#EEEEE9]-500 p-4">
              <img
                className="relative w-[60px] h-[60px] rounded-full mr-[5%]"
                src={
                  activePatient?.patientDetails?.patient?.avatarUrl ||
                  "/assets/images/avatar.png"
                }
                alt="patient profile"
                data-testid='img'
              />
              <div data-testid='user'>
                <p className="text-[#103C1B] text-sm 1100:text-xl font-medium" data-testid='user-name'>
                  {activePatient?.patientDetails?.patient?.firstName}{" "}
                  {activePatient?.patientDetails?.patient?.lastName}
                </p>
                <p className="text-[13px] text-[#103C1B] font-medium mt-2" data-testid='user-diagnosis'>
                  <span className="text-[#87928A]  mr-[3px]">Diagnosis:</span>
                  <span>{diagnosis}</span>
                </p>
              </div>
            </div>
            <div className="my-6">
              <div className="flex flex-wrap lg:flex-nowrap gap-6 px-[20px] text-sm lg:grid lg:grid-cols-2">
                {itemValues.map((value, i) => {
                  const Icon = value.icon;
                  const Component = () => <Icon />
                  return (
                    <div key={i} className="w-full min-[821]:max-w-1/2">
                      <div className="flex flex-wrap xl:flex-nowrap items-center">
                        {value.hasIcon && (
                          <div className="mr-[5px]">
                            <Component />
                          </div>
                        )}
                        <div>
                          <p className="text-[#87928A] font-medium">
                            {value.keyTitle}
                          </p>
                        </div>
                      </div>
                      <div className="w-[100%] text-[#324705] font-medium break-words mt-2">
                        <p>
                          {sortedActivePatient && value?.fn(sortedActivePatient?.patientDetails)}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full lg:w-1/2 mt-[6px] pb-5 px-[12px]">
              <SendMessageButton
                type="button"
                variant="primary"
                label="Send message"
                onClick={() => navigate(`/provider/messages/${userId}`)}
                additionalClassname={"h-[50px]"}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-[55%] w-full">
          <div className="w-[100%] lg:grid lg:grid-cols-2 lg:gap-[4%]">
            <div className="h-[auto] relative w-[100%] shadow-overview  rounded-[4px]">
              <div className="pt-[17px] pl-[17px] pb-2 border-b-2 border-[#EEEEE9]-500">
                <p className="text-[#103C1B] text-sm lg:text-xl font-medium" data-testid='allergies'>
                  Allergies
                </p>
              </div>
              <div className="px-5 py-4 text-[#324705]">
                {!activePatient?.patientDetails?.medicalRecord[0]?.allergies ? (
                  <p data-testid='no-allergies'>No allergies</p>
                ) : (
                    ([activePatient?.patientDetails?.medicalRecord[0]?.allergies])?.map(
                    (v: any, i: any) => (
                      <p key={i} className="text-base mb-6">
                        {v.allergy}
                      </p>
                    )
                  )
                )}
              </div>
            </div>
            <div className="h-[auto] relative w-[100%] shadow-overview rounded-[4px]">
              <div className="pt-[17px] pl-[17px] pb-2 border-b-2 border-[#EEEEE9]-500">
                <p className="text-[#103C1B] text-sm min-[1100px]:text-xl font-medium" data-testid='prescriptions'>
                  Current Prescription
                </p>
              </div>
              <div className="px-5 py-4 text-[#324705]">
                {!activePatient?.patientDetails?.prescriptions?.length ? (
                  <p data-testid='no-prescription'>No Prescription</p>
                ) : (
                    activePatient?.patientDetails?.prescriptions
                    .slice(
                      activePatient?.patientDetails?.prescriptions.length > 5
                        ? activePatient?.patientDetails?.prescriptions?.length - 5
                        : 0
                    )
                    .map((v, i) => (
                      <p key={i} className="text-base mb-6">
                        {v.drug}
                      </p>
                    ))
                )}
              </div>
            </div>
            <div className="h-[auto] relative w-[100%] shadow-overview rounded-[4px]">
              <div className="pt-[17px] px-[17px] pb-2 border-b-2 border-[#EEEEE9]-500">
                <div className="flex flex-row items-center justify-between w-[100%]">
                  <p className="text-[#103C1B] text-sm min-[1100px]:text-xl font-medium" data-testid='notes'>
                    Notes
                  </p>
                  <div
                    className="text-sm min-[1100px]:text-base font-medium  cursor-pointer text-[#3D874E]"
                    onClick={() => {
                      setActiveTabLabel("Documentation");
                      navigate(`/provider/patients/${userId}/details`, {
                        state: {
                          activeTab: "documentation"
                        }
                      });
                    }}
                    data-testid='view-all-notes'
                  >
                    View all
                  </div>
                </div>
              </div>
              <div className="px-5 py-4 text-[#324705]" data-testid='recent-notes'>
                {recentNotes?.length ? (
                  recentNotes?.map((note: any, index: number) => (
                    <p key={index} className="flex justify-between py-2">
                      <span>
                        {format(new Date(note?.created_at), "MM/dd/yyyy")}
                      </span>
                      <span>{formTitleMapper[note?.noteType]}</span>
                    </p>
                  ))
                ) : (
                    <p data-testid='no-recent-notes'>No recent notes</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
