import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useProviderAuthState } from "../../../api/hooks/provider/auth/useProviderAuthState";
import { useCreateForm } from "../../../api/hooks/provider/useCreateForm";
import { useGetProviderForm } from "../../../api/hooks/provider/useGetProviderForm";
import { FormFields } from "../../../components/form/FormBuilder";
import Button from "../../../components/shared/Button";
import { Loading } from "../../../components/shared/Loading";
import { generateUUID } from "../../../utils/helpers";
import FormManager from "../../components/settings/FormManager";

export type FormData = {
  id: string;
  sectionName: string;
  questionOptions: {
    id: string;
    title: string;
    fieldType: string;
    showOptions?: boolean;
    options?: string[];
    required: boolean;
  }[];
};

export type FormTypes =
  | "intakeForm"
  | "initialVisitNote"
  | "progressNote"
  | "consentForm";

export const formNames: { [key in FormTypes]: string } = {
  intakeForm: "Intake Form",
  initialVisitNote: "Initial Visit Note",
  progressNote: "Progress Note",
  consentForm: "Consent Form"
};

const formTypes: FormTypes[] = [
  "consentForm",
  "initialVisitNote",
  "intakeForm",
  "progressNote"
];

const Templates = () => {
  const { user: provider } = useProviderAuthState()
  const search = useSearchParams()[0];
  const navigate = useNavigate();
  const activeTab = search.get("activeNote") as FormTypes | undefined;
  const referrer = search.get("referrer");
  const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
  const [isFormEditMode, setIsFormEditMode] = useState<boolean>(false);
  const [initialForms, setInitialForm] = useState<{
    [key in FormTypes]: FormFields[];
  }>({
    intakeForm: [],
    initialVisitNote: [],
    progressNote: [],
    consentForm: []
  });

  const [isSavingTemplate, setIsSavingTemplate] = useState<boolean>(false);

  const { providerFormData } = useGetProviderForm({
    formType: formTypes,
    providerId: provider?.userId ? provider?.userId : "",
  })

  const { data, isFetched, isError, error } = providerFormData[0]
  const { data: data2, isFetched: isFetched2, isError: isError2, error: error2 } = providerFormData[1]
  const { data: data3, isFetched: isFetched3, isError: isError3, error: error3 } = providerFormData[2]
  const { data: data4, isFetched: isFetched4, isError: isError4, error: error4 } = providerFormData[3]

  if (isError && axios.isAxiosError(error)) {
    toast.error(data?.error?.message, { toastId: "customId" })
  } else if (data?.error !== null) {
    toast.error(data?.error, { toastId: "customId" })
  } else {
    toast.error(data?.error?.message || 'Unknown error occured while fetching consentForm, refresh page', { toastId: "customId" })
  }

  if (isError2 && axios.isAxiosError(error2)) {
    toast.error(data2?.error?.message, { toastId: "customId" })
  } else if (data2?.error !== null) {
    toast.error(data2?.error, { toastId: "customId" })
  } else {
    toast.error(data2?.error?.message || 'Unknown error occured while fetching initialVisitNote, refresh page', { toastId: "customId" })
  }
  if (isError3 && axios.isAxiosError(error3)) {
    toast.error(data3?.error?.message, { toastId: "customId" })
  } else if (data3?.error !== null) {
    toast.error(data3?.error, { toastId: "customId" })
  } else {
    toast.error(data3?.error?.message || 'Unknown error occured while fetching intakeForm, refresh page', { toastId: "customId" })
  }
  if (isError4 && axios.isAxiosError(error4)) {
    toast.error(data4?.error?.message, { toastId: "customId" })
  } else if (data4?.error !== null) {
    toast.error(data4?.error, { toastId: "customId" })
  } else {
    toast.error(data4?.error?.message || 'Unknown error occured while fetching progressNote, refresh page', { toastId: "customId" })
  }

  useEffect(() => {
    if (isFetched && isFetched2 && isFetched3 && isFetched4) {
      setInitialForm({
        consentForm: data?.consentForm && data?.consentForm?.noteFields,
        initialVisitNote: data2?.initialVisitNote && data2?.initialVisitNote?.noteFields,
        intakeForm: data3?.intakeForm && data3?.intakeForm?.noteFields,
        progressNote: data4?.progressNote && data4?.progressNote?.noteFields,
      });
    }

  }, [data?.form?.noteFields, data?.form?.noteType, data2?.form?.noteFields, data2?.form?.noteType, data3?.form?.noteFields, data3?.form?.noteType, data4?.form?.noteFields, data4?.form?.noteType, isFetched, isFetched2, isFetched3, isFetched4, data?.consentForm, data2?.initialVisitNote, data3?.intakeForm, data4?.progressNote]);

  const genericFormSections: FormData = {
    id: generateUUID(),
    sectionName: "Section 1",
    questionOptions: [
      {
        id: generateUUID(),
        title: "",
        fieldType: "text",
        required: false
      }
    ]
  };

  const [intakeFormSections, setIntakeFormSections] = useState<FormData[]>([
    JSON.parse(JSON.stringify(genericFormSections))
  ]);

  const [initialVisitNoteSections, setInitialVisitNoteSections] = useState<
    FormData[]
  >([JSON.parse(JSON.stringify(genericFormSections))]);

  const [progressNoteSections, setProgressNoteSections] = useState<FormData[]>([
    JSON.parse(JSON.stringify(genericFormSections))
  ]);

  const [consentFormSections, setConsentFormSections] = useState<FormData[]>([
    JSON.parse(JSON.stringify(genericFormSections))
  ]);

  const formsAndSections: {
    [key in FormTypes]: {
      fields: FormData[];
      settings: React.Dispatch<React.SetStateAction<FormData[]>>;
    };
  } = {
    intakeForm: {
      fields: intakeFormSections,
      settings: setIntakeFormSections
    },
    initialVisitNote: {
      fields: initialVisitNoteSections,
      settings: setInitialVisitNoteSections
    },
    progressNote: {
      fields: progressNoteSections,
      settings: setProgressNoteSections
    },
    consentForm: {
      fields: consentFormSections,
      settings: setConsentFormSections
    }
  };

  const saveTemplate = async () => {
    setIsSavingTemplate(true);
    if (activeTab && formTypes.includes(activeTab)) {
      const formSections = formsAndSections[activeTab].fields;
      await handleCreateForm({ formSections });
    } else {
      toast.error("Cannot submit empty form");
    }
    setIsSavingTemplate(false);
  };

  const { createForm, data: createFormData, isPending } = useCreateForm()

  const handleCreateForm = async ({
    formSections
  }: {
    formSections: FormData[];
  }) => {
    const formParams: {
      noteFields: any[];
    } = {
      noteFields: []
    };

    formSections.forEach(section => {
      const newSection = {
        title: section.sectionName,
        type: "section"
      };
      if (!section.sectionName) {
        return;
      }
      formParams.noteFields = [...formParams.noteFields, newSection];
      section.questionOptions.forEach(
        ({ fieldType, options, title, required }) => {
          if (!title) return;
          const newQuestion: any = {
            title,
            type: fieldType,
            required,
            ...(options && { options })
          };

          formParams.noteFields = [...formParams.noteFields, newQuestion];
        }
      );
    });

    if (activeTab && formParams.noteFields.length) {
      createForm({
        data: formParams,
        formType: activeTab
      });

      setIsCreateMode(false);
      navigate(`?activeTab=Template&activeNote=${activeTab}`);
      setIsFormEditMode(false);
      setInitialForm({
        ...initialForms,
        [activeTab]: createFormData?.newForm?.noteFields
      });
    } else {
      toast.error("Error- section name cannot be empty");
    }
  };

  return (
    <>
      <div className="h-full w-full mb-10 overflow-y-auto px-2">
        <div className="text-left text-[20px] font-[600] text-[#103C1B] mb-6" data-testid='template-settings-title'>
          Template Settings
        </div>
        {isPending && (<div className="flex justify-center items-center h-screen"><Loading /></div>)}
        {(!activeTab || !formTypes.includes(activeTab)) && (
          <>
            <div className="w-2/3 my-6 ml-4 box-border" >
              <p className="my-0 text-xl" data-testid='intake-form-title'>Intake Form</p>
              <div className="h-6" />
              <div data-testid='intake-form-button-container'>
                <Button
                  type="button"
                  variant="secondary"
                  label="Manage Intake Form"
                  onClick={() =>
                    navigate("?activeTab=Template&activeNote=intakeForm")
                  }
                />
              </div>
            </div>
            <div className="w-2/3 my-6 ml-4 box-border">
              <p className="my-0 text-xl" data-testid='initial-visit-notes-title'>Initial Visit Notes</p>
              <div className="h-6" />
              <div data-testid='initial-visit-button-container'>
                <Button
                  type="button"
                  variant="secondary"
                  label="Manage Initial Visit Notes"
                  onClick={() =>
                    navigate("?activeTab=Template&activeNote=initialVisitNote")
                  }
                />
              </div>
            </div>

            <div className="w-2/3 my-6 ml-4 box-border">
              <p className="my-0 text-xl" data-testid='progress-note-title'>Progress Notes</p>
              <div className="h-6" />
              <div data-testid='progress-note-button-container'>
              <Button
                type="button"
                variant="secondary"
                label="Manage Progress Notes"
                onClick={() =>
                  navigate("?activeTab=Template&activeNote=progressNote")
                }
              />
              </div>
            </div>
            <div className="w-2/3 my-6 ml-4 box-border">
              <p className="my-0 text-xl" data-testid='consent-form-title'>Consent Forms</p>
              <div className="h-6" />
              <Button
                type="button"
                variant="secondary"
                label="Manage Consent Form"
                onClick={() =>
                  navigate("?activeTab=Template&activeNote=consentForm")
                }
              />
            </div>
          </>
        )}
        {activeTab && formTypes.includes(activeTab) && (
          <FormManager
            formToView={activeTab}
            form={initialForms[activeTab]}
            setIsCreateMode={setIsCreateMode}
            isCreateMode={isCreateMode}
            isFormEditMode={isFormEditMode}
            setIsFormEditMode={setIsFormEditMode}
            formSections={formsAndSections[activeTab].fields}
            setFormSections={formsAndSections[activeTab].settings}
            navigate={navigate}
          />
        )}
      </div>
      <div className="h-[96px] w-full bg-white border-t border-[#ddd] bottom-0 sticky">
        <div className="h-full flex justify-end items-center">
          <div className="w-[122px] lg:mr-5" data-testid='save-changes-button-container'>
            <Button
              type="button"
              variant="primary"
              label="Save changes"
              size="medium"
              loading={isSavingTemplate}
              onClick={() => saveTemplate()}
              disabled={(referrer && referrer === "templatesPreview" && !isFormEditMode) ? true : false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Templates;
