import { Fragment, useState } from "react";
import { NavigateFunction } from "react-router-dom";
import FormBuilder, { FormFields } from "../../../components/form/FormBuilder";
import TextField from "../../../components/form/TextField";
import Button from "../../../components/shared/Button";
import CustomDropdown from "../../../components/shared/CustomDropdown";
import ToggleSwitch from "../../../components/shared/ToggleSwitch";
import { DeleteIcon } from "../../../svgs/DeleteIcon";
import { EditIcon } from "../../../svgs/EditIcon";
import { GreenCheckIcon } from "../../../svgs/GreenCheckIcon";
import { PlusIcon } from "../../../svgs/PlusIcon";
import { generateUUID } from "../../../utils/helpers";
import { FormData, FormTypes, formNames } from "../../pages/Settings/Templates";
import AddQuestionOptionsModal from "./AddQuestionOptionsModal";

const questionTypeList: { name: string; value: string }[] = [
  { name: "Heading", value: "heading" },
  { name: "Short Text", value: "text" },
  { name: "Long Text", value: "textarea" },
  { name: "Dropdown", value: "dropdown" },
  { name: "Radio Button", value: "radio" },
  { name: "Checkbox", value: "checkbox" },
  { name: "Date", value: "date" }
];

const FormManager = ({
  formToView,
  form,
  isCreateMode,
  setIsCreateMode,
  isFormEditMode,
  setIsFormEditMode,
  formSections,
  setFormSections,
  navigate
}: {
  formToView: FormTypes;
  form: FormFields[];
  isCreateMode: boolean;
  setIsCreateMode: React.Dispatch<React.SetStateAction<boolean>>;
  isFormEditMode: boolean;
  setIsFormEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  formSections: FormData[];
  setFormSections: React.Dispatch<React.SetStateAction<FormData[]>>;
  navigate: NavigateFunction;
}) => {
  const [selectedMultipleOptions, setSelectedMultipleOptions] = useState<
    string[]
  >([]);
  const [isSectionNameEditView, setIsSectionNameEditView] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>("");


  const handleChange = (
    e: any,
    questionId: string | number,
    index: number,
    type: "title" | "fieldType" | "options" | "sectionName" | "required"
  ) => {
    const sections = [...formSections];
    setFormSections(prevSections => {
      if (type === "sectionName") {
        sections[index].sectionName = e.target.value;
        return sections;
      } else {
        const questionArray = prevSections.map(options =>
          options.questionOptions.findIndex(
            question => question.id === questionId
          )
        );
        const sectionIndex = questionArray.findIndex(val => val > -1);
        const questionIndex = questionArray[sectionIndex];
        if (type !== "options" && type !== "required") {
          sections[sectionIndex].questionOptions[questionIndex][type] =
            e.target.value;

          if (type === "fieldType") {
            if (["dropdown", "checkbox", "radio"].includes(e.target.value)) {
              sections[sectionIndex].questionOptions[questionIndex].options =
                [];
              sections[sectionIndex].questionOptions[
                questionIndex
              ].showOptions = true;
            } else {
              delete sections[sectionIndex].questionOptions[questionIndex]
                .options;
            }
          }
        } else if (type === "required") {
          sections[sectionIndex].questionOptions[questionIndex][type] = !e;
        }

        return sections;
      }
    });
  };

  const toggleShowQuestionOptions = (id: string) => {
    const sections = [...formSections];
    const questionArray = sections.map(options =>
      options.questionOptions.findIndex(question => question.id === id)
    );
    const sectionIndex = questionArray.findIndex(val => val > -1);
    const questionIndex = questionArray[sectionIndex];

    const isShown =
      sections[sectionIndex].questionOptions[questionIndex].showOptions;

    if (typeof isShown === "boolean") {
      sections[sectionIndex].questionOptions[questionIndex].showOptions =
        !isShown;
    }

    setFormSections(sections);
  };

  const handleAddSection = () => {
    setFormSections(prevIntakeFormSections =>
      [...prevIntakeFormSections].concat({
        id: generateUUID(),
        sectionName: `Section ${formSections.length + 1}`,
        questionOptions: [
          {
            id: generateUUID(),
            showOptions: true,
            title: "",
            fieldType: "text",
            required: false
          }
        ]
      })
    );
  };

  const handleAddField = (index: number) => {
    const newFormSections = [
      ...formSections.slice(0, index),
      {
        ...formSections[index],
        questionOptions: [
          ...formSections[index].questionOptions,
          {
            id: generateUUID(),
            title: "",
            fieldType: "text",
            required: false
          }
        ]
      },
      ...formSections.slice(index + 1)
    ];

    setFormSections(newFormSections);
  };

  const handleSaveQuestionOptions = (
    newOptions: string[],
    id: string | number
  ) => {
    const sections = [...formSections];
    const questionArray = sections.map(options =>
      options.questionOptions.findIndex(question => question.id === id)
    );
    const sectionIndex = questionArray.findIndex(val => val > -1);
    const questionIndex = questionArray[sectionIndex];
    sections[sectionIndex].questionOptions[questionIndex].options = newOptions;
    setSelectedQuestionId("");
    setFormSections(sections);
  };

  const FormHeading = ({
    title,
    submitAction,
    showButton,
    buttonText
  }: {
    title: string;
    submitAction: () => void;
    showButton: boolean;
    buttonText: string;
  }) => {
    return (
      <div className="mt-2 flex mb-8 justify-between">
        <div>
          <span
            onClick={() => navigate("?activeTab=Template")}
            className="text-[20px] font-[400] text-[#2E3011] cursor-pointer"
            data-testid='back-to-templates'
          >
            Templates
          </span>
          <span className="text-[20px] font-[600] text-[#2E3011] ml-[6px]">
            {`> ${title} ${formNames[formToView]}`}
          </span>
        </div>
        {showButton && (
          <div data-testid='form-heading-button-container'>
            <Button
              type="button"
              variant="primary"
              label={buttonText}
              size="small"
              onClick={submitAction}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="h-full w-full mb-10 overflow-y-auto">
      {isFormEditMode ? (
        <div className="h-full w-[95%] mx-4">
          <FormHeading
            title="Edit"
            submitAction={() => {
              setIsFormEditMode(false);
              setIsCreateMode(false);
              // setViewFormFrame(true);
            }}
            showButton={!!form}
            buttonText="View"
          />

          {formSections &&
            formSections?.map((sectionItem, index) => {
              return (
                <div
                  key={index}
                  className="my-4 rounded-[8px] shadow-[-2px_4px_25px_#EEEEE9] min-h-[176px] box-border"
                >
                  <>
                    <div className="w-full h-[68px] bg-[#EEEEE9] flex items-center justify-center gap-10">
                      {isSectionNameEditView &&
                      sectionItem?.id === activeSection ? (
                        <div className="w-full lg:min-w-[350px] h-full flex">
                          <input
                            name="sectionName"
                            type="text"
                            placeholder="Type section name"
                            value={sectionItem?.sectionName}
                            onChange={e => {
                              handleChange(
                                e,
                                sectionItem.id,
                                index,
                                "sectionName"
                              );
                            }}
                            className="w-full outline-none border-[#ddd] m-2 pl-2 rounded-sm flex text-[#000] border border-none focus:outline-none text-xl"
                          />
                        </div>
                      ) : (
                        <p className="text-[24px] text-[#2E3011] font-[500]">
                          {sectionItem?.sectionName}
                        </p>
                      )}
                      <div
                        onClick={() => {
                          setIsSectionNameEditView(!isSectionNameEditView);
                          setActiveSection(sectionItem?.id);
                        }}
                      >
                        {isSectionNameEditView ? (
                          <GreenCheckIcon height={36} width={36} />
                        ) : (
                          <EditIcon />
                        )}
                      </div>
                    </div>
                    {/* QUESTION FIELD MAP LIST */}
                    {sectionItem?.questionOptions?.map((value, index) => (
                      <Fragment key={value?.id}>
                        <div className="w-full h-full p-4 flex justify-between items-center">
                          <div className="w-1/2 lg:w-[400px] xl:w-[500px] 2xl:w-[600px]">
                            <TextField
                              name="text"
                              type="text"
                              placeholder={`Type in your ${
                                value.fieldType === "heading"
                                  ? "heading"
                                  : "question"
                              }`}
                              value={value?.title}
                              onChange={e =>
                                handleChange(e, value?.id, index, "title")
                              }
                              isRequired={true}
                            />
                          </div>
                          <div className="">
                            <CustomDropdown
                              testId="fieldType"
                              onChange={e => {
                                handleChange(e, value?.id, index, "fieldType");
                              }}
                              value={value?.fieldType}
                              defaultValue="Answer type"
                              name="fieldType"
                              placeholder="Choose answer type"
                              optionsList={questionTypeList}
                            />
                          </div>
                          <div
                            onClick={() => {
                              const newQuestions =
                                sectionItem.questionOptions.filter(
                                  (question, ind) => ind !== index
                                );
                              const newSectionItems = {
                                ...sectionItem,
                                questionOptions: newQuestions
                              };
                              const newSections = formSections.map(section =>
                                section.id === sectionItem.id
                                  ? newSectionItems
                                  : section
                              );
                              setFormSections(newSections);
                            }}
                            className="cursor-pointer"
                          >
                            <DeleteIcon color="#949589" />
                          </div>
                          {value.fieldType !== "heading" && (
                            <>
                              <div className="border-[1px] border-gray-300 border-solid h-[40px]" />
                              <div>
                                <ToggleSwitch
                                  testId="showOptions"
                                  label="Required"
                                  isSelected={value.required}
                                  onClick={() =>
                                    handleChange(
                                      value.required,
                                      value?.id,
                                      index,
                                      "required"
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                        {["checkbox", "dropdown", "radio"].includes(
                          value.fieldType
                        ) && (
                          <div className="w-full flex items-center mb-3">
                            <div className="text-[16px] font-[500] text-[#949589] mr-4">
                              {value.showOptions &&
                                value.options?.map((option, idx) => (
                                  <p
                                    key={idx}
                                    className="ml-5 text-[14px] text-[#949589]"
                                  >
                                    Option {idx + 1}- {option}
                                  </p>
                                ))}
                            </div>

                            <div className="text-[#1A9D39] text-[16px] flex items-center">
                              {value.options?.length ? (
                                <>
                                  {value.showOptions && (
                                    <>
                                      <p
                                        onClick={() => {
                                          if (value.options) {
                                            setSelectedMultipleOptions(
                                              value.options
                                            );
                                          }
                                          setSelectedQuestionId(value.id);
                                        }}
                                        className="mr-2 cursor-pointer"
                                        data-testid='edit-options'
                                      >
                                        Edit options
                                      </p>
                                      |
                                    </>
                                  )}

                                  <p
                                    onClick={() =>
                                      toggleShowQuestionOptions(value.id)
                                    }
                                    className="ml-2 cursor-pointer"
                                  >
                                    {value.showOptions
                                      ? "Hide options"
                                      : "Show Options"}
                                  </p>
                                </>
                              ) : (
                                <p
                                  onClick={() => {
                                    if (value.options) {
                                      setSelectedMultipleOptions(value.options);
                                    }
                                    setSelectedQuestionId(value.id);
                                  }}
                                  className="cursor-pointer"
                                >
                                  Select options
                                </p>
                              )}
                              {selectedQuestionId === value.id && (
                                <AddQuestionOptionsModal
                                  options={selectedMultipleOptions}
                                  closeModal={() => setSelectedQuestionId("")}
                                  saveAction={handleSaveQuestionOptions}
                                  title="Add options"
                                  questionId={value.id}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                    <div className="flex flex-row pl-4 pb-4">
                      <span
                        className="cursor-pointer flex items-center"
                        onClick={() => handleAddField(index)}
                        data-testid='add-question-field'
                      >
                        <PlusIcon />
                        <span className="text-[#1A9D39] text-[16px] ml-3" >
                          Add another question
                        </span>
                      </span>
                    </div>
                  </>
                </div>
              );
            })}
          {/* SECTION BOX ENDS */}
          <div data-testid='add-a-section'>
            <Button
              type="button"
              variant="secondary"
              label="Add a section"
              size="medium"
              onClick={() => handleAddSection()}
            />
          </div>
        </div>
      ) : !form || isCreateMode ? (
        <div className="h-full w-[95%] mx-4">
          <FormHeading
            title="Create"
            submitAction={() => {
              setIsCreateMode(false);
              setIsFormEditMode(true);
            }}
            showButton={true}
            buttonText="Create"
          />
            {/* <FormBuilder
              formParams={form}
              formType={formToView}
              submitAction={() => { }}
              isPreview={true}
            />  */}
        </div>
      ) : (
            <Fragment>
          <FormHeading
            title="View"
            submitAction={() => {
              setIsFormEditMode(true);
              const newFormSections: FormData[] = [];
              form.forEach(item => {
                if (item.type === "section") {
                  const section = {
                    id: generateUUID(),
                    sectionName: item.title,
                    questionOptions: [
                      {
                        id: generateUUID(),
                        title: "",
                        fieldType: "text",
                        required: false
                      }
                    ]
                  };
                  newFormSections.push(section);
                } else {
                  const lastSection =
                    newFormSections[newFormSections.length - 1];
                  const newQuestionSection =
                    lastSection?.questionOptions?.filter(item => item.title);
                  const newOptions = {
                    id: generateUUID(),
                    title: item.title,
                    fieldType: item.type,
                    required: item.required,
                    ...(item.options && {
                      options: item.options,
                      showOptions: true
                    })
                  };
                  newQuestionSection.push(newOptions);
                  lastSection.questionOptions = newQuestionSection;
                }
                setFormSections(newFormSections);
              });
            }}
            showButton={true}
            buttonText="Edit"
          />
          <FormBuilder
            formParams={form}
            formType={formToView}
            submitAction={() => {}}
            isPreview={true}
          />
            </ Fragment>
      )}
    </div>
  );
};

export default FormManager;
