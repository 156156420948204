import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { UpcomingAppointmentType } from '../../../../api/types';


export function useOverViewTableCols() {
  const TABLE_COLUMNS = useMemo<Column<UpcomingAppointmentType>[]>(
    () => [
      {
        Header: "Date",
        accessor: "appointmentDate",
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              {value ? format(new Date(value), "EEEE, dd LLL") : "N/A"}
            </Fragment>
          );
        }
      },
      {
        Header: "Time",
        accessor: "appointmentStartTime",
        Cell: ({ cell: { value }, row }) => {
          return (
            <Fragment>
              {`${row.original.appointmentStartTime}${' '}-${' '}${row.original.appointmentEndTime}`}
            </Fragment>
          );
        }
      },
      {
        Header: "Patient",
        accessor: "patientName"
      },
      {
        Header: "Reason of visit",
        accessor: "appointmentReason",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value || "N/A"}</Fragment>;
        }
      },
      {
        Header: "Duration",
        accessor: "duration",
        Cell: ({ cell: { value }, row }) => {
          return (
            <Fragment>
              {row.original.duration > 9 ? `${value} mins` : `${value} hr`}
            </Fragment>
          );
        }
      }
    ],
    []
  );

  return {
    TABLE_COLUMNS
  }
}