import React from "react";
import { ReactComponent as DurationIcon } from "../../../svgs/duration-icon.svg";

interface EventCardProps {
  name: string;
  startTime: string;
  endTime: string;
  diagnosis: string;
  onClick: () => any;
}

const EventCard: React.FC<EventCardProps> = ({
  name,
  startTime,
  endTime,
  diagnosis,
  onClick
}) => {
  return (
    <div className="rounded-[8px] drop-shadow bg-[#fff] w-full min-h-[285px] p-4 mb-2" data-testid="event-card">
      <p className="text-left text-[#324705] font-semibood text-[24px] py-2" data-testid="appointment-title">
        Happening Now!
      </p>
      <div
        className="text-left text-[#324705] font-normal text-[14px] my-4"
        data-toggle="menu"
        data-testid="appointment-with-username"
      >
        Appointment with <b>{name}</b>
      </div>
      <div
        className="text-left text-[#324705] font-normal text-[14px] my-4"
        data-toggle="menu"
        data-testid="diagnosis"
      >
        Diagnosis: <b>{diagnosis || "N/A"}</b>
      </div>
      <div className="w-full flex justify-between items-center py-2 my-4" data-testid="appointment-time-container">
        <div className="text-[#7E954B] text-[14px] flex items-center justify-start">
          <DurationIcon />{" "}
          <p className="ml-2" data-testid="appointment-time">
            {startTime} - {endTime}
          </p>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <button
          type="button"
          onClick={onClick}
          className="hidden lg:flex items-center text-sm justify-center rounded-[80px] font-[500] h-[40px] w-full bg-[#34623F] text-[#fff]"
          data-testid="join-call-button"
        >
          Join Call
        </button>
        <button
          disabled
          type="button"
          onClick={onClick}
          className="flex lg:hidden items-center text-sm justify-center rounded-[80px] font-[500] h-[40px] w-full bg-[#34623F] text-[#fff] cursor-not-allowed"
          data-testid="join-call-button-disabled"
        >
          Join Call
        </button>
      </div>
    </div>
  );
};

export default EventCard;
