export const insuranceCoverageType = [
  "Health",
  "Life",
  "Property",
  "Auto",
  "Travel",
  "Liability",
  "Disability",
  "Dental",
  "Vision",
  "Pet"
];
export const statesInTheUS = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
] as const;

export const phoneRegExp = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const subscriptionData = [
  {
    id: 0,
    item: "Practice Listing",
    free: "x",
    m99: "x",
    m149: "x",
    m199: "x"
  },
  {
    id: 1,
    item: "Scheduling",
    free: "x",
    m99: "x",
    m149: "x",
    m199: "x"
  },
  {
    id: 2,
    item: "Messaging",
    free: "x",
    m99: "x",
    m149: "x",
    m199: "x"
  },
  {
    id: 3,
    item: "Telehealth",
    free: "",
    m99: "x",
    m149: "x",
    m199: "x"
  },
  {
    id: 3,
    item: "Intake Forms",
    free: "",
    m99: "x",
    m149: "x",
    m199: "x"
  },
  {
    id: 4,
    item: "Consent Forms",
    free: "",
    m99: "x",
    m149: "x",
    m199: "x"
  },
  {
    id: 5,
    item: "Reminders",
    free: "",
    m99: "x",
    m149: "x",
    m199: "x"
  },
  {
    id: 5,
    item: "Collection of payments",
    free: "",
    m99: "x",
    m149: "x",
    m199: "x"
  },
  {
    id: 6,
    item: "Access to templates",
    free: "",
    m99: "x",
    m149: "x",
    m199: "x"
  },
  {
    id: 7,
    item: "Notes",
    free: "",
    m99: "x",
    m149: "x",
    m199: "x"
  },
  {
    id: 8,
    item: "Lab Orders",
    free: "",
    m99: "",
    m149: "x",
    m199: "x"
  },
  {
    id: 9,
    item: "Electronic Prescription",
    free: "",
    m99: "",
    m149: "x",
    m199: "x"
  },
  {
    id: 9,
    item: "Electronic Prescription (EPCS)",
    free: "",
    m99: "",
    m149: "x",
    m199: "x"
  },
  {
    id: 10,
    item: "***Billing per claim ($4.99 per remittance)",
    free: "",
    m99: "",
    m149: "x",
    m199: ""
  },
  {
    id: 11,
    item: "***Billing per claim ($2.99 per remittance)",
    free: "",
    m99: "",
    m149: "",
    m199: "x"
  }
];

export interface Insurance {
  item: string;
  value: string;
}

export const insuranceCompanies: Insurance[] = [
  { item: "AARP", value: "aarp" },
  { item: "Aetna", value: "aetna" },
  { item: "American Family Insurance", value: "americanFamilyInsurance" },
  { item: "Amerigroup", value: "amerigroup" },
  { item: "Blue Cross and Blue Shield Association", value: "blueCross" },
  { item: "Bright Health", value: "brightHealth" },
  { item: "CareSource", value: "careSource" },
  { item: "Cambia Health Solutions", value: "cambiaHealthSolution" },
  { item: "Centene Corporation", value: "centeneCorporation" },
  { item: "Cigna", value: "cigna" },
  { item: "Coventry Health Care", value: "coventryHealthCare" },
  { item: "Delta Dental", value: "deltaDental" },
  { item: "Elevance Health", value: "elevanceHealth" },
  { item: "EmblemHealth", value: "emblemHealth" },
  { item: "Fortis", value: "fortis" },
  { item: "Geisinger", value: "Geisinger" },
  {
    item: "Golden Rule Insurance Company",
    value: "goldenRuleInsuranceCompany"
  },
  { item: "Group Health Cooperative", value: "groupHealthCooperative" },
  { item: "Group Health Incorporated", value: "groupHealthIncorporated" },
  { item: "Harvard Pilgrim Health Care", value: "harvardPilgrimHealthCare" }
];

export const maritalStatusList = [
  { name: "Single", value: "Single" },
  { name: "Married", value: "Married" },
  { name: "Seperated", value: "Seperated" },
  { name: "Divorced", value: "Divorced" },
  { name: "Unmarried", value: "Unmarried" },
  { name: "Common law", value: "Common law" },
  {
    name: "Legally Separated",
    value: "Legally Separated"
  },
  { name: "Living together", value: "Living together" },
  { name: "Interlocutory", value: "Interlocutory" },
  { name: "Annulled", value: "Annulled" },
  { name: "Domestic partner", value: "Domestic partner" },
  {
    name: "Registered domestic partner",
    value: "Registered domestic partner"
  },
  { name: "Unreported", value: "Unreported" },
  { name: "Widowed", value: "Widowed" },
  { name: "Unknown", value: "Unknown" }
];

export const raceList = [
  {
    name: "Black or African American",
    value: "Black or African American"
  },
  { name: "White", value: "White" },
  {
    name: "American Indian or Alaska Native",
    value: "American Indian or Alaska Native"
  },
  { name: "Asian", value: "Asian" },
  {
    name: "Native Hawaiian or Other Pacific Islander",
    value: "Native Hawaiian or Other Pacific Islander"
  },
  { name: "Other races", value: "others" }
];

export const dependentRelationshipList = [
  "Spouse",
  "Child",
  "Employee",
  "Unknown",
  "Organ Donor",
  "Cadaver Donor",
  "Life Partner",
  "Other relationship"
];
