import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ListTabsWidget from "../../../components/shared/ListTabsWidget";
import ProviderDashboardLayout from "../../components/dashboard/ProviderDashboardLayout";
import Credentialing from "./Credentialing";
import MalpracticeInsurance from "./MalpracticeInsurance";
import PracticeRegistration from "./PracticeReg";

const BusinessOffice = () => {
  const search = useSearchParams()[0];
  const navigate = useNavigate();
  const activeTab = search.get("activeTab");
  const [activeTabLabel, setActiveTabLabel] = useState(
    activeTab || "Business Registration"
  );

  const handleTabClick = (tabName: string) => {
    navigate(`/provider/business-office?activeTab=${tabName}`);
    setActiveTabLabel(tabName);
  };
  return (
    <>
      <ProviderDashboardLayout headerTitle="Business Office">
        <div className="lg:mt-6 flex flex-col w-full h-full">
          <div className="overflow-y-hidden mb-6 px-4 w-full overflow-x-scroll lg:overflow-x-hidden py-4">
            <ListTabsWidget
              tabs={[
                { label: "Business Registration" },
                { label: "Insurance Credentialing" },
                { label: "Malpractice Insurance" }
              ]}
              handleTabClick={label => {
                handleTabClick(label);
              }}
              activeTabLabel={activeTabLabel}
            />
          </div>
          {(activeTab === "Business Registration" || activeTab === null) && (
            <PracticeRegistration />
          )}
          {activeTab === "Insurance Credentialing" && (
            <Credentialing />
          )}
          {activeTab === "Malpractice Insurance" && (
            <MalpracticeInsurance />
          )}
        </div>
      </ProviderDashboardLayout>
    </>
  );
};

export default BusinessOffice;
